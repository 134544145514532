import { FormGroup } from '@angular/forms';

export class CommonValidators {

  static isMatch(first, second) {
    return (group: FormGroup): { [key: string]: any } => {
      if ((group.controls[first].value !== group.controls[second].value) &&
        ( (group.controls[first].value !== '' && group.controls[first].value !== null) && (group.controls[second].value !== '' && group.controls[second].value !== null) )
      ) {
        return {
          isMatch: true
        };
      }
      return null;
    };
  }

}
