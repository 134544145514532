import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { EventModel } from '../../dashboard/events/events.model';
import { DashboardService } from '../../dashboard/dashboard.service';
import { Toast, ToasterMessagesTypes } from '../../../decorators/toast.decorator';
import { CurrentUserService } from '../../../services/current-user.service';
import { BASE_URL, transparentSource } from '../../../constants/constant';

@Component({
  selector: 'mvms-global-events-list-item',
  templateUrl: './global-events-list-item.component.html',
  styleUrls: ['./global-events-list-item.component.scss']
})
export class GlobalEventsListItemComponent {
  @Input() event: EventModel;
  transparentSource = transparentSource;
  constructor(
    protected router: Router,
    protected dashboardService: DashboardService,
    private currentUserService: CurrentUserService) {
  }

  getMapLink(address) {
    return `https://maps.google.com/?q=${encodeURI(address)}`;
  }
  imageLink(id) {
    return `url(${BASE_URL}api/File/${id}/thumbnail)`;
  }

  goToEvent(id) {
    // if (this.currentUserService.user) {
    this.dashboardService.currentEventId.next(id);
    this.router.navigate([`/opportunities/${id}`]);
    // }
    // else
    //   this.showError();

    // this.currentUserService.getCurrentUser().subscribe(res => {
    //   if (res.isAccepted) {
    //     this.dashboardService.currentEventId.next(id);
    //     this.router.navigate([`/opportunities/${id}`]);
    //   }
    //   else
    //     this.showError();
    // });
  }

  @Toast(ToasterMessagesTypes.InsufficientAccess)
  showError() {
    var ox = 34;
  }
}
