import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'enumkeys'})
export class EnumKeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    const list = [];
    for (const key in value) {
      if (!isNaN(Number(key))) {
        list.push({key: parseInt(key), value: value[key]});
      }
    }

    return list;
  }
}
