import { Injectable } from '@angular/core';
import {BASE_URL} from '../../../constants/constant';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {EnquirieModel} from './enquiries.model';
import {Subject} from 'rxjs/Subject';
import {Toast, ToasterMessagesTypes} from '../../../decorators/toast.decorator';
import {ProgressBar} from '../../../decorators/progress-bar.decorator';

@Injectable()
export class EnquiriesDataService {
  private url = `${BASE_URL}api/PublicEnquiry`;
  public unread = 0 ;

  public emitRefreshList: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {}

  getList(filterParam:any): Observable<any> {
    return this.http.post<any>(`${this.url}/list`, filterParam);
  }

  getItem(id:any): Observable<EnquirieModel> {
    return this.http.get<EnquirieModel>(`${this.url}/${id}`);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullySent)
  create(model:any) {
    return this.http.post<any>(`${this.url}/submit`, model);
  }

}
