import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroupDirective, Validators} from '@angular/forms';
import {Subject} from 'rxjs/Subject';
import {CreateUserService} from '../../../../services/create-user.service';
import {AccountType, RegexpPattern, NATIONALITY_SAUDI} from '../../../../constants/constant';
import {PersonalInfoProfileModel} from '../user-profile.model';
import {UserProfileDataService} from '../user-profile-data.service';
import {CroppieOptions} from 'croppie';
import {CurrentUserService} from '../../../../services/current-user.service';
import {MvmsCroppieBtnComponent} from '../../../shared/croppie/mvms-croppie-btn/mvms-croppie-btn.component';
import { LanguageService } from '../../../../services/language.service';

// declare var $: any;

@Component({
  selector: 'mvms-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit, OnDestroy {
  public SaudiNational = NATIONALITY_SAUDI;
  public AccountType = AccountType;
  public cityList;
  public nationalityList;
  public genderType;
  public maritalStatusType;
  public formChanged = false;

  public CroppieOptions: CroppieOptions = {
    viewport :  {
      width: 300,
      height: 300,
    },
    boundary : {
      width: 400,
      height: 400
    },
    enableExif: true,
  };

  public updateForm = this.fb.group({
    gender: ['', [Validators.required]],
    birthDate: ['', [Validators.required]],
    maritalStatus: ['', [Validators.required]],
    profession: ['', [Validators.required]],
    accountType: ['None', [Validators.required]],
    // badgeNo: ['', [Validators.maxLength(8), Validators.pattern(new RegExp(RegexpPattern.digits))]],
    nationality: ['', [Validators.required]],
    city: ['', [Validators.required]],
    address: [''],
    avatarId: [''],
    emergencyContact: ['', [Validators.required, Validators.minLength(7), Validators.pattern(new RegExp(RegexpPattern.phone))]]
  });

  public isDataLoaded = false;
  public professionList;

  public model: PersonalInfoProfileModel;
  private componentDestroyed: Subject<any> = new Subject();

  isCurrentLanguageArabic: boolean;

  @ViewChild ('UploadFileComponent') UploadFileComponent: MvmsCroppieBtnComponent;
  @ViewChild('form') form: FormGroupDirective;
  constructor(private fb: FormBuilder,
              private createUserService: CreateUserService,
              private userProfileDataService: UserProfileDataService,
              private currentUserService: CurrentUserService,
              private languageService: LanguageService) {
  }

  ngOnInit() {
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
    this.initData();
    this.initFormsValidators();
    this.userProfileDataService.getPersonalInfo().subscribe((model) => {
      this.model = model;
      if(this.model.nationality['value'] == 'Other')
        alert("Please update your nationality");
      this.isDataLoaded = true;
      this.patchModelInForms();
      this.updateForm.valueChanges.takeUntil(this.componentDestroyed).subscribe(() => {
        this.formChanged = true;
      });
    });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  checkBirthDate() {
    const birthDateStr = this.updateForm.value.birthDate;
    this.updateForm.controls.birthDate.setValue(birthDateStr);
    const currentDate = Date.now();
    const birthDate = Date.parse(birthDateStr);
    if (isNaN(birthDate)) {
      this.updateForm.updateValueAndValidity();
    } else {
      if ((currentDate - birthDate) < 157784630000) {
        this.updateForm.controls.birthDate.setErrors({'youngerFiveYears': true});
      } else {
        this.updateForm.controls.birthDate.setErrors(null);
      }
      this.updateForm.updateValueAndValidity();
    }
  }

  public initFormsValidators() {
    /*this.updateForm.get('accountType').valueChanges.takeUntil(this.componentDestroyed).subscribe((status: number) => {
      if (status !== AccountType.None) {
        this.updateForm.get('badgeNo').setValidators([Validators.maxLength(8), Validators.required, Validators.pattern(new RegExp(RegexpPattern.digits))]);
        this.updateForm.get('badgeNo').updateValueAndValidity();
        this.updateForm.updateValueAndValidity();
      } else {
        this.clearValidators('badgeNo');
      }
    });*/
  }

  private clearValidators(formName) {
    this.updateForm.get(formName).clearValidators();
    this.updateForm.get(formName).updateValueAndValidity();
  }

  public initData() {
    this.createUserService.getNationality().takeUntil(this.componentDestroyed).subscribe(nationalities => this.nationalityList = nationalities);
    this.createUserService.getCites().takeUntil(this.componentDestroyed).subscribe(cities => this.cityList = cities);
    this.createUserService.getProfessions().takeUntil(this.componentDestroyed).subscribe(professions => this.professionList = professions);

    this.createUserService.getGender().takeUntil(this.componentDestroyed).subscribe(gender => this.genderType = gender);
    this.createUserService.getMaritalStatus().takeUntil(this.componentDestroyed).subscribe(maritalStatus => this.maritalStatusType = maritalStatus);
  }

  public patchModelInForms() {
    this.updateForm.patchValue(this.model);
  }

  public update() {
    if (this.updateForm.valid) {
      if(this.updateForm.value.nationality.value == 'Other'){
        alert("Please update your nationality");
        return;
      }
      const model = {
        personal: {
          gender: this.updateForm.value.gender,
          birthDate: this.updateForm.value.birthDate,
          maritalStatus: this.updateForm.value.maritalStatus,
          professionId: this.updateForm.value.profession.id,
          nationalityId: this.updateForm.value.nationality.id,
          accountType: this.updateForm.value.accountType,
          //badgeNo: this.updateForm.value.badgeNo,
          avatarId: this.updateForm.value.avatarId,
        },
        contact: {
          cityId: this.updateForm.value.city.id,
          address: this.updateForm.value.address,
          emergencyContact: this.updateForm.value.emergencyContact
        }
      };
      this.userProfileDataService.updatePersonalInfo(model).subscribe(() => {
        this.currentUserService.clearCurrentUser();
        this.currentUserService.getCurrentUser().subscribe(() => {});
        this.formChanged = false;
      },
      () => this.formChanged = false);
    }
  }

  public uploadAvatar(formData: FormData) {
    this.userProfileDataService.uploadFile(formData).subscribe(fileId => {
      const avatarControl = this.updateForm.get('avatarId');
      avatarControl.setValue(fileId.id);
      avatarControl.markAsDirty();
      this.UploadFileComponent.dialog.closeAll();
    });
  }

  checkEmergencyPhone() {
    if (this.updateForm.controls.emergencyContact.dirty && this.updateForm.controls.emergencyContact.valid) {
      this.createUserService.checkPhone({phoneNo: this.updateForm.controls.emergencyContact.value}).subscribe((res: any) => {
        if (!res.isValid) {
          this.updateForm.controls.emergencyContact.setErrors({'notValid': true});
        } else {
          // this.updateForm.patchValue({emergencyContact: res.formattedPhoneNo});
          if (this.updateForm.value.emergencyContact === this.model.phoneNo) {
            this.updateForm.controls.emergencyContact.setErrors({'equal': true});
          }
          this.updateForm.updateValueAndValidity();
        }
      });
    }
  }

  reset() {
    this.patchModelInForms();
    this.form.resetForm(this.model);
    this.formChanged = false;
  }
}
