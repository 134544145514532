<mat-form-field fxFlex="100">
  <div class="autocomplete-select-arrow"></div>
  <input matInput type='text' [formControl]="inputControl" [placeholder]="placeholder" [matAutocomplete]="auto"
  [allowCustomValues]="allowCustomValues" mvmsEnforcedInput>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayName.bind(this)">
    <mat-option *ngFor="let item of filteredArray | async" [value]="item"  [attr.dir]="isCurrentLanguageArabic  ? 'rtl' : ''">
      {{displayName(item)}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="inputControl.hasError('required')">
    {{'SHARED.Errors.emptyField' | translate }}
  </mat-error>
</mat-form-field>
