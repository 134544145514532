import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs/Subject';
import { DashboardService } from '../../../dashboard.service';
import { OnlineTrainingModel } from '../training.model';
import { DashboardDataService } from '../../../dashboard-data.service';

@Component({
  selector: 'mvms-online-training',
  templateUrl: './online-training.component.html',
  styleUrls: ['./online-training.component.scss']
})
export class OnlineTrainingComponent implements OnInit, OnDestroy {
  model: OnlineTrainingModel;

  currentTrainingAttempts: number;

  protected componentDestroyed: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private dashboardDataService: DashboardDataService) {
  }

  ngOnInit() {
    if (this.dashboardService.currentTraining) {
      this.dashboardDataService
        .getOnlineTraining(this.dashboardService.routeParams.trainingId)
        .takeUntil(this.componentDestroyed)
        .subscribe(trainingModel => {
          this.model = trainingModel;
        });
    } else {
      this.router.navigate([`/dashboard/myEvents/${this.dashboardService.routeParams.id}/training`]);
    }

    this.currentTrainingAttempts = this.dashboardService.currentTraining.attempt;
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  startTest() {
    this.router.navigate([`/dashboard/myEvents/${this.dashboardService.routeParams.id}/online-training/${this.dashboardService.routeParams.trainingId}/quiz`]);
  }

  reTakeTest() {
    this.dashboardService.retakeTrainingMode = true;
    this.router.navigate([`/dashboard/myEvents/${this.dashboardService.routeParams.id}/online-training/${this.dashboardService.routeParams.trainingId}/quiz`]);
  }
}
