<div id="login" fxLayout="column" fusePerfectScrollbar [class.rtl]="isCurrentLanguageArabic" [attr.dir]="isCurrentLanguageArabic  ? 'rtl' : ''">
  <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div id="login-form">
      <div class="user-logo">
      </div>
      <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="login()">
        <mat-form-field class="big-error-input">
          <input matInput formControlName="email" type='email' [placeholder]="'LOGIN.Email' | translate" autocomplete="off">
          <mat-error *ngIf="loginForm.controls.email.hasError('required')">
            {{'SHARED.Errors.emptyField' | translate }}
          </mat-error>
          <mat-error *ngIf="invalidEmail || loginForm.controls.email.hasError('pattern')">
            {{'SHARED.Errors.invalidEmail' | translate }}
          </mat-error>
          <mat-error *ngIf="pendingSatus">
            {{'SHARED.Errors.userNotAppoved' | translate }}
          </mat-error>
          <mat-error *ngIf="disabledSatus">
            {{'SHARED.Errors.userIsDisabled' | translate }}
          </mat-error>
          <mat-error *ngIf="lockedSatus">
            {{'SHARED.Errors.userIsLocked' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="password" [type]="showPassword.checked ? 'text' : 'password'" [placeholder]="'LOGIN.Password' | translate"
                 mvmsBlockPaste autocomplete="off">

                 <mat-button-toggle #showPassword matSuffix><mat-icon *ngIf="showPassword.checked">visibility</mat-icon><mat-icon *ngIf="showPassword.checked===false">visibility_off</mat-icon></mat-button-toggle> 
          <mat-error *ngIf="loginForm.controls.password.hasError('required')">
            {{'SHARED.Errors.emptyField' | translate }}
          </mat-error>
          <mat-error *ngIf="invalidPassword || loginForm.controls.password.hasError('pattern')  || loginForm.controls.password.hasError('minlength') || loginForm.controls.password.hasError('maxlength')">
            {{'SHARED.Errors.invalidPassword' | translate }}
          </mat-error>
        </mat-form-field>
        <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
             fxLayoutAlign="space-between center">
          <a class="forgot-password" (click)="showRecoverDialog()">
            {{'LOGIN.ForgotPass' | translate }}
          </a>
        </div>
        <button mat-raised-button type="submit" color="accent" class="submit-button" aria-label="LOG IN"
                [disabled]="loginForm.invalid">
          {{'SHARED.Button.LOGIN' | translate }}
        </button>
      </form>
    </div>

  </div>

</div>

