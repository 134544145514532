import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';
import {NavigationEnd, Router} from '@angular/router';
import {EventModel} from './events/events.model';
import {DashboardDataService} from './dashboard-data.service';
import 'rxjs/add/operator/distinctUntilChanged';
import {IResponseModel} from '../../../types';
import 'rxjs/add/operator/debounceTime';
import {OnlineTrainingListItemModel, OnlineTrainingModel} from './events/training/training.model';


@Injectable()
export class DashboardService {
  public queryString = '';
  public $queryString: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  subscriptions: Subscription[] = [];
  public routeParams: any;
  public currentEventId: BehaviorSubject<string> = new BehaviorSubject(null);
  public currentEvent: BehaviorSubject<EventModel> = new BehaviorSubject(null);
  public currentTraining: OnlineTrainingListItemModel;
  public retakeTrainingMode = false;
  public data: BehaviorSubject<IResponseModel> = new BehaviorSubject(null);
  public dataTaskList: BehaviorSubject<IResponseModel> = new BehaviorSubject(null);
  public filterExtension: object;
  public routerEvent: NavigationEnd;

  constructor(protected router: Router, protected dashboardDataService: DashboardDataService) {
    this.init();
  }

  init() {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.routerEvent = e;
        let leaf = this.router.routerState.snapshot.root;
        while (leaf && leaf.children && leaf.children.length > 0 && Object.keys(leaf.params).length === 0) {
          leaf = leaf.children[0];
        }
        this.routeParams = leaf.params; // Top route with parameters
        this.routeParams.id ? this.currentEventId.next(this.routeParams.id) : this.currentEventId.next(null);
      }
    });

    this.currentEventId.distinctUntilChanged().subscribe(id => {
      if (id && this.routerEvent) {
        if (this.routerEvent.urlAfterRedirects.toLowerCase().replace(/\/$/, '').includes('myevents')) {
          this.getEvent(id);
        }
      }
    });


    this.$queryString.debounceTime(300).subscribe(m => {
      this.queryString = m;
      if (this.queryString != null) {
        this.refreshEventList();
      }
    });
  }

  getEvent(id) {
    this.subscriptions.push(
      this.dashboardDataService.getEvent(id).subscribe(data => {
        this.currentEvent.next(data);
      })
    );
  }

  refreshEventList() {
    this.subscriptions.push(
      this.dashboardDataService.getEventsList({
        pageNo: 1,
        pageSize: 100,
        searchText: this.queryString,
        sortOrder: 'Asc'
      }).subscribe((data) => {
        this.data.next(data);
      })
    );
  }

  doRefreshTaskList() {
    this.dashboardDataService.getTaskList({
      pageNo: 1,
      pageSize: 100,
      searchText: '',
      eventId: this.currentEvent.value.id
    }).subscribe(data => {
      this.dataTaskList.next(data);
    });
  }

  public dispose() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

}
