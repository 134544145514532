<div class="event z-depth-2" *ngIf="model" id="eventDetail" >
  <div class="image-wrap left"  >
      <img mat-card-image class="featured-image" [style.background-image]="imageLink(model.featuredImageId)"  src="{{transparentSource}}" alt="form" >
  </div>
  <div class="content">
      <div class="card-info container" >
        <div class="row">
          <h2 class="header col s12 l12">
            {{model.title}}
          </h2>
        </div>
        <div class="row">
          <div class="info-box col s12 l12">
            <div class="title"><mat-icon>location_on</mat-icon></div>
            <div class="value"><a href="{{getMapLink(model.location)}}">{{model.location}}</a></div>
          </div>
        </div>
        <div class="row row_small-margin">
          <div class="info-box col s12 l12">
            <div class="title">{{'DASHBOARD.event.startTime' | translate }}</div>
            <div class="value">{{model.startDateTime | dateTimeFormat}}</div>
          </div>
        </div>
        <div class="row row_large-margin">
          <div class="info-box col s12 l12">
            <div class="title">{{'DASHBOARD.event.EndTime' | translate }}</div>
            <div class="value">{{model.endDateTime | dateTimeFormat}}</div>
          </div>
        </div>
        <div class="row">
          <div class="info-box col s12 l12">
            <div class="title">{{'DASHBOARD.event.Description' | translate }}</div>
            <div class="value description">{{model.description}}</div>
          </div>
        </div>
        <!--<div class="row">-->
          <!--<div class="info-box col l12">-->
            <!--<div class="title">{{'DASHBOARD.event.by' | translate }}</div>-->
            <!--<div class="value">{{model.departmentName}}</div>-->
          <!--</div>-->
        <!--</div>-->
      </div>
      <div class="btn-group">
        <button type="button" mat-raised-button color="accent"
                *ngIf="visibleJoin"
                mvmsDebounceClick (debounceClick)="joinEvent(model.id)" [debounceTime]="1500"
        >{{'SHARED.Button.Join' | translate }}</button>
      </div>
    </div>
  </div>
