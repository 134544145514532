<div class="form-container" *ngIf="model">
  <form class="form" [formGroup]="updateForm" (ngSubmit)="update()" #form="ngForm">
    <div class="form-section">
      <h2 class="header">{{'DASHBOARD.event.profile.Personal' | translate }}</h2>
      <div class="row">
        <div class="user-photo col l4 upload-demo upload-file">
          <div class="photo">
            <mvms-image [imageId]="updateForm.value.avatarId" [isAvatar]="false"></mvms-image>
          </div>
          <div class="upload">
            <div class="upload-btn">
              <mvms-croppie-btn #UploadFileComponent [title]="'DASHBOARD.event.profile.PersonalTab.uploadPhoto' | translate" [options]="CroppieOptions"
                (handleUploadFile)="uploadAvatar($event)"></mvms-croppie-btn>
            </div>
          </div>
        </div>
        <div class="full-name col l8">
          <h2 class="name">
            {{(isCurrentLanguageArabic && model.arabicFirstName) ? model.arabicFirstName : model.firstName }} {{ (isCurrentLanguageArabic && model.arabicMiddleName) ? model.arabicMiddleName : model.middleName }} {{(isCurrentLanguageArabic && model.arabicLastName) ? model.arabicLastName : model.lastName}}
          </h2>
          <h4 class="user-id" *ngIf="isDataLoaded">{{model.volunteerIdNo}}</h4>
        </div>
      </div>
      <div class="row">
        <h4 class="title col l4">{{'DASHBOARD.event.profile.PersonalTab.firstName' | translate }}</h4>
        <div class="col l4 value" floatLabel="never">
          {{model.firstName}}
        </div>
      </div>
      <div class="row">
        <h4 class="title col l4">{{'DASHBOARD.event.profile.PersonalTab.middleName' | translate }}</h4>
        <div class="col l4 value" floatLabel="never">
          {{model.middleName}}
        </div>
      </div>
      <div class="row">
        <h4 class="title col l4">{{'DASHBOARD.event.profile.PersonalTab.lastName' | translate }}</h4>
        <div class="col l4 value" floatLabel="never">
          {{model.lastName}}
        </div>
      </div>

      <div *ngIf="model.arabicFirstName || model.arabicMiddleName || model.arabicLastName">
        <div class="row">
          <h4 class="title col l4">{{'DASHBOARD.event.profile.PersonalTab.arabicFirstName' | translate }}</h4>
          <div class="col l4 value" floatLabel="never">
            {{model.arabicFirstName}}
          </div>
        </div>
        <div class="row">
          <h4 class="title col l4">{{'DASHBOARD.event.profile.PersonalTab.arabicMiddleName' | translate }}</h4>
          <div class="col l4 value" floatLabel="never">
            {{model.arabicMiddleName}}
          </div>
        </div>
        <div class="row">
          <h4 class="title col l4">{{'DASHBOARD.event.profile.PersonalTab.arabicLastName' | translate }}</h4>
          <div class="col l4 value" floatLabel="never">
            {{model.arabicLastName}}
          </div>
        </div>
    </div>

      <div class="row">
        <h4 class="title col l4 IE-feild-title">{{'DASHBOARD.event.profile.PersonalTab.Gender' | translate }}</h4>
        <div class="col l4" floatLabel="never">
          <mvms-dropdown-select [data]="genderType" [allowCustomValues]="false" [inputControl]="updateForm.controls.gender" [placeholder]="'' | translate">
          </mvms-dropdown-select>
        </div>
      </div>

      <div class="row">
        <h4 class="title col l4 IE-feild-title">{{'DASHBOARD.event.profile.PersonalTab.BirthDate' | translate }} </h4>
        <mat-form-field class="col l4" floatLabel="never">
          <input matInput formControlName="birthDate" (dateChange)="checkBirthDate()" [matDatepicker]="picker" [placeholder]="'DASHBOARD.event.profile.PersonalTab.BirthDate' | translate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="updateForm.controls.birthDate.hasError('matDatepickerParse')">
            {{'SHARED.Errors.birthdate' | translate }}
          </mat-error>
          <mat-error *ngIf="updateForm.controls.birthDate.hasError('required') && !updateForm.controls.birthDate.hasError('matDatepickerParse')">
            {{'SHARED.Errors.emptyField' | translate }}
          </mat-error>
          <mat-error *ngIf="updateForm.controls.birthDate.hasError('youngerFiveYears')">
            {{'SHARED.Errors.youngerFiveYears' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <h4 class="title col l4 IE-feild-title">{{'DASHBOARD.event.profile.PersonalTab.MaritalStatus' | translate }} </h4>
        <div class="col l4" floatLabel="never">
          <mvms-dropdown-select [data]="maritalStatusType" [allowCustomValues]="false" [inputControl]="updateForm.controls.maritalStatus"
            [placeholder]="'' | translate">
          </mvms-dropdown-select>
        </div>
      </div>

      <div class="row">
        <h4 class="title col l4 IE-feild-title">{{'DASHBOARD.event.profile.PersonalTab.nationality' | translate }}</h4>
        <div class="col l4 value" floatLabel="never">
            <mvms-autocomplete [data]="nationalityList" [inputControl]="updateForm.controls.nationality" [placeholder]="'DASHBOARD.event.profile.PersonalTab.nationality' | translate"></mvms-autocomplete>
        </div>
      </div>
      <div class="row" *ngIf="model.nationality.id == SaudiNational">
        <h4 class="title col l4 IE-feild-title">{{'DASHBOARD.event.profile.PersonalTab.NationalID' | translate }}</h4>
        <div class="col l4 value" floatLabel="never">
          {{model.nationalId}}
        </div>
      </div>

      <div class="row" *ngIf="!(model.nationality == SaudiNational)">
        <h4 class="title col l4 IE-feild-title">{{'DASHBOARD.event.profile.PersonalTab.PassportID' | translate }}</h4>
        <div class="col l4 value" floatLabel="never">
          {{model.passportNo}}
        </div>
      </div>

      <div class="row">
        <h4 class="title col l4 IE-feild-title">{{'DASHBOARD.event.profile.PersonalTab.profession' | translate }}</h4>
        <div class="col l4">
          <mvms-autocomplete [data]="professionList" [inputControl]="updateForm.controls.profession" [placeholder]="'DASHBOARD.event.profile.PersonalTab.SelectProfession' | translate"></mvms-autocomplete>
        </div>
      </div>
      <div class="row">
        <h4 class="title col l4 IE-feild-title">{{'DASHBOARD.event.profile.PersonalTab.Either' | translate }}</h4>
        <mat-radio-group class="col l8 radio-btn" formControlName="accountType">
          <mat-radio-button [value]="AccountType.AramcoEmployee" color="primary">{{'DASHBOARD.event.profile.PersonalTab.AramcoEmployee' | translate }}</mat-radio-button>
          <mat-radio-button [value]="AccountType.AramcoDependent" color="primary">{{'DASHBOARD.event.profile.PersonalTab.AramcoDepended' | translate }}</mat-radio-button>
          <mat-radio-button [value]="AccountType.None" color="primary">{{'DASHBOARD.event.profile.PersonalTab.None' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <!--div class="row" *ngIf="updateForm.controls.accountType.value != AccountType.None">
        <h4 class="title col l4">{{'DASHBOARD.event.profile.PersonalTab.BadgeNumber' | translate }}</h4>
        <mat-form-field class="col l4" floatLabel="never">
          <input matInput formControlName="badgeNo" type='text' [placeholder]="'DASHBOARD.event.profile.PersonalTab.BadgeNumber' | translate">
          <mat-error *ngIf="updateForm.controls.badgeNo.hasError('required')">
            {{'SHARED.Errors.emptyField' | translate }}
          </mat-error>
          <mat-error *ngIf="updateForm.controls.badgeNo.hasError('pattern'); else error5">
            {{'SHARED.Errors.badgeInvalid' | translate }}
          </mat-error>
          <ng-template #error5>
          <mat-error *ngIf="updateForm.controls.badgeNo.hasError('maxlength')">
            {{'SHARED.Errors.badgeMaxLength' | translate }}
          </mat-error>
            </ng-template>
        </mat-form-field>
      </div-->
    </div>
    <div class="form-section">
      <h2 class="header">{{'DASHBOARD.event.profile.PersonalTab.Contact' | translate }}</h2>
      <div class="row">
        <h4 class="title col l4 IE-feild-title">{{'DASHBOARD.event.profile.PersonalTab.City' | translate }}</h4>
        <div class="col l4">
          <mvms-autocomplete [data]="cityList" [inputControl]="updateForm.controls.city" [placeholder]="'DASHBOARD.event.profile.PersonalTab.City' | translate"></mvms-autocomplete>
        </div>
      </div>
      <div class="row">
        <h4 class="title col l4 IE-feild-title">{{'DASHBOARD.event.profile.PersonalTab.Address' | translate }}</h4>
        <mat-form-field class="col l4" floatLabel="never">
          <input matInput formControlName="address" type='text' [placeholder]="'DASHBOARD.event.profile.PersonalTab.Address' | translate">
        </mat-form-field>
      </div>
      <div class="row">
        <h4 class="title col l4 IE-feild-title">{{'DASHBOARD.event.profile.PersonalTab.Emergency' | translate }}</h4>
        <mat-form-field class="col l4 phone" floatLabel="never">
          <input matInput formControlName="emergencyContact" type='text' class="phone ltr" [placeholder]="'SHARED.Placeholders.phone' | translate" (blur)="checkEmergencyPhone()">
          <mat-error *ngIf="updateForm.controls.emergencyContact.hasError('notValid') || updateForm.controls.emergencyContact.hasError('pattern')">
            {{'SHARED.Errors.EmergencyPhoneNotValid' | translate }}
          </mat-error>
          <mat-error *ngIf="updateForm.controls.emergencyContact.hasError('equal')">
            {{'SHARED.Errors.equalNumber' | translate }}
          </mat-error>
          <mat-error *ngIf="updateForm.controls.emergencyContact.hasError('required')">
            {{'SHARED.Errors.emptyField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row form-row_btn-group">
      <div class="btn-group col l8 offset-l4">
        <div class="btn-group-item">
          <button type="submit" mvmsDebounceClick (debounceClick)="form.onSubmit($event)" [debounceTime]="1500" mat-raised-button class='capitalize IE-padding' [disabled]="!formChanged"
            color='accent'>{{'SHARED.Button.Save' | translate }}</button>
        </div>
        <div class="btn-group-item">
          <button type="button" mat-raised-button class='capitalize IE-padding' color='accent' (click)="reset()">{{'SHARED.Button.Cancel' | translate }}</button>
        </div>
      </div>
    </div>
  </form>
</div>
