import { Component, OnInit, OnDestroy } from '@angular/core';
import { EnquirieModel, EnquiriesListModel } from '../enquiries.model';
import { EnquiriesDataService } from '../enquiries-data.service';
import { MvmsAnimations } from '../../../../animations/animations';
import { Subscription } from '../../../../../../node_modules/rxjs';

@Component({
  selector: 'mvms-list-enquiries',
  templateUrl: './list-enquiries.component.html',
  styleUrls: ['./list-enquiries.component.scss'],
  animations: MvmsAnimations
})
export class ListEnquiriesComponent implements OnInit, OnDestroy {
  public enquiryList: EnquiriesListModel[];

  public currentItem: EnquirieModel | boolean = false;
  public currentItemId;
  isLoading: boolean;

  subscribtions: Subscription[] = [];

  constructor(private enquiriesDataService: EnquiriesDataService) {
    this.subscribtions.push(
      this.enquiriesDataService.emitRefreshList
        .subscribe(
          () => this.refreshList()
        )
    );
  }

  ngOnInit() {
    this.refreshList();
  }

  ngOnDestroy() {
    this.subscribtions.forEach(s => s.unsubscribe());
  }

  refreshList() {
    this.isLoading = true;

    this.subscribtions.push(
      this.enquiriesDataService.getList({
        pageNo: 1,
        pageSize: 100,
        searchText: '',
        readFilter: 'all',
        answerFilter: 'all'
      })
        .subscribe(data => {
          this.enquiryList = data.items;
          this.isLoading = false;
        },
          () => this.isLoading = false
        )
    );
  }
  readItem(item) {
    if (item.id === this.currentItemId) {
      this.currentItemId = null;
      return;
    }
    const id = item.id;
    const el = this.enquiryList.find(x => x.id === item.id);
    el.isReplyRead = true;
    this.subscribtions.push(
      this.enquiriesDataService.getItem(id).subscribe(currentItem => {
        this.currentItem = currentItem;
        this.currentItemId = currentItem.id;
        this.enquiriesDataService.emitRefreshList.next(true);
      })
    );
  }

}
