import { Component, OnInit, ViewChild, Input, OnChanges } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { DataSource } from '@angular/cdk/table';
import { Router } from '@angular/router';
import { MyTask } from '../my-events-tasks.model';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'mvms-my-tasks',
  templateUrl: './my-tasks.component.html',
  styleUrls: ['./my-tasks.component.scss']
})
export class MyTasksComponent implements OnInit, OnChanges {
  @Input() taskList: MyTask[];
  dataSource:any;
  displayedColumns:string[]=[];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  columnNames = [{
    id: 'status',
    value: 'St'

  }, {
    id: 'title',
    value: 'Task'
  },
  {
    id: 'priority',
    value: 'Pr'
  },
  {
    id: 'dueDate',
    value: 'Due-Date'
  }];

  constructor(private router: Router) { }

  ngOnInit() {
    console.log('init', this.taskList);
    this.displayedColumns = this.columnNames.map(x => x.id);
      this.createTable();
  }
  ngOnChanges() {
    if (this.taskList) {
      this.createTable();
    }
  }
  createTable() {
    console.log('create', this.taskList);
    this.dataSource = new MatTableDataSource(this.taskList);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    console.log('dataSource', this.dataSource);
  }
  goToTask(row:any) {
    this.router.navigate([`/dashboard/myEvents/${row.eventID}/tasks`]);
  }

}
