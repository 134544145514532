<div class="events-list">
  <div *ngIf="hasAccess; else noAccess">
    <div *ngIf="eventsList; else noRecords">
      <div class="row">
        <div class="col s6">
            <div>
            <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider></div>
            <div> Range Selected : {{minValue}} - {{maxValue}}</div>
        </div>
        <div class="col s6">
          <button type="button" mat-raised-button class='capitalize IE-padding cert-button' color='accent'
            (click)="printCertificate()">
            {{'DASHBOARD.event.printVolunteeringCertificate' | translate}}</button>
        </div>
      </div>
      <div class="search search-width">
        <mat-form-field class="search-input">
          <mat-icon class="search-icon" svgIcon="search" matPrefix></mat-icon>
          <input matInput [placeholder]="'DASHBOARD.event.searchEventPlaceholder' | translate" (input)="search($event)"
            [(ngModel)]="queryString">
        </mat-form-field>
      </div>
     
      <h5 *ngIf="currentEventsList.length">{{'DASHBOARD.event.currentEvents' | translate}}</h5>
      <div class="list row">
        <div class="list-item col l6 m6 s12" *ngFor="let event of currentEventsList">
          <mvms-events-list-item [event]="event"></mvms-events-list-item>
        </div>
      </div>

      <h5 *ngIf="pastEventsList.length">{{'DASHBOARD.event.pastEvents' | translate}}</h5>
      <div class="list row">
        <div class="list-item col l6 m6 s12" *ngFor="let event of pastEventsList">
          <mvms-events-list-item [event]="event"></mvms-events-list-item>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noAccess>
    <mvms-no-access></mvms-no-access>
  </ng-template>

  <ng-template #noRecords>
    <mvms-no-records *ngIf="!isLoading"></mvms-no-records>
  </ng-template>

  <!-- <div class="row">
    <div #googlemap style='width: 100%; height: 500px;'></div>
  </div> -->
</div>