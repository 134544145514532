<div class="img-crop upload-demo" #box>
  <div class="upload-msg">
    Upload a file to start cropping
  </div>
  <div class="crop-box">
    <div class="upload-demo-wrap">
      <div id="upload-demo"></div>
    </div>
  </div>
  <div class="crop-btn">
    <div class="actions upload-btn">
      <a class="file-btn upload-file-btn">
        <button type="button" mat-raised-button class='capitalize' color='accent'> {{'SHARED.Button.Upload' | translate }}</button>
        <input type="file" id="upload" class="input" accept="image/*" />
      </a>
      <button class="upload-result capitalize" mat-raised-button color='accent' (click)="dialogRef.close()">Apply</button>
    </div>
  </div>
</div>
