<div class="list-card-details">
  <!--<p class="name"> {{task.status}}</p>-->
  <p class="event"> {{task.title}}</p>
  <div class="task-status">
    <div class="priority">
      <img class="responsive-img" *ngIf="task.priority == 0" [title]="TaskPriority[task.priority]"
           src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGCSURBVGhD7ZpLSsRAFEWzA3UFLsCJ4H7cir9VuB8/e1DQsTOnjvReyAUpCiqv6lXqJeTAgVCpfA5NupPung4ODoZxAu/hK3yZlzm2Kc7hG/xN5BjXbQKe6AdMIyTXhY8pRcjQMUsjZMgYa4QMFVMbIUPEtEbIoTFeEXJIjHeEXDVmacQPfEzkWG7ufz9h9xjLK/ENUziWm5vaNcYSQVtCaJcYawRtDaGuMTUR1COEusTURlCvENoU0xJBPUNoVUxrBPUOoaaYM/gOczuy2COE8txOYZEHmNuB1V4h9BYW4TN2bmOrPUOeYZHdhNzB3MZWe4bcwCK8kKJf7Iu/VtrF26+wxvAW/RpezV7CFI5pPecuua2Xq366f8ELWIJzODe3j5xNEcI7ZkiE8IoZGiFaY0JEiNqYUBGiJiZchOCBeMDcibS4aoTwjhkSIbxihkaI1pgQEaI2JlSEsMaEjBBLY0JHiFLMJiIET3TzP08LPsXxsZnP10/z8ub+MHBwsA+m6Q97JX+ZCDZLfQAAAABJRU5ErkJggg==">
      <img class="responsive-img"  *ngIf="task.priority == 1" [title]="TaskPriority[task.priority]"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHtSURBVGhD7ZpLSsRAFEVb0ZmiDnTqRPfkZywuw98CdA2i21D870HB31gRBzrVeyEXiuLZle5UpSqSAwea5HUlx+50E3XQ09OTjTm4D+/gbfWY2zrFMryHP57cxn2dgCf6CP0IyX3Fx4QiZNExdSNkkTGjRsiiYsaNkEXENI2QWWNiRcgsMbEjZKsxqSLkE0wekzpCJo1pK0ImiWk7QkaNyRUho8TkjpCNYkqJkGPF1Il4M7ZZflda+3xDa44UswAfoLUQPYMrkCzCE2jNfcA1OFW5Dj+hNXsMuRbh2jyGNUd5bvMwyAG0FqD8icxAl0l4Cf1ZRvgwxp+7gBPQZRa+QH9W7sIgvMe2nkwPocU2dOe+IF8FH27jPnd2C1ocQXfO9QYGGRbCxS2KDNmD1pPpM+TL7sK31hX0Z/k28tmE/hzfllzDhcd4hf6s3IFBeCENu9jPoS72JXgKrTle2BtwupKP/7rY+YHBtcgq5HVjzVGeW+1fK9X5+H03tlmO8vEbWjPZd0mb/otv90YRIndMlAiRKyZqhGg7JkmEaCsmaYRIHdNKhOCBeEDrRJrYaoSIHZMlQsSKyRohmsYUESHGjSkqQowaU2SEqBtTdIQIxXQiQvBEO//nacG7ON428/76unrcuX8Y6On5HwwGvzb/iZKiLpddAAAAAElFTkSuQmCC">
      <img class="responsive-img" *ngIf="task.priority == 2" [title]="TaskPriority[task.priority]"
           src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGCSURBVGhD7ZpdSsQwFEa7A3UFbsZ9Oeoq3I8/e3BAdyA++OKLej+YDyQEk5t77yQZcuBASTttDkNb2pltsVh040y8FZ/Fp8MyxqbiUnwRfxIxhnVTgIm+imkExbrhY0oRdOiY2gg6ZIw2gg4V0xpBh4ixRtCuMV4RtEuMdwQ9akxtxJd4n4ix3LZ/fRPDYzTfxIeYgrHctqmhMZoIaAmBITHaCGgNga4xLRHQIwS6xLRGQK8QaIqxREDPENgUY42A3iFQFXMh7sXcjjRGhEDM7VwscifmdqA1KgTuxCJ4xs59WGtkyKNY5GRCbsTch7VGhlyLRXAieZzs3+J7IsZy22rE3KpfK3lcfiPsdi/xtOvd3UtTBGmN+RSvEjGW2/Y/XSJIS4zHVcs1gmhjrCEhEUQTYwkJjSC1MUO/fCA4EA6Ym4jFo0YQ75guEcQrpmsEscYMEUFaY4aKINqYISNIbczQEaQUM0UEwUSn/3ma4CkOj814vn44LE/3h4HF4jTYtl/lc53npDaPeQAAAABJRU5ErkJggg==">
    </div>
    <div class="edit-btn">
      <button mat-icon-button (click)="updateTask(task.id)">
        <mat-icon>edit</mat-icon>
      </button>
      <!--<a class="btn-floating" (click)="updateTask()"><i class="material-icons">edit</i></a>-->
    </div>
  </div>

</div>
