<div id="coc"  fxLayout="column"  fusePerfectScrollbar [class.rtl]="isCurrentLanguageArabic" [attr.dir]="isCurrentLanguageArabic  ? 'rtl' : ''">

    <div class="row"  >
      <div class="col s11 l4 x3" style="color:white!important; ">
      <div class="head">
      <h2 class="header">{{'COC.Title' | translate }}</h2>
      <p></p>

    </div>
      
    </div>
      <div class="col s12 l8 x9" style="background-color:white;border-radius:20px"> 
      <mat-dialog-content [innerHTML]="coc.content | safeHtml"> </mat-dialog-content> 
      <div class="row"  style="text-align: center;"> 
      <mat-card-actions style="width: 100%;display: inline-block;">
      <button mat-raised-button (click)="submitcoc()" *ngIf="!IsAccepted" color="accent" class="submit-button" aria-label="LOG IN">           
            {{'COC.Accept' | translate }}
          </button>
      </mat-card-actions>
    </div>
  </div>

</div>
