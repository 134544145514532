<div id="forgot-password" fxLayout="column" fusePerfectScrollbar [class.rtl]="isCurrentLanguageArabic" [attr.dir]="isCurrentLanguageArabic  ? 'rtl' : ''">
  <div class="btn-close-dialog">
    <button mat-icon-button (click)="closeDialog()" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div id="forgot-password-form">
      <div class="title">{{ 'LOGIN.ChangePass' | translate }}</div>
      <form name="forgotPasswordForm" [formGroup]="changePasswordForm" (ngSubmit)="setNewPassword()">
        <mat-form-field class="full-width">
          <input matInput formControlName="newPassword" [type]="showPassword1.checked ? 'text' : 'password'" [placeholder]="'LOGIN.Password' | translate" mvmsBlockPaste>
          <mat-button-toggle #showPassword1 matSuffix><mat-icon *ngIf="showPassword1.checked">visibility</mat-icon><mat-icon *ngIf="showPassword1.checked===false">visibility_off</mat-icon></mat-button-toggle>
          <mat-error *ngIf="changePasswordForm.controls['newPassword'].hasError('pattern') ||
                            changePasswordForm.controls['newPassword'].hasError('minlength') ||
                            changePasswordForm.controls['newPassword'].hasError('maxlength')">
            {{'SHARED.Errors.passwordMust' | translate }}
          </mat-error>
          <mat-error *ngIf="changePasswordForm.controls['newPassword'].hasError('required')">
            {{'SHARED.Errors.emptyField' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput formControlName="confirmNewPassword" [type]="showPassword2.checked ? 'text' : 'password'" [placeholder]="'LOGIN.confirmPassword' | translate"
            mvmsBlockPaste>
            <mat-button-toggle #showPassword2 matSuffix><mat-icon *ngIf="showPassword2.checked">visibility</mat-icon><mat-icon *ngIf="showPassword2.checked===false">visibility_off</mat-icon></mat-button-toggle>
          <mat-hint *ngIf="changePasswordForm.hasError('isMatch') &&
                          changePasswordForm['controls']['newPassword'].touched &&
                          changePasswordForm['controls']['confirmNewPassword'].touched">
            {{'SHARED.Errors.PasswordNotMatch' | translate }}
          </mat-hint>
          <mat-error *ngIf="changePasswordForm.controls['confirmNewPassword'].hasError('required')">
            {{'SHARED.Errors.emptyField' | translate }}
          </mat-error>
        </mat-form-field>
        <div class="btn-submit">
          <button mat-raised-button class="submit-button" color="accent" type="submit" aria-label="UPDATE PASSWORD">
            {{'SHARED.Button.RESET' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
