import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {map} from 'rxjs/operators/map';
import {startWith} from 'rxjs/operators/startWith';
import {Observable} from 'rxjs/Observable';
import {FormControl} from '@angular/forms';
import {IResponseSimpleEntity} from '../../../../types';
import {TranslateService} from '@ngx-translate/core';
import {LANGUAGES} from '../../../constants/constant';


@Component({
  selector: 'mvms-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, OnChanges {
  public filteredArray: Observable<IResponseSimpleEntity[]>;
  @Input() public data: IResponseSimpleEntity[];
  @Input() public inputControl:  FormControl;
  @Input() public placeholder:  string;
  @Input() public allowCustomValues: boolean;
  public isCurrentLanguageArabic: boolean;
  constructor(private translateService: TranslateService) { }
  ngOnChanges(changes: SimpleChanges) {
    if (this.data && this.data != null) {
      this.createAuto();
    }
  }

  ngOnInit() {
    this.isCurrentLanguageArabic = this.translateService.currentLang === LANGUAGES.arabic;
  }

  createAuto() {
    const displayNameRef = this.displayName;

    this.filteredArray = this.inputControl.valueChanges.pipe(
      startWith<string | IResponseSimpleEntity>(''),
      map(entity => {
          if (typeof entity === 'string') {
            return entity;
            }
          if (entity != null) {
            return displayNameRef(entity);
        }
      }),
      map(val =>  val ? this.filter(val, this.data) : this.data)
    );
  }

  public filter(val, arr) {
    const displayNameRef = this.displayName;
    return arr.filter(item => displayNameRef(item).toLowerCase().indexOf(val.toLowerCase()) === 0);
  }
  public displayName(val): string {
    if (val) {
      if (typeof val === 'string') {
        return val;
      }
      if (val.hasOwnProperty('value')) {
        return val.value;
      }
      if (val.hasOwnProperty('name')) {
        return val.name;
      }
    }
    return undefined;
  }
}
