import { Injectable } from '@angular/core';
import {BASE_URL} from '../../../constants/constant';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {Subject} from 'rxjs/Subject';


@Injectable()
export class AttendanceDataService {
  private url = `${BASE_URL}api/public/attendance/`;

  public emitRefreshList: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {}

  getList(filterParam:any): Observable<any> {
    return this.http.post<any>(`${this.url}/list`, filterParam);
  }
}
