import { Component, OnInit} from '@angular/core';
import {Inject, Injectable} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import {HomepageSectionForPortal, HomepageSectionTypeEnum, CodeofconductForPortal} from '../../services/models/homepage-section-for-portal.model';
import {ContentService} from '../../services/content.service';
import {LanguageService} from '../../services/language.service';
import { Subject } from 'rxjs';
import { SafeHtmlPipe } from 'src/app/pipes/safehtml.pipe';
import { UserProfileDataService } from '../dashboard/user-profile/user-profile-data.service';
import { CurrentUserService } from 'src/app/services/current-user.service';

@Component({
  selector: 'mvms-code-of-conduct',
  templateUrl: './code-of-conduct.component.html',
  styleUrls: ['./code-of-conduct.component.scss']
})
export class CodeOfConductComponent implements OnInit {
  isCurrentLanguageArabic: boolean;
  public content: string;
  public coc: CodeofconductForPortal;
  public user: any;
  public ID: string;
  public IsAccepted: boolean = true;


  constructor(public dialogRef: MatDialogRef<CodeOfConductComponent>,
    private languageService: LanguageService,private userprofiledataservice: UserProfileDataService, 
     @Inject(MAT_DIALOG_DATA) public data: any, public contentService: ContentService, private currentUserService: CurrentUserService) {
    this.coc = data;
    this.user = this.coc.user;
    this.user ? this.IsAccepted = this.user.isCOC : this.IsAccepted = true;
    this.ID = this.coc.id;
   }

  ngOnInit(): void{
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
  }
  public submitcoc():void{

    this.userprofiledataservice.UpdateCOC({ID: this.ID}).subscribe(() => {
      this.currentUserService.clearCurrentUser();
      this.currentUserService.getCurrentUser().subscribe();
    })
    this.dialogRef.close(true);

  }
}
