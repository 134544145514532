import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LoginComponent} from './login.component';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class LoginDialogService {
  public dialogRef: MatDialogRef<LoginComponent>;
  public afterClosed: Subject<any> = new Subject<any>();
  public beforeClosed : Subject<any> = new Subject<any>();
  constructor(private matDialog: MatDialog) {
  }


  public open() {
    this.dialogRef = this.matDialog.open(LoginComponent, {panelClass:'cdk-overlay-pane'});
    this.dialogRef.afterClosed().subscribe(data => {
      this.afterClosed.next(data);
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
