
<div class="offline-training z-depth-1" *ngIf="model">
  <h2 class="header">{{model.title}}</h2>
  <p class="training-params">{{ 'DASHBOARD.event.training.videoTime' | translate }}</p>
  <p class="training-params m-bottom">{{ 'DASHBOARD.event.training.textTime' | translate }}</p>
  <div class="info-row">
    <!-- <p class="training-params sub">{{ 'DASHBOARD.event.training.author' | translate }}</p> -->
    <p class="training-params sub">
      {{ 'DASHBOARD.event.training.sessionAvailavleFrom' | translate }} {{model.startDate | dateTimeFormat}} 
      {{ 'DASHBOARD.event.training.to' | translate }} {{model.endDate | dateTimeFormat}}</p>
  </div>
  <div class="video-box">
    <div class="player-container">
      <youtube-player
        width="100%"
        height="100%"
        [videoId]="model.videoLink.split('=')[1]"
        class="player"
      ></youtube-player>
    </div>
  </div>
  <div class="description">{{model.description}}</div>
  <div class="btn-group">
    <div class="btn-group-item" *ngIf="!currentTrainingAttempts">
      <button  type="button" mat-raised-button color="accent" (click)="startTest()">
        {{'DASHBOARD.event.training.TakeTest' | translate }}
      </button>
    </div>
    <div class="btn-group-item" *ngIf="currentTrainingAttempts > 0">
      <button  type="button" mat-raised-button color="accent" (click)="reTakeTest()">
        {{'DASHBOARD.event.training.RetakeTest' | translate }}
      </button>
    </div>
  </div>
</div>
