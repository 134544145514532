<div class="my-dashboard">
  <div class="board">
      <div class="z-depth-3" style="margin-left: 10px; margin-bottom: 10px;">
              <button type="button" mat-raised-button color="accent" mvmsDebounceClick (debounceClick)="printExperienceCertificate()" [debounceTime]="1500">
                {{'DASHBOARD.PrintExperienceCetificate' | translate }}
              </button>
      </div>
      <mat-grid-list [cols]="breakpoint" rowHeight="300px" (window:resize)="onResize($event)"> 
          <mat-grid-tile
              [colspan]="1"
              [rowspan]="1">
              <div class="card z-depth-3">
                  <h3 class="header">
                    {{'DASHBOARD.myDashboard.upcomingEvent' | translate }}
                  </h3>
                  <mvms-my-upcoming-event></mvms-my-upcoming-event>
                </div>
          </mat-grid-tile>
          <mat-grid-tile
              [colspan]="1"
              [rowspan]="2">
              <div class="card z-depth-3 tasks-card">
                  <h3 class="header">
                    {{'DASHBOARD.myDashboard.tasks' | translate }}
                  </h3>
                  <div class="content">
                    <mvms-my-tasks [taskList]="myTasksList"></mvms-my-tasks>
                  </div>
                </div>
          </mat-grid-tile>
          <mat-grid-tile
          [colspan]="1"
          [rowspan]="1">
          <div class="card z-depth-3">
              <h3 class="header">
                {{'DASHBOARD.myDashboard.badgesEarned' | translate }}
              </h3>
              <mvms-badge-list></mvms-badge-list>
            </div>
      </mat-grid-tile>
      <mat-grid-tile
          [colspan]="1"
          [rowspan]="1">
          <div class="card z-depth-3">
              <h3 class="header">
                {{ 'DASHBOARD.myDashboard.pastEvents' | translate }}
              </h3>
              <div class="content">
                <mvms-my-events [eventList]="myEventsList"></mvms-my-events>
              </div>
            </div>
      </mat-grid-tile>
        </mat-grid-list>
  </div>
  <div class="map">

  </div>

</div>
