
  
  
  <div *ngIf="!isCurrentLanguageArabic"  >
  <div class="row">
  <div class="col s12 l4" >
    <h2>Ithra Volunteer Award</h2>
  </div>
  <div class="col s12 l8">
    <p>
    Ithra Volunteer Award is the first volunteering award that recognizes Ithra volunteers. 
    This award is a Kingdom-wide contest where Ithra will honor and encourage their volunteers to think creatively in 
    making impactful volunteering initiatives in three tracks:    
    </p>
    
  <div class="row" style="margin-bottom:60px;margin-top:55px " >
        <div class="awardicon">
            <img src="/assets/images/Community.svg" alt="Community" />
              <b>COMMUNITY</b><br/>
              Community initiatives aim to solve a social issue and improve the society.
              <br/>
          </div>

        <div class="awardicon">
            <img src="/assets/images/Culture.svg" alt="Community" />
              <b>CULTURE</b> <br/>
              Cultural initiative focus on art, cultural values, and knowledge.
              <br/>
            
          </div>

        <div class="awardicon">
            <img src="/assets/images/education.svg"  alt="Community" />
              <b>EDUCATION</b> <br/>Educational initiatives support the academic field in transferring specific knowledge to the community.
        </div>
        </div>
  </div>
  
  </div>

  <div class="row" style="margin-bottom:100px" >
    
    <div class="col s12 l4">
    <p> <img src="../../../assets/images/IthraAward.jpg" style="border-radius:20px" alt="" /> </p>
    </div><div class="col s12 l8"  style="line-height:1;" >
    <p>
        All the submitted ideas and initiatives will be evaluated by local and international committees from different foundations. There will be one winner from each track and all the winners will be assigned to volunteer cultural center around the globe.   
      </p>
      <p>Do you have a creative initiative?</p> 
      <p>Does your initiative have a positive impact in the community? </p>
      <p>Did you complete 100 volunteering hours in Ithra? </p>

      <p [ngStyle]="{'font-weight':'600'}" >Then you can be the winner of Ithra Volunteer Award... Don’t miss the chance to show your creativity </p>

      <p>Please read the terms and conditions carefully before completing your registration.</p>
    </div>
  </div>


    <div class="row Terms" style="border-radius: 20px;margin-bottom:100px">
        <div class="col s12 l4" ><h2>Terms and Conditions</h2> </div>
        <div class="col s12 l8" style="margin-top:22px; margin-bottom:22px"><p> 1. Individuals and groups may participate (Max 4 members in each group) <br/>
                              2. Any nationality may participate <br/>
                              3. Participants must be at least 12 years of age. <br/>
                              4. The initiative should be creative and innovative <br/>
                              5. The quality of the initiative and related work must be to the highest standard.  <br/>
                              6. The initiative must have a positive and systainable impact on the community.  <br/>
                              7. The volunteer must complete a minimum 100 hours with Ithra  <br/> </p>  
           
        </div>
   </div>
          
          
    <div class="row timeline" style="margin-top:50px" >
      <div style=" margin-top:45px; margin-bottom:40px"> <h4>Ithra Volunteer Award Timeline </h4> </div>
      <div  [ngStyle]="{'background':'rgba(87, 98, 104, 0.03)','margin-left':'12px','text-align':'center'}">
      <p style="margin-top: 70px;"><img src="/assets/images/awards-timeline-en1.png" alt="Timeline"/> </p>  </div>
        
    </div>
    <div class="row">
      <div class="col s12" style="margin-top:35px">
        <h4>Frequently asked questions
      <a href="https://www.ithra.com/en/faqs-ithra-volunteer-award/" target="_blank">Click here</a> </h4>
      </div>
    
  
</div>
</div>
<div *ngIf="isCurrentLanguageArabic">
<div class="row">
  <div class="col s12 l4" >
    <h2>جائزة إثراء للتطوع</h2>
  </div>
  <div class="col s12 l8">
    <p>
    هي أول جائزة خاصة بالمتطوعين المبدعين على مستوى المملكة، يكرم إثراء ويشجع من خلالها المتطوعين على التفكير الإبداعي وإنشاء مبادرات تطوعية في ثلاثة مسارات 
    </p>
  
  <div class="row" style="margin-bottom:60px;margin-top:55px">
    <div class="awardicon" [ngStyle]="{'float':'right', 'text-align':'center'}">
        <img src="/assets/images/Community.svg" alt="Community" />
          <b>مجتمعي</b> هي مبادرات تهدف إلى حلّ قضيّة اجتماعية وتحسين المجتمع
      </div>

    <div class="awardicon" [ngStyle]="{'float':'right', 'text-align':'center'}">
        <img src="/assets/images/Culture.svg" alt="Community" />
          <b>ثقافي</b>  هي مبادرات تركّز على الفنّ والقيم الثقافية والمعرفيّة
      </div>

    <div class="awardicon" [ngStyle]="{'float':'right', 'text-align':'center'}">
        <img src="/assets/images/education.svg" alt="Community" />
        <div class="awardcontainer">
          <b>تعليمي</b> هي مبادرات تدعم المجال الأكاديمي عبر نقل المعرفة إلى المجتمع
        </div>
    </div>
    </div>
</div>
  </div>
  <div class="row" style="margin-bottom:100px">
  <div class="col s12 l4">
    <p> <img src="../../../assets/images/IthraAward.jpg" style="border-radius:20px" alt="" /> </p>
    </div><div class="col s12 l8"  style="line-height:1; margin-top:22px" >
    
        <p>
        سيتم تقييم جميع الأفكار والمبادرات المقدمة من قبل لجان محلية ودولية ومؤسسات مختلفة، وسيكون هناك فائز واحد في كل مسار، كما ستتاح الفرصة لجميع الفائزين للتطوع في أحد المراكز الثقافية حول العالم. 
      </p>
      <p>هل لديك مبادرة مبدعة؟  </p> 
      <p>هل لمبادرتك تأثير إيجابي على المجتمع؟ </p>
      <p>هل استكملت 100 ساعة تطوعية في إثراء؟ </p>

      <p [ngStyle]="{'font-weight':'600'}">إذاً يمكنك أن تكون الفائز بجائزة إثراء التطوع ... لا تفوت فرصة إظهار إبداعك </p>

      <p>الرجاء قراءة الشروط والأحكام بعناية قبل استكمال عملية التسجيل </p>
</div>
</div>

  <div class="row Terms" style="border-radius: 20px;margin-bottom:100px">
        <div class="col s12 l4" style="Margin-top:22px" ><h2>الأحكام و<br/>الشروط</h2> </div>
        <div class="col s12 l8"  style="margin-top:22px; margin-bottom:21px"> <p> 1. يمكن لجميع الأفراد والمجموعات المشاركة (4 أعضاء لكل مجموعة كحد أقصى) <br/>
      2.	يمكن لجميع الأشخاص من جميع الجنسيات المشاركة <br/>
      3.	يجب على المشارك أن يكون من الفئة العمرية 12 أو أكثر   <br/>
      4.	يجب أن تكون الأفكار إبداعية وابتكارية  <br/>
      5.	يجب أن تكون جودة المبادرة والأعمال المتعلقة بها متماشية مع أعلى المعايير <br/>
      6.	يجب أن يكون للمبادرة تأثير إيجابي ومستدام على المجتمع <br/>
      7.	على المتطوع  أن يُكمل  100 ساعة تطوعية في إثراء  كحد أدنى <br/> </p> 
           
        </div>
   </div>

   <div class="row timeline" style="margin-top:50px">
      <div style="margin-top:45px; margin-bottom:40px"> <h4> الخط الزمني لجائزة إثراء التطوع  </h4></div>
          <div style="margin-bottom:60px ;" [ngStyle]="{'background':'rgba(87, 98, 104, 0.03)','margin-left':'12px','text-align':'center'}">
          <p style="margin-bottom: 25px;"> <img src="/assets/images/awards-timeline-ar1.png" alt="Timeline"/> </p>  </div>
        </div>
      <div class="row">
      <div class="col s12" style="margin-top:35px">
        <h4>الأسئلة الشائعة لجائزة إثراء للتطوع  
        <a href="https://www.ithra.com/ar/faqs-ithra-volunteer-award/" target="_blank"> انقر هنا  </a></h4> 
        </div>
       </div>
    
   
      <div class="row" [ngStyle]="{'text-align':'center'}">
            <button class="IE-padding" mvmsDebounceClick (debounceClick)="applyAward()"
              [debounceTime]="2000">{{'SHARED.Button.award' | translate }}</button>
      </div>
</div>
