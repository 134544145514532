import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { EditorConfig } from '../../../../../constants/constant';
import { LanguageService } from '../../../../../services/language.service';

@Component({
  selector: 'mvms-create-enquiry',
  templateUrl: './widthdraw-reason.component.html',
  styleUrls: ['./widthdraw-reason.component.scss']
})
export class WithdrawReasonComponent implements OnInit {

  validate = false;

  isCurrentLanguageArabic: boolean;

  withdrawalReasonForm = this.fb.group({
    reason: ['', [Validators.required, Validators.maxLength(200)]],
  });

  constructor(
    private dialogRef: MatDialogRef<WithdrawReasonComponent>,
    private fb: FormBuilder,
    private languageService: LanguageService) { }

  ngOnInit() {
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
  }
  close(success: boolean) {
    this.dialogRef.close({
      reason: this.withdrawalReasonForm.controls.reason.value,
      success: success
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }

  submit() {
    if (this.withdrawalReasonForm.valid) {
      this.dialogRef.close({
        reason: this.withdrawalReasonForm.controls.reason.value,
        success: true
      });
    } else {
      this.withdrawalReasonForm.controls['reason'].markAsTouched();
      this.validate = true;
    }
  }

}
