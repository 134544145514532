import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { BASE_URL } from './constants/constant';
import { ContentService } from './services/content.service';
import { ToasterConfig, ToasterContainerComponent, ToasterService } from 'angular5-toaster/dist';
import { appInjector } from './app-injector';
import { CurrentUserService } from './services/current-user.service';
import { TokenService } from './auth/services/token.service';
import { HomepageSectionTypeEnum, HomepageSectionForPortal } from './services/models/homepage-section-for-portal.model';
import { LanguageService } from './services/language.service';
import { DepartmentResources } from './services/models/department-resources.model';
import { AutoLogoutService } from './auth/services/auto-logout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'app';

  BASE_URL = BASE_URL;

  isCurrentLanguageArabic: boolean;

  footerSection: HomepageSectionForPortal;

  loaderReady = false;

  currentVersion: string;

  year: number = new Date().getFullYear();

  @ViewChild(ToasterContainerComponent) toasterContainer;

  departmentResources: DepartmentResources;

  toasterconfig: ToasterConfig =
    new ToasterConfig({
      showCloseButton: true,
      tapToDismiss: false,
      animation: 'fade',
      timeout: -1,
      positionClass: 'toast-bottom-right',
      mouseoverTimerStop: true
    });

  constructor(
    private contentService: ContentService,
    private languageService: LanguageService,
    private currentUserService: CurrentUserService,
    private tokenService: TokenService,
    private injector: Injector,
    private toasterService: ToasterService,
    private autoLogoutService: AutoLogoutService) {
    appInjector(this.injector);
  }

  ngOnInit(): void {
    this.contentService.currentVersion$
      .subscribe(v => this.currentVersion = v);

    this.contentService.departmentResources$
      .subscribe(r => this.departmentResources = r);

    this.contentService
      .getHomepageSection(HomepageSectionTypeEnum.footer)
      .subscribe(
        section => this.footerSection = section
      );

    if (this.tokenService.getToken() != null) {
      this.currentUserService.getCurrentUser().subscribe(() => {
      });
    }

    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
    if (this.isCurrentLanguageArabic) {
      const body = <HTMLBodyElement>document.getElementsByTagName('body')[0];
      body.classList.add('rtl');
      body.setAttribute('dir', 'rtl');
    }
  }

  click($event) {
    if (this.toasterContainer.toasts.length && !$event.target.closest('toaster-container')) {
      this.toasterService.clear(this.toasterContainer.toasts[this.toasterContainer.toasts.length - 1].toastId);
    }
  }

}
