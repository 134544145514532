import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import 'hammerjs';

import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {JoinFormsComponent} from './components/join-forms/join-forms.component';
import {MaterialComponentsModule} from './modules/material-components/material-components.module';
import {CreateUserService} from './services/create-user.service';
import {HeaderComponent} from './components/shared/header/header.component';
import {AuthModule} from './auth/auth.module';
import {BASE_URL} from './constants/constant';
import {MedicalComponent} from './components/dashboard/events/medical/medical.component';
import {TrainingComponent} from './components/dashboard/events/training/training.component';
import {TrainingService} from './components/dashboard/events/training/training.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ContentService} from './services/content.service';
import {AboutUsComponent} from './components/aboutus/aboutus.component';
import {GoogleMapsModule} from 'google-maps-angular2';
import {RecaptchaModule, RecaptchaLoaderService} from 'ng-recaptcha';
import {MaterializeModule} from 'angular2-materialize';
import {PublicService} from './services/public.service';
import {RecaptchaFormsModule} from 'ng-recaptcha/forms';
import {EnumKeysPipe} from './pipes/enumkeys.pipe';
import {AutocompleteServerSideComponent} from './components/shared/autocomplete-server-side/autocomplete-server-side.component';
import {AutocompleteComponent} from './components/shared/autocomplete/autocomplete.component';
import {TabNavigationComponent} from './components/shared/tab-navigation/tab-navigation.component';
import {UploadFileComponent} from './components/shared/upload-file/upload-file.component';
import {ImageComponent} from './components/shared/image/image.component';
import {RecoverPasswordComponent} from './components/account/recover-password/recover-password.component';
import {ChangePasswordComponent} from './components/account/change-password/change-password.component';
import {LoginComponent} from './components/account/login/login.component';
import {ContentComponent} from './components/content/content.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {NewsComponent} from './components/news/news.component';
import {NgMasonryGridModule} from 'ng-masonry-grid';
import {OpportunitiesComponent} from './components/opportunities/opportunities.component';
import {NavPanelComponent} from './components/dashboard/nav-panel/nav-panel.component';
import {EventsComponent} from './components/dashboard/events/events.component';
import {EventStatusComponent} from './components/dashboard/events/event/event-status/event-status.component';
import {EventsListComponent} from './components/dashboard/events/events-list/events-list.component';
import {EventsListItemComponent} from './components/dashboard/events/events-list/events-list-item/events-list-item.component';
import {DashboardService} from './components/dashboard/dashboard.service';
import {DashboardDataService} from './components/dashboard/dashboard-data.service';
import {UserProfileComponent} from './components/dashboard/user-profile/user-profile.component';
import {PersonalInfoComponent} from './components/dashboard/user-profile/personal-info/personal-info.component';
import {SkillsExperiencesComponent} from './components/dashboard/user-profile/skills-experiences/skills-experiences.component';
import {AccountSettingsComponent} from './components/dashboard/user-profile/account-settings/account-settings.component';
import {EventComponent} from './components/dashboard/events/event/event.component';
import {UserProfileDataService} from './components/dashboard/user-profile/user-profile-data.service';
import {DateTimeFormatPipe} from './pipes/datetime.pipe';
import {ViewResultComponent} from './components/dashboard/events/training/view-result/view-result.component';
import {ConfirmDialogComponent} from './components/shared/confirm-dialog/confirm-dialog.component';
import {GlobalEventsListItemComponent} from './components/opportunities/global-events-list-item/global-events-list-item.component';
import {AccountService} from './components/account/account.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import {LoginDialogService} from './components/account/login/login.dialog.service';
import {TasksComponent} from './components/dashboard/events/tasks/tasks.component';
import {ViewTaskResultComponent} from './components/dashboard/events/tasks/view-task-result/view-task-result.component';
import {TaskKanbanComponent} from './components/dashboard/events/tasks/task-kanban/task-kanban.component';
import {ListComponent} from './components/dashboard/events/tasks/task-kanban/list/list.component';
import {NgxDnDModule} from '@swimlane/ngx-dnd';
import {ScrumboardBoardCardComponent} from './components/dashboard/events/tasks/task-kanban/card/card.component';
import {MyDashboardComponent} from './components/dashboard/my-dashboard/my-dashboard.component';
import {EventOpportunitiesDetailComponent} from './components/opportunities/event-opportunities-detail/event-opportunities-detail.component';
import {ToasterModule} from 'angular5-toaster/dist';
import {ErrorToasterComponent} from './components/shared/error-toaster/error-toaster.component';
import {BlockPasteDirective} from './directives/block-paste.directive';
import {EnforcedInputsDirective} from './directives/enforced-input.directive';
import {NgxYoutubePlayerModule} from 'ngx-youtube-player';
import { ImageCropperComponent } from './components/shared/image-cropper/image-cropper.component';
import {MvmsCroppieBtnComponent} from './components/shared/croppie/mvms-croppie-btn/mvms-croppie-btn.component';
import {CroppieComponent} from './components/shared/croppie/mvms-croppie-btn/croppie/croppie.component';
import {EnquiryComponent} from './components/dashboard/enquiry/enquiry.component';
import { CreateEnquiryComponent } from './components/dashboard/enquiry/create-enquiry/create-enquiry.component';
import {CKEditorModule} from 'ng2-ckeditor';
import { ListEnquiriesComponent } from './components/dashboard/enquiry/list-enquiries/list-enquiries.component';
import {EnquiriesDataService} from './components/dashboard/enquiry/enquiries-data.service';
import {CurrentUserService} from './services/current-user.service';
import {SafeHtmlPipe} from './pipes/safehtml.pipe';
import {EvaluationDataService} from './components/dashboard/events/evaluation/evaluation-data.service';
import {EvaluationComponent} from './components/dashboard/events/evaluation/evaluation.component';
import {EvaluationListComponent} from './components/dashboard/events/evaluation/evaluation-list/evaluation-list.component';
import {MvmsNgxCroppieComponent} from './components/shared/croppie/mvms-croppie-btn/mvms-ngx-croppie/mvms-ngx-croppie.component';
import {DateFormatPipe} from './pipes/date.pipe';
import {EvaluationViewEditComponent} from './components/dashboard/events/evaluation/evaluation-view-edit/evaluation-view-edit.component';
import {BarRatingModule} from 'ngx-bar-rating';
import { RewardsComponent } from './components/dashboard/rewards/rewards.component';
import { RewardsListComponent } from './components/dashboard/rewards/rewards-list/rewards-list.component';
import { RewardsCardComponent } from './components/dashboard/rewards/rewards-card/rewards-card.component';
import { GradientBorderComponent } from './components/shared/gradient-border/gradient-border.component';
import {RewardsDataService} from './components/dashboard/rewards/rewards-data.service';
import { ChangePasswordContainerComponent } from './components/account/change-password-container/change-password-container.component';
import { TrainingQuizComponent } from './components/dashboard/events/training/online-training/training-quiz/training-quiz.component';
import {OnlineTrainingComponent} from './components/dashboard/events/training/online-training/online-training.component';
import {DropdownSelectComponent} from './components/shared/dropdown-select/dropdown-select.component';
import {MzSidenavModule} from 'ngx-materialize';
import { RecaptchaDynamicLanguageLoaderService } from './services/recaptcha-dynamic-language-loader.service';
import {NgProgressModule} from 'ngx-progressbar';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { LanguageService } from './services/language.service';
import { AttendanceComponent } from './components/dashboard/attendance/attendance.component';
import { NoRecordsComponent } from './components/shared/no-records/no-records.component';
import {InputErrorsComponent} from './components/shared/input-errors/input-errors.component';
import {ListAttendanceComponent} from './components/dashboard/attendance/list-attendance/list-attendance.component';
import {AttendanceDataService} from './components/dashboard/attendance/attendance-data.service';
import { CodeOfConductComponent } from './components/code-of-conduct/code-of-conduct.component';
import { NoAccessComponent } from './components/shared/no-access/no-access.component';
import { OwlModule } from 'ngx-owl-carousel';
import { WithdrawReasonComponent } from './components/dashboard/events/events-list/widthdraw-reason/widthdraw-reason.component';
import { MyTasksComponent } from './components/dashboard/my-dashboard/my-tasks/my-tasks.component';
import { MyEventsComponent } from './components/dashboard/my-dashboard/my-events/my-events.component';
import { HeaderDataService } from './components/shared/header/header-data.service';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { MyUpcomingEventComponent } from './components/dashboard/my-dashboard/my-upcoming-event/my-upcoming-event.component';
import { BadgeItemComponent } from './components/dashboard/my-dashboard/my-badges/badge-item/badge-item.component';
import { BadgeListComponent } from './components/dashboard/my-dashboard/my-badges/badge-list/badge-list.component';
import { ScrollTopComponent } from './components/shared/scroll-top/scroll-top.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AutoLogoutService } from './auth/services/auto-logout.service';
import { AwardComponent } from './components/award/award.component';
import { CodeofconductDialogService } from './components/code-of-conduct/codeofconduct.dialog.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { RequestvolunteersComponent } from './components/requestvolunteers/requestvolunteers.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json?q=' + new Date().getTime());
}

@NgModule({
  declarations: [
    AppComponent,
    JoinFormsComponent,
    MvmsNgxCroppieComponent,
    HeaderComponent,
    EventComponent,
    MedicalComponent,
    TrainingComponent,
    EvaluationComponent,
    ScrollTopComponent,
    EvaluationViewEditComponent,
    EvaluationListComponent,
    UserProfileComponent,
    PersonalInfoComponent,
    SkillsExperiencesComponent,
    AccountSettingsComponent,
    EnumKeysPipe,
    AutocompleteServerSideComponent,
    AutocompleteComponent,
    DropdownSelectComponent,
    AboutUsComponent,
    TabNavigationComponent,
    UploadFileComponent,
    ImageComponent,
    RecoverPasswordComponent,
    ChangePasswordComponent,
    LoginComponent,
    ContentComponent,
    DashboardComponent,
    NewsComponent,
    OpportunitiesComponent,
    NavPanelComponent,
    EnquiryComponent,
    CreateEnquiryComponent,
    ListEnquiriesComponent,
    EventsComponent,
    EventStatusComponent,
    EventsListComponent,
    EventsListItemComponent,
    WithdrawReasonComponent,
    GlobalEventsListItemComponent,
    DateTimeFormatPipe,
    DateFormatPipe,
    TimeAgoPipe,
    ViewResultComponent,
    ConfirmDialogComponent,
    TasksComponent,
    ViewTaskResultComponent,
    TaskKanbanComponent,
    ListComponent,
    ScrumboardBoardCardComponent,
    MyDashboardComponent,
    EventOpportunitiesDetailComponent,
    ErrorToasterComponent,
    BlockPasteDirective,
    ImageCropperComponent,
    MvmsCroppieBtnComponent,
    CroppieComponent,
    SafeHtmlPipe,
    EnforcedInputsDirective,
    RewardsComponent,
    RewardsListComponent,
    RewardsCardComponent,
    GradientBorderComponent,
    ChangePasswordContainerComponent,
    OnlineTrainingComponent,
    TrainingQuizComponent,
    DebounceClickDirective,
    AttendanceComponent,
    NoRecordsComponent,
    NoAccessComponent,
    InputErrorsComponent,
    ListAttendanceComponent,
    CodeOfConductComponent,
    MyTasksComponent,
    MyEventsComponent,
    MyUpcomingEventComponent,
    BadgeItemComponent,
    BadgeListComponent,
    ScrollTopComponent,
    AwardComponent,
    RequestvolunteersComponent
  ],
  entryComponents: [
    LoginComponent,
    ChangePasswordComponent,
    ViewResultComponent,
    ConfirmDialogComponent,
    WithdrawReasonComponent,
    ViewTaskResultComponent,
    ErrorToasterComponent,
    ImageCropperComponent,
    CroppieComponent,
    CreateEnquiryComponent,
    EvaluationViewEditComponent,
    CodeOfConductComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OwlModule,
    MaterialComponentsModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    GoogleMapsModule.forRoot({url: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBKDEKoLG1fvkdL3IE-T3LcLwmfbzi10HQ'}),
    RecaptchaModule,
    RecaptchaFormsModule,
    MaterializeModule,
    NgMasonryGridModule,
    MatTableModule,
    MatSortModule,
    NgxDnDModule,
    MatMenuModule,
    ToasterModule,
    NgxYoutubePlayerModule,
    CKEditorModule,
    BarRatingModule,
    MzSidenavModule,
    NgProgressModule,
    MatGridListModule,
    NgxSliderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // takamul
    AuthModule.forRoot({
      loginEndPoint: `${BASE_URL}connect/token`,
       // guards: {loggedInGuard: {redirectUrl: '/'}, loggedOutGuard: {redirectUrl: '/'}}
    })
  ],
  exports: [MaterialComponentsModule],
  providers: [
    CreateUserService,
    AccountService,
    AutoLogoutService,
    TrainingService,
    UserProfileDataService,
    ContentService,
    PublicService,
    DashboardService,
    DashboardDataService,
    HeaderDataService,
    LoginDialogService,
    CodeofconductDialogService,
    EnquiriesDataService,
    EvaluationDataService,
    CurrentUserService,
    RewardsDataService,
    LanguageService,
    AttendanceDataService,
    {
      provide: RecaptchaLoaderService,
      useClass: RecaptchaDynamicLanguageLoaderService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
