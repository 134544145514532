import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'mvms-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit {
  constructor(private matDialog: MatDialog) { }

  ngOnInit() {
  }

  openCreateDialog() {
   //this.matDialog.open(CreateEnquiryComponent, {panelClass: 'create'});
  }

}
