import {IResponseSimpleEntity} from '../../../../../types';

export class EvaluationListModel {
  id: string;
  type: EvaluationRequestPartyType;
  evaluationStartDate: Date;
  evaluationEndDate: Date;
  hasVolunteerResult: boolean;
  hasEmployeeResult: boolean;
  userFullName: string;
  userAvatarId: string;
}


export class EvaluationModel {
  id: string;
  eventId: string;
  eventTitle: string;
  eventStartDateTime: Date;
  eventEndDateTime: Date;
  comment: string;
  employeeName: string;
  items: Criteria[];
}

export class Criteria {
  criteriaId: string;
  criteriaName: string;
  value: number;
}

export class EvaluateModel  {
  evaluationPartyId: string;
  comment: string;
  criteriaValues: CriteriaListItem[];
}

export class CriteriaListItem {
  criteriaId: string;
  value: number;
}

export class EvaluationCriteriaModel {
  id: string;
  name: string;
}
export enum EvaluationRequestPartyType {
  Unidirectional = 0,
  Bidirectional = 1
}
