<div class="event" *ngIf="model">
  <div class="card-info">
    <h2 class="header">
      {{model.title}}
    </h2>
    <div class="row">
      <div class="info-box col l6 m12">
        <div class="title">{{'DASHBOARD.event.startTime' | translate }}</div>
        <div class="value">{{model.startDateTime | dateTimeFormat}}</div>
      </div>
      <div class="info-box col l6 m12">
        <div class="title">{{'DASHBOARD.event.Status' | translate }}</div>
        <div class="value">{{ model.enrollmentStatusText }}</div>
      </div>
    </div>
    <div class="row">
      <div class="info-box col l6 m12">
        <div class="title">{{'DASHBOARD.event.EndTime' | translate }}</div>
        <div class="value">{{model.endDateTime | dateTimeFormat}}</div>
      </div>
      <div class="info-box col l6 m12">
        <div class="title">{{'DASHBOARD.event.Admin' | translate }}</div>
        <div class="value">{{model.admin}}</div>
      </div>
    </div>
    <div class="row">
      <div class="info-box col l6 m12">
        <div class="title">{{'DASHBOARD.event.Area' | translate }}</div>
        <div class="value">{{model.area}}</div>
      </div>
      <div class="info-box col l6 m12">
        <div class="title">{{'DASHBOARD.event.Description' | translate }}</div>
        <div class="value">{{model.description}}</div>
      </div>

    </div>
    <div class="hours-card-row">
      <div class="info-box">
        <div class="hours-card">
          <div class="info">
            <div class="hours-value">{{model.actualVolunteeringHrs}}</div>
            <p class="hours-title">{{'DASHBOARD.event.VolunteeringHours' | translate }}</p>
          </div>
          <mat-divider class="mat-divider" role="separator" aria-orientation="horizontal"></mat-divider>
        </div>
      </div>
      <div class="btn-group">
        <div class="btn-group-item">
          <button type="button" mat-raised-button color="accent" (click)="withdrawal(model.id)">
            {{'DASHBOARD.event.Withdrawal' | translate }}
          </button>
        </div>
        <div class="btn-group-item" *ngIf="model.status === ENROLLMENT_STATUSES.PendingPrerequisites && model.isMedicalRequirementOk && model.isPreviousEventRequirementOk && model.isTrainingRequirementOk">
          <button type="button" mat-raised-button color="accent" mvmsDebounceClick (debounceClick)="enrollToEvent(model.id)" [debounceTime]="1500">
            {{'DASHBOARD.event.enroll' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="event-status">
    <mvms-event-status [data]="model"></mvms-event-status>
  </div>
  <div class="row">
    <div class="necessary-action training col l4 m6 s12" *ngIf="!model.isTrainingRequirementOk">
      <div class="action-card">
        <div class="back">
          <div class="icon icon_training"></div>
        </div>
        <div class="info">
          <h3 class="title">{{'DASHBOARD.event.status.Training' | translate }}</h3>
          <p class="details truncate">{{ 'DASHBOARD.event.training.trainingDetails' | translate }}</p>
          <div class="btn-group">
            <button type="button" mat-raised-button color="accent" (click)="goTo('training', model.id)">{{ 'SHARED.Button.start' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="necessary-action medical col l4 m6 s12" *ngIf="!model.isMedicalRequirementOk">
      <div class="action-card">
        <div class="back">
          <div class="icon icon_medical"></div>
        </div>
        <div class="info">
          <h3 class="title">{{'DASHBOARD.event.status.Medical' | translate }}</h3>
          <p class="details">{{'DASHBOARD.event.medical.medicalDetails' | translate }}</p>
          <div class="btn-group">
            <button type="button" mat-raised-button color="accent" (click)="goTo('medical', model.id)">{{ 'SHARED.Button.start' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div #googlemap style='width: 100%; height: 500px;'></div>
</div>
