<div *ngIf="hasAccess; else noAccess">
  <div *ngIf="attendanceList && attendanceList.length; else noRecords" class="attendance z-depth-1">
    <div class="attendance-table">
      <div class="table-header">
        <div class="table-cell check-in">{{'DASHBOARD.Attendance.checkIn' | translate }} </div>
        <div class="table-cell check-out">{{'DASHBOARD.Attendance.checkOut' | translate }} </div>
        <div class="table-cell hours">{{'DASHBOARD.Attendance.time' | translate }} </div>
        <div class="table-cell hours">{{'DASHBOARD.Attendance.area' | translate }} </div>
      </div>
      <div class="table-row" *ngFor="let item of attendanceList">
        <div class="table-cell check-in">{{item.checkIn | dateTimeFormat}}</div>
        <div class="table-cell check-out">
          <div class="not-checkout" *ngIf="!item.checkOut">
            ----
          </div>
          <div *ngIf="item.checkOut">
            {{item.checkOut | dateTimeFormat}}
          </div>
        </div>
        <div class="table-cell hours">
          {{item.hours ? item.hours : "0"}}:{{item.minutes ? (item.minutes < 10 ? '0'+ item.minutes : item.minutes) : "00"}}
        </div>
        <div class="table-cell area">{{item.area}}</div>
      </div>
    </div>
  </div>
</div>

<ng-template #noAccess>
  <mvms-no-access></mvms-no-access>
</ng-template>

<ng-template #noRecords>
  <mvms-no-records *ngIf="!isLoading"></mvms-no-records>
</ng-template>