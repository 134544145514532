import {Component, Input, OnInit} from '@angular/core';
import {BASE_URL, transparentSource} from '../../../constants/constant';


@Component({
  selector: 'mvms-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit  {

  @Input() public imageId: string;
  @Input() public isAvatar = true;

  public baseUrl = BASE_URL;
  public backURL:any;
  transparentSource = transparentSource;
  constructor() {
  }

  ngOnInit() {
  this.backURL = `url(${this.baseUrl}api/File/${this.imageId}/thumbnail)`;
  }
}
