import {Component, Input, OnInit} from '@angular/core';
import {EventModel} from '../../events.model';

@Component({
  selector: 'mvms-event-status',
  templateUrl: './event-status.component.html',
  styleUrls: ['./event-status.component.scss']
})
export class EventStatusComponent implements OnInit {
@Input()data: EventModel;
  constructor() { }

  ngOnInit() {
  }

}
