import { Injectable } from '@angular/core';
import {Router, CanActivate, CanActivateChild, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Route} from '@angular/router';
import {TokenService} from '../services/token.service';
import {AuthConfig} from '../auth.config';
import {AuthenticationService} from '../services/authentication.service';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild , CanLoad {

constructor (private config: AuthConfig,
             private tokenService: TokenService,
             private authService: AuthenticationService,
             private router: Router) {
}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return !this.checkUser(state.url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;
    return this.checkUser(url);
  }


  private checkUser(url: string): boolean {
    const token = this.tokenService.getToken();
    if (token && token.token) {
        return true;
    }

    // Navigate to the login page with extras
    // const redirectUrl = this.config.guards.loggedInGuard.redirectUrl;
    // this.router.navigate([redirectUrl], { queryParams: { returnUrl: url }});
    return false;
  }
}
