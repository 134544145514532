import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { BASE_URL } from '../constants/constant';
import { IResponseModel } from '../../types';
import { Toast, ToasterMessagesTypes } from '../decorators/toast.decorator';
import { EventListModel } from '../components/dashboard/events/events.model';
import { ProgressBar } from '../decorators/progress-bar.decorator';
import { HomepageSectionForPortal, CodeofconductForPortal } from './models/homepage-section-for-portal.model';
import { DepartmentResources } from './models/department-resources.model';
import { BehaviorSubject, Subject } from '../../../node_modules/rxjs';
import { CodeOfConductComponent } from '../components/code-of-conduct/code-of-conduct.component';

@Injectable()
export class ContentService {
  private url = `${BASE_URL}api/public/content`;

  private urlEvent = `${BASE_URL}api/public/Event`;

  private urlSystem = `${BASE_URL}api/system`;

  currentVersion$: BehaviorSubject<string> = new BehaviorSubject(null);

  departmentResources$: BehaviorSubject<DepartmentResources> = new BehaviorSubject(null);

  GlobalEventList$: BehaviorSubject<EventListModel[]> = new BehaviorSubject(null);

  constructor(private http: HttpClient, ) {
    this.getDepartmentResources()
      .subscribe(resources =>
        this.departmentResources$.next(resources));

    this.getVersion()
      .subscribe(version => {
        this.currentVersion$.next(version);
      });
  }

  getSlideshowItemList() {
    return this.http.get(`${this.url}/slideshowItemList`);
  }

  getHomepageSection(block): Observable<HomepageSectionForPortal> {
    return this.http.get<HomepageSectionForPortal>(`${this.url}/homePageSection/${block}`);
  }

  getMedicalConsent() {
    return this.http.get(`${this.url}/medicalConsent`);
  }

  getBlogList(model) {
    return this.http.post(`${this.url}/blogList`, model);
  }

  getGalleryAlbumList(model) {
    return this.http.post(`${this.url}/galleryAlbumList`, model);
  }

  getGalleryPhotoList(model) {
    return this.http.post(`${this.url}/galleryPhotosList`, model);
  }

  getGlobalEventList() {
    return this.http.get(`${this.url}/globalEventList`);
  }

  getCodeofconduct(): Observable<CodeofconductForPortal>{
    return this.http.get<CodeofconductForPortal>(`${this.url}/codeofconduct`)
  }

  @ProgressBar()
  getGlobalEventListExclMine(filterParams): Observable<IResponseModel> {
    return this.http.post<IResponseModel>(`${this.url}/departmentEventList/exclMine`, filterParams);
  }

  @ProgressBar()
  getGlobalEventDetail(id): Observable<any> {
    return this.http.get(`${this.url}/departmentEvent/${id}`);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyJoined)
  joinEventl(eventId): Observable<any> {
    return this.http.post<any>(`${this.urlEvent}/join`, eventId);
  }

  public getDepartmentResources(): Observable<DepartmentResources> {
    return this.http.get<DepartmentResources>(`${this.url}/departmentResources`);
  }

  private getVersion(): Observable<string> {
    return this.http.get(`${this.urlSystem}/version`, { responseType: 'text' });
  }
}
