import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import {DATE_TIME_FMT} from '../constants/constant';

@Pipe({
  name: 'dateTimeFormat'
})

export class DateTimeFormatPipe  extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const dateValue = new Date(value);

    const dateWithNoTimezone = new Date(
      dateValue.getFullYear(),
      dateValue.getMonth(),
      dateValue.getDate(),
      dateValue.getHours(),
      dateValue.getMinutes(),
      dateValue.getSeconds()
    );
    return super.transform(dateWithNoTimezone, DATE_TIME_FMT);
  }
}
