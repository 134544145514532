import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {ContentService} from '../../services/content.service';
import {BASE_URL} from '../../constants/constant';

declare var $: any;

@Component({
  selector: 'mvms-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})

export class NewsComponent implements OnInit, AfterViewChecked {
  BASE_URL = BASE_URL;
  public blogItems: any[];
  public masonryItems: any[];
  public processedItems = 0;
  public initializedItems = 0;

  constructor(private contentService: ContentService) {
  }
 
  ngOnInit(): void {
    this.contentService.getBlogList({pageNo: 1, pageSize: 50, searchText: ''}).subscribe((data: any) => {
      this.blogItems = data.items;
      this.masonryItems = this.blogItems.slice(0, 4);
      this.blogItems.forEach(item => item.videoId = item.videoLink ? item.videoLink.split('=')[1] : null);
    });
    // $(window).load(function() {
    //   $('.post-module').hover(function() {
    //     $(this).find('.description').stop().animate({
    //       height: 'toggle',
    //       opacity: 'toggle'
    //     }, 300);
    //   });
    // });
  }

  ngAfterViewChecked(): void {
    const newItems = $('#blog .gallery-expand').slice(this.initializedItems);
    if (newItems.length > 0) {
      newItems.galleryExpand({
        inDuration: 200,
      });

      if (this.initializedItems === 0) {
        $('#blog .gallery').masonry({
          itemSelector: '.gallery-item',
          columnWidth: '.gallery-item',
          transitionDuration: 0
        });
        // layoutMasonry after each image loads
        $('#blog .gallery').imagesLoaded(function () {
          $('#blog .gallery').masonry('layout');
        });
      } else {
        $('#blog .gallery').imagesLoaded(function () {
          $('#blog .gallery').masonry('layout');
        });
        $('#blog .gallery').masonry('appended', newItems);
      }
      this.initializedItems = $('#blog .gallery-expand').length;
    }
  }

  showMore(): void {
    const adding = this.blogItems.slice(this.masonryItems.length, this.masonryItems.length + 2);
    this.masonryItems.push.apply(this.masonryItems, adding);
  }

  layoutComplete() {
    const selector = $('#blog .gallery-expand').slice(this.processedItems);
    selector.galleryExpand({
      inDuration: 200,
    });
    this.processedItems = this.processedItems + selector.length;
  }
}
