<div class="view-result" *ngIf="model" [class.rtl]="isCurrentLanguageArabic"
     [attr.dir]="isCurrentLanguageArabic  ? 'rtl' : ''">
  <div class="form-container">
    <form class='form' [formGroup]="taskForm" (ngSubmit)="update()">
      <div class="form-section">
        <div class="header-panel">
          <h2 class="header">{{'DASHBOARD.event.ViewTaskResult.header' | translate }}</h2>
          <div class="btn-close">
            <button type="button" mat-icon-button (click)="closeDialog()" tabindex="-1">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <div class="main">
          <div class="section-header">
            <h3 class="title">
              {{'DASHBOARD.event.ViewTaskResult.subHeaderEvent' | translate }}
            </h3>
            <div class="divider"></div>
          </div>
          <div class="row">
            <div class="title col l6 m6 s6">{{'DASHBOARD.event.ViewTaskResult.EventDate' | translate }}</div>
            <div class="value col l6 m6 s6">
              <div class="data">{{model.eventDate | dateTimeFormat}}</div>
              <div class="divider"></div>
            </div>
          </div>
          <div class="row">
            <div class="title col l6 m6 s6">{{'DASHBOARD.event.ViewTaskResult.EventTitle' | translate }}</div>
            <div class="value col l6 m6 s6">
              <div class="data">{{model.eventTitle}}</div>
              <div class="divider"></div>
            </div>
          </div>
          <div class="row">
            <div class="title col l6 m6 s6">{{'DASHBOARD.event.ViewTaskResult.EventArea' | translate }}</div>
            <div class="value col l6 m6 s6">
              <div class="data">{{model.eventArea}}</div>
              <div class="divider"></div>
            </div>
          </div>
          <div class="section-header">
            <h3 class="title">
              {{'DASHBOARD.event.ViewTaskResult.subHeaderTask' | translate }}
            </h3>
            <div class="divider"></div>
          </div>
          <div class="row">
            <div class="title col l6 m6 s6">{{'DASHBOARD.event.ViewTaskResult.TaskTitle' | translate }}</div>
            <div class="value col l6 m6 s6">
              <div class="data">{{model.title}}</div>
              <div class="divider"></div>
            </div>
          </div>
          <div class="row">
            <div class="title col l6 m6 s6">{{'DASHBOARD.event.ViewTaskResult.TaskDescription' | translate }}</div>
            <div class="value col l6 m6 s6">
              <div class="data">{{model.description}}</div>
              <div class="divider"></div>
            </div>
          </div>
          <div class="row">
            <div class="title col l6 m6 s6">{{'DASHBOARD.event.ViewTaskResult.Estimated' | translate }}</div>
            <div class="value col l6 m6 s6">
              <div class="data">{{model.estimateHrs}}</div>
              <div class="divider"></div>
            </div>
          </div>
          <div class="row">
            <div class="title col l6 m6 s6">{{'DASHBOARD.event.ViewTaskResult.Priority' | translate }}</div>
            <div class="value col l6 m6 s6">
              <div class="data">{{TaskPriority[model.priority]}}</div>
              <div class="divider"></div>
            </div>
          </div>
          <div class="row">
            <p class="title col l6 m6 s6">{{'DASHBOARD.event.ViewTaskResult.TaskStatus' | translate }}</p>
            <div class=" col l6 m6 s6">
              <mat-form-field class="z-depth-1 full-width status-select" floatLabel="never">
                <mat-select placeholder="{{'DASHBOARD.event.ViewTaskResult.TaskStatus' | translate }}"
                            [(value)]="taskStatus">
                  <mat-option *ngFor="let task of TaskStatus | enumkeys" [value]="task.key">
                    {{task.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="title col l6 m6 s6">{{'DASHBOARD.event.ViewTaskResult.DueDate' | translate }}</div>
            <div class="value col l6 m6 s6">
              <div class="data">{{model.dueDate | dateTimeFormat}}</div>
              <div class="divider"></div>
            </div>
          </div>
          <div class="row">
            <div class="value col l12 m12 s12">
              <mat-form-field class="full-width">
                                <textarea matInput [(ngModel)]="taskComments" type='text' formControlName="comment"
                                          matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="5"
                                          [placeholder]="'DASHBOARD.event.ViewTaskResult.AddComment' | translate"
                                          title="">
                                </textarea>
                <mat-hint align="end">{{taskForm.controls.comment.value ? taskForm.controls.comment.value.length : '0'}} / 1000</mat-hint>
              </mat-form-field>
              <div class="col m10 custom-input-error">
                <mat-error *ngIf="taskForm.controls.comment.hasError('maxlength')">
                  {{'SHARED.Errors.maxLength' | translate }}
                </mat-error>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="btn-group col l12 m12 s12">
            <button type="submit" mat-raised-button color="accent" [disabled]="!taskForm.valid">
              {{'SHARED.Button.Update' | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
