import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'mvms-gradient-border',
  templateUrl: './gradient-border.component.html',
  styleUrls: ['./gradient-border.component.scss']
})
export class GradientBorderComponent implements OnInit {
  @Input() disabled = false;
  constructor() { }

  ngOnInit() {
  }

}
