import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

import { DashboardDataService } from '../../dashboard-data.service';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../dashboard.service';
import { MedicalModel } from './medical.model';
import { ViewResultComponent } from '../training/view-result/view-result.component';
import { EventModel } from '../events.model';
import { ContentService } from '../../../../services/content.service';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { MvmsAnimations } from '../../../../animations/animations';
import { GoogleMapsService } from 'google-maps-angular2';
import { ViewResultTypes } from '../../../../constants/constant';

@Component({
  selector: 'mvms-medical',
  templateUrl: './medical.component.html',
  styleUrls: ['./medical.component.scss'],
  animations: MvmsAnimations
})
export class MedicalComponent implements OnInit, OnDestroy {
  @ViewChild('googlemap') mapElement: ElementRef;

  agreeTerms: boolean;
  medicals : any;
  fullName: FormControl = new FormControl('', [Validators.required, Validators.maxLength(50)]);
  currentEvent: EventModel;
  termMeassage: Observable<Object>;
  user:any;
  medicalList: MedicalModel[];
  isApproved = false;

  private componentDestroyed: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dashboardDataService: DashboardDataService,
    private dashboardService: DashboardService,
    public contentService: ContentService,
    private gapi: GoogleMapsService,
    private matDialog: MatDialog, ) {
  }

  ngOnInit() {
    this.dashboardService.currentEvent.takeUntil(this.componentDestroyed).subscribe(event => {
      this.currentEvent = event;
      if (this.currentEvent) {
        if (this.currentEvent.requireMedicalConsent) {
          this.termMeassage = this.contentService.getMedicalConsent().map((data: any) => {
            this.isApproved = !!data.medicalConsentName;
            if (this.isApproved) {
              this.agreeTerms = true;
              this.fullName.setValue(data.medicalConsentName);
              this.fullName.disable();
            }
            return data.content;
          });
        } else {
          this.dashboardDataService.getMedicalList(this.dashboardService.routeParams.id, 'Desc').takeUntil(this.componentDestroyed).subscribe(list => {
            this.medicalList = list;
            setTimeout(() => {
              this.loadMap();
            }, 600);
          });
        }
      }
    });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  loadMap(): void {
    if (this.medicalList && this.medicalList.length === 0) {
      return;
    }

    this.gapi.init.then((maps: any) => {
      const mapElement = this.mapElement;
      const map = new maps.Map(mapElement.nativeElement, {
        zoom: 5,
        center: new maps.LatLng(this.medicalList[0].latitude, this.medicalList[0].longitude),
        mapTypeId: maps.MapTypeId.ROADMAP
      });

      for (let i = 0; i < this.medicalList.length; i++) {
        const marker = new maps.Marker({
          position: new maps.LatLng(this.medicalList[i].latitude, this.medicalList[i].longitude),
          map: map
        });
      }
    });
  }

  attendToMedical(medicalId) {
    this.dashboardDataService.attendToMedical({ medicalScheduleId: medicalId }).subscribe(() => {
      this.medicalList.find((item: MedicalModel) => item.canEnroll = false);
    });
  }

  submitAnswer(value) {
    if (this.fullName.valid && this.fullName.dirty) {
      this.dashboardDataService.submitMedicalConsent({
        name: this.fullName.value,
        isAccepted: value
      }).subscribe(() => {
        this.dashboardService.getEvent(this.currentEvent.id);
        this.router.navigate(['../'], { relativeTo: this.route });
      });
    }
  }

  return() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  showViewresultDialog(medical) {
    const dialogRef = this.matDialog.open(ViewResultComponent, { panelClass: 'view-result' });
    dialogRef.componentInstance.data = medical;
    dialogRef.componentInstance.type = ViewResultTypes.medical;
  }

}
