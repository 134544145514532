<div class="mat-tab-nav-bar-pagination">
  <div  (click)="left()" aria-hidden="true"
       class="mat-tab-header-pagination mat-tab-header-pagination-before mat-elevation-z4 mat-ripple left" mat-ripple="">
    <div class="mat-tab-header-pagination-chevron"></div>
  </div>
  <div class="mat-tab-label-container">
    <div mat-tab-nav-bar>
      <a mat-tab-link
         *ngFor="let link of navLinks"
         [routerLink]="link.path"
         routerLinkActive #rla="routerLinkActive"
         [active]="rla.isActive">
        {{link.title | translate}}
      </a>
    </div>
  </div>
  <div (click)="right()" aria-hidden="true"
       class="mat-tab-header-pagination mat-tab-header-pagination-after mat-elevation-z4 mat-ripple right" mat-ripple="">
    <div class="mat-tab-header-pagination-chevron"></div>
  </div>
</div>
