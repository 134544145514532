import {ToasterService} from 'angular5-toaster/dist';
import {Observable} from 'rxjs/Observable';
import {appInjector} from '../app-injector';
import 'rxjs/add/operator/do';
import {LanguageService} from '../services/language.service';


export enum ToasterMessagesTypes {
  SuccessfullySent,
  SuccessfullyRegistered,
  SuccessfullyUpdated,
  SuccessfullyCreated,
  SuccessfullyJoined,
  SuccessfullyEnrolled,
  SuccessfullyApplied,
  SuccessfullyEvaluationSent,
  SuccessfullyPasswordChanged,
  SuccessfullyWithdrawn,
  InsufficientAccess,
  NoAttendance
}

export const ToastMessages = {
  SuccessfullySent: {
    en: 'Your message has been sent successfully',
    ar: 'تم الإرسال بنجاح'
  },
  NoAttendance: {
    en: 'No Attendance found for the selected period',
    ar: 'لم يتم العثور على حضور'
  },
  SuccessfullyRegistered: {
    en: 'You are successfully registered',
    ar: 'تم التسجيل بنجاح'
  },
  SuccessfullyUpdated: {
    en: 'Successfully Updated',
    ar: 'تم التحديث بنجاح'
  },
  SuccessfullyCreated: {
    en: 'Successfully Created',
    ar: 'تم التحديث بنجاح'
  },
  SuccessfullyJoined: {
    en: 'Successfully joined',
    ar: 'تم الانضمام بنجاح'
  },
  SuccessfullyEnrolled: {
    en: 'You have been enrolled successfully to the event',
    ar: 'تم الانضمام بنجاح'
  },
  SuccessfullyApplied: {
    en: 'Successfully applied',
    ar: 'تم التقديم بنجاح'
  },
  SuccessfullyPasswordChanged: {
    en: 'You have successfully changed your password.',
    ar: 'تم تغيير كلمة المرور بنجاح'
  },
  SuccessfullyEvaluationSent : {
    en: 'Your evaluation was successfully sent',
    ar: 'تم إرسال التقييم بنجاح'
  },
  SuccessfullyWithdrawn : {
    en: 'Successfully withdrawn',
    ar: 'تم الانسحاب بنجاح'
  },
  InsufficientAccess:{
    en:'Event cannot be joined until your registration is approved',
    ar:'لا يمكنك الإنضمام للفعالية حتى يتم قبول تسجيلك'
  }
};

class AllowedToasterMessagesTypes {
  private readonly _isCurrentLanguageArabic: boolean;

  constructor () {
    const languageService = appInjector().get(LanguageService);
    this._isCurrentLanguageArabic = languageService.isCurrentLanguageArabic;
  }

  getMessage(message: ToasterMessagesTypes) {
    const m = ToastMessages[ToasterMessagesTypes[message]];
    if (m) {
      return this._isCurrentLanguageArabic ? m.ar : m.en;
    }

    throw new Error('Message is not exist');
  }
}

export function Toast(message: ToasterMessagesTypes) {
  return function (target: any, key: string, descriptor: any) {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: any[]) {
      const result = originalMethod.apply(this, args);
      return (result as Observable<any>).do(() => {
        const toasterService = appInjector().get(ToasterService);
        const value = new AllowedToasterMessagesTypes().getMessage(message);
        toasterService.pop('success', null, value);
      });
    };

    return descriptor;
  };
}
