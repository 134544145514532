<div class="event-card">
  <div class="featured-image" (click)="goToEvent(event.id)">
    <mvms-image [imageId]="event.featuredImage" [isAvatar]="false"></mvms-image>
  </div>
  <div class="info">
    <h3 class="title IE-padding-all" [title]="event.title">{{event.title}}</h3>
    <p class="details">{{'DASHBOARD.event.startTime' | translate }} : {{event.startDateTime | dateTimeFormat}}</p>
    <p class="details">{{'DASHBOARD.event.EndTime' | translate }} : {{event.endDateTime | dateTimeFormat}}</p>
    <p class="details" *ngIf="event.area">{{'DASHBOARD.event.Area' | translate }} : {{event.area}}</p>
    <p class="details">{{'DASHBOARD.event.Admin' | translate }} : {{event.admin}}</p>
    <p class="details">{{'DASHBOARD.event.statusTitle' | translate }} : {{ event.enrollmentStatusText }}<span
        *ngIf="!event.enrollmentStatusText">Pending</span></p>
    <p class="details">
      <mat-icon color="">location_on</mat-icon> {{event.location }}
    </p>
    <div class="btn-group">
      <button type="button" mat-raised-button color="accent" *ngIf="isCurrentEvent(event.endDateTime)" mvmsDebounceClick
        (debounceClick)="withdrawal($event, event)"
        [debounceTime]="1500">{{'DASHBOARD.event.Withdrawal' | translate }}</button>

      <button type="button" mat-raised-button color="accent"
        *ngIf="!isCurrentEvent(event.endDateTime) && event.certificateEnabled"
        (click)="printCertificate(event.id)">{{'DASHBOARD.event.printCertificate' | translate }}</button>
    </div>
  </div>
</div>