import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { EventModelExtended, EventModelConverter, EnrollmentStatus } from '../events.model';
import { DashboardDataService } from '../../dashboard-data.service';
import { DashboardService } from '../../dashboard.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs/Subject';
import { GoogleMapsService } from 'google-maps-angular2';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { LanguageService } from '../../../../services/language.service';
import { filter } from 'rxjs/operators/filter';
import { WithdrawReasonComponent } from '../events-list/widthdraw-reason/widthdraw-reason.component';
import { ToasterService } from 'angular5-toaster/dist/angular5-toaster';
import { appInjector } from '../../../../app-injector';

@Component({
  selector: 'mvms-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit, OnDestroy {
  @ViewChild('googlemap') mapElement: ElementRef;

  model: EventModelExtended;

  ENROLLMENT_STATUSES = EnrollmentStatus;

  protected componentDestroyed: Subject<any> = new Subject();
  private disjoinMessage:any;

  constructor(private dashboardDataService: DashboardDataService,
    protected dashboardService: DashboardService,
    public dialog: MatDialog,
    private gapi: GoogleMapsService,
    private languageService: LanguageService,
    private router: Router) {
  }

  ngOnInit() {
    this.disjoinMessage = this.languageService.isCurrentLanguageArabic ?
      'هل ترغب في الانسحاب من هذه الفعالية' :
      'Are you sure you want to withdraw this event?';

    this.dashboardService.currentEvent.pipe(filter(e => !!e)).takeUntil(this.componentDestroyed)
      .subscribe(event => {
        this.model = EventModelConverter.toEventModelExtended(event);

        this.loadMap();

        setTimeout(() => {
          const container = document.getElementById('dashboardmain');
          if (container) {
            container.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 50);
      });
  }

  loadMap(): void {
    const mapElement = this.mapElement;
    this.gapi.init.then((maps: any) => {
      const pos = {
        lng: this.model.longitude,
        lat: this.model.latitude
      };
      const myOptions = {
        zoom: 8,
        center: pos
      };
      const map = new maps.Map(mapElement.nativeElement, myOptions);

      const marker = new maps.Marker({
        map: map,
        position: pos
      });
    });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.dashboardService.dispose();
  }

  withdrawal() {
    // Issue fixed: https://takamul.atlassian.net/browse/MVMS-1169 by Saif ur Rehman, Dated: Jun 02, 2019.
    // var FORTY_EIGHT_HOURS = 48 * 60 * 60 * 1000; /* ms */
    // var currentData = new Date().getTime();
    // if ((new Date(this.dashboardService.currentEvent.value.startDateTime).getMilliseconds() - currentData) < FORTY_EIGHT_HOURS) {
    //   const toasterService = appInjector().get(ToasterService);
    //   if (this.languageService.isCurrentLanguageArabic)
    //     toasterService.pop('error', 'خطأ', 'لا يمكنك الإنسحاب من هذه الفعالية الآن. الرجاء إرسال استفسار لطلب الإنسحاب');
    //   else
    //     toasterService.pop('error', 'Error', 'You can no longer withdraw yourself from this event. Please send an enquiry to withdraw.');
    //   return;
    // }

    let withdrawalDialogRef: MatDialogRef<WithdrawReasonComponent> =
      this.dialog.open(WithdrawReasonComponent, { disableClose: false });

    withdrawalDialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        event.stopPropagation();
        this.dashboardDataService.withdrawEvent({
          eventId: this.dashboardService.currentEventId.value,
          reason: result.reason
        }).subscribe(() => {
          this.dashboardService.refreshEventList();
        });
      }
      withdrawalDialogRef = null;
    });
  }

  goTo(location:any, eventId:any) {
    this.router.navigate([`/dashboard/myEvents/${eventId}/${location}`]);
  }

  enrollToEvent(eventId:any) {
    this.dashboardDataService.enrollToEvent({ eventId: eventId }).subscribe(() => {
      this.model.status = 0;
    });
  }
}
