import {Component, OnInit,AfterViewInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import Croppie from 'croppie/croppie';
import { CroppieOptions, ResultOptions} from 'croppie';


export type Type = 'canvas' | 'base64' | 'html' | 'blob' | 'rawcanvas';
declare var $: any;

export interface TempResultOptions extends ResultOptions {
  type?: Type;
}

@Component({
  selector: 'mvms-ngx-croppie',
  template: `<div #imageEdit (update)="newResult()"></div>`
})
export class MvmsNgxCroppieComponent implements AfterViewInit {
  @ViewChild('imageEdit',{static: false}) imageEdit: ElementRef;
  @Input() croppieOptions: CroppieOptions;
  @Input() imageUrl: string;
  @Input() bind: (img: string) => void;
  @Input() outputFormatOptions: TempResultOptions;
  @Output() result: EventEmitter<string | HTMLElement | Blob | HTMLCanvasElement>
    = new EventEmitter<string | HTMLElement | Blob | HTMLCanvasElement>();

  private _croppie : Croppie;

  ngAfterViewInit(): void {
     this._croppie = new Croppie(this.imageEdit.nativeElement, this.croppieOptions);
     this._croppie.bind({
      url: this.imageUrl
    });

    this.bind = (img: string) => {
      this._croppie.bind({ url: this.imageUrl });
    };

  }

  public updateImg(imageUrl: any) {
    this._croppie.bind({
      url: imageUrl
    });

    this.bind = (img: string) => {
      this._croppie.bind({ url: imageUrl });
    };
  }

  newResult() {
    this._croppie.result(this.outputFormatOptions).then((res: any) => {
      this.result.emit(res);
    });
  }

  rotate(degrees: 90 | 180 | 270 | -90 | -180 | -270) {
    this._croppie.rotate(degrees);
  }
}
