import { AfterContentInit, Input, Host, Self, Directive } from '@angular/core';
import { MatAutocomplete, MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NgControl, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

@Directive({
    selector: '[mvmsEnforcedInput]'
  })
export class EnforcedInputsDirective implements AfterContentInit {

  @Input() formControl: FormControl;
  @Input() matAutocomplete: MatAutocomplete;
  @Input() allowCustomValues: boolean;

  subscription: Subscription;

  constructor(@Host() @Self() private readonly autoCompleteTrigger: MatAutocompleteTrigger, private control: NgControl) {
  }

  ngAfterContentInit() {
    if (this.allowCustomValues) {
      return;
    }

    if (this.formControl === undefined) {
      throw Error('inputCtrl @Input should be provided');
    }

    if (this.matAutocomplete === undefined) {
      throw Error('valueCtrl @Input should be provided');
    }

    setTimeout(() => {
      this.subscribeToClosingActions();
      this.handleSelection();
    });
  }

  private subscribeToClosingActions(): void {
    if (this.subscription && !this.subscription.closed) {
        this.subscription.unsubscribe();
    }

    this.subscription = this.autoCompleteTrigger.panelClosingActions
        .subscribe((e) => {
           if (!e || !e.source) {
                const selected = this.matAutocomplete.options
                    .map(option => option.value)
                    .find(option => option === this.formControl.value);

                if (selected == null) {
                    this.formControl.setValue('');
                }
            }
        },
        err => this.subscribeToClosingActions(),
        () => this.subscribeToClosingActions());
}

  private handleSelection() {
    this.matAutocomplete.optionSelected.subscribe((e: MatAutocompleteSelectedEvent) => {
      this.formControl.setValue(e.option.value);
    });
  }
}
