import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {BASE_URL} from '../constants/constant';
import {Subject} from 'rxjs/Subject';
import {ProgressBar} from '../decorators/progress-bar.decorator';

@Injectable()
export class PublicService {

  private observable: Observable<any>;
  private allEnums: object;
  public subject: Subject<any> = new Subject<any>();

  private url = `${BASE_URL}api/public`;
  private autocompleteUrl = `${BASE_URL}api/Autocomplete`;

  constructor(private http: HttpClient) {
  }

  public getEnums(): Observable<any> {
    if (this.allEnums != null) {
      this.subject.next(this.allEnums);
      return Observable.of(this.allEnums);
    } else if (this.observable) {
      return this.observable;
    } else {
      this.observable = this.http.get(`${this.autocompleteUrl}/allEnums`)
        .do((allEnums: object) => {
          if (allEnums) {
            this.allEnums = allEnums;
            this.observable = null;
            this.subject.next(this.allEnums);
            return Observable.of(this.allEnums);
          }
        }).share();
      return this.observable;
    }
  }

  @ProgressBar()
  public contactUs(model) {
    return this.http.post(`${this.url}/contactUs`, model);
  }
}
