import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {LanguageService} from '../../../services/language.service';

@Component({
  selector: 'mvms-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  public confirmMessage: string;
  isCurrentLanguageArabic: boolean;

  title: string | null;

  constructor(private languageService: LanguageService, public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
  }
  ngOnInit() {
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
  }

}
