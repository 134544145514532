import {Component, OnInit} from '@angular/core';
import {Section} from '../../../constants/constant';


@Component({
  selector: 'mvms-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  public navLinks  = [
    { title: 'DASHBOARD.event.profile.Personal',
      icon: '',
      path: `/dashboard/${Section.ProfilePersonal}`,
      isVisible: true,
    },
    { title: 'DASHBOARD.event.profile.SkillsTabTitle',
      icon: '',
      path: `/dashboard/${Section.ProfileSkills}`,
      isVisible: true,
    },
    { title: 'DASHBOARD.event.profile.Account',
      icon: '',
      path: `/dashboard/${Section.ProfileAccount}`,
      isVisible: true,
    }
  ];

  constructor() { }
  ngOnInit() {
  }
}
