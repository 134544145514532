import { appInjector } from '../../../app-injector';
import { LanguageService } from '../../../services/language.service';

export class EventListModel {
  id: string;
  title: string;
  startDateTime: Date;
  endDateTime: Date;
  description: string;
  location: string;
  area: string;
  longitude?: number;
  latitude?: number;
  featuredImageId?: string;
  featuredImage?: string;
  status?: EnrollmentStatus;
  admin: string;
  certificateEnabled: boolean;
}



export class CertificateRange{
  public VolunteerId: string;
  public Lang: string;
  public CheckInYear: number;
  public CheckOutYear: number;
  constructor(v: string, l: string, ci: number, co: number )
  {
    this.VolunteerId = v;
    this.Lang = l;
    this.CheckInYear =ci;
    this.CheckOutYear =co;
  }
}

export class EventListModelExtended extends EventListModel {
  enrollmentStatusText: string;
}

export const EventListModelConverter = {
  toEventListModelExtended(eventModel: EventListModel) {
    const languageService = appInjector().get(LanguageService);

    const model = { ...eventModel } as EventListModelExtended;

    if (eventModel.status) {
      model.enrollmentStatusText =
        languageService.isCurrentLanguageArabic ?
          EnrollmentStatusText[eventModel.status].ar :
          EnrollmentStatusText[eventModel.status].en;
    }


    return model;
  }
};

export class EventModel {
  id: string;
  title: string;
  startDateTime: Date;
  endDateTime: Date;
  description: string;
  departmentName?: string;
  location?: string;
  featuredImageId: string;
  status: EnrollmentStatus;
  admin: string;
  area: string;
  totalVolunteeringHrs: number;
  actualVolunteeringHrs: number;
  requireMedicalCheckup: boolean;
  requireMedicalConsent: boolean;
  requireOnlineTrainings: boolean;
  requireOfflineTrainings: boolean;
  requirePreviousEvents: boolean;
  isPreviousEventRequirementOk: boolean;
  isTrainingRequirementOk: boolean;
  isMedicalRequirementOk: boolean;
  longitude?: number;
  latitude?: number;
}

export class EventModelExtended extends EventModel {
  enrollmentStatusText: string;
}

export enum EnrollmentStatus {
  Pending = 0,
  Invited = 1,
  Accepted = 2,
  Rejected = 3,
  Withdrawn = 4,
  PendingPrerequisites = 5
}

export const EnrollmentStatusText = {
  [EnrollmentStatus.Pending]: {
    en: 'Pending',
    ar: 'قيد الانتظار'
  },
  [EnrollmentStatus.Invited]:
  {
    en: 'Invited',
    ar: 'مدعو'
  },
  [EnrollmentStatus.Accepted]:
  {
    en: 'Accepted',
    ar: 'قبلت'
  },
  [EnrollmentStatus.Rejected]:
  {
    en: 'Rejected',
    ar: 'مرفوض'
  },
  [EnrollmentStatus.Withdrawn]:
  {
    en: 'Withdrawn',
    ar: 'منسحب'
  },
  [EnrollmentStatus.PendingPrerequisites]:
  {
    en: 'Pending Prerequisites',
    ar: 'متطلب سابق غير مكتمل'
  }
};

export const EventModelConverter = {
  toEventModelExtended(eventModel: EventModel) {
    const languageService = appInjector().get(LanguageService);

    const model = { ...eventModel } as EventModelExtended;

    model.enrollmentStatusText =
      languageService.isCurrentLanguageArabic ?
        EnrollmentStatusText[eventModel.status].ar :
        EnrollmentStatusText[eventModel.status].en;

    return model;
  }
};
