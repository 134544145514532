import {Inject, Injectable} from '@angular/core';
import {MatDialog, MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CodeofconductForPortal} from '../../services/models/homepage-section-for-portal.model';
import { CodeOfConductComponent } from './code-of-conduct.component';
import {Subject} from 'rxjs';
import {ContentService} from '../../services/content.service';
import {Observable} from 'rxjs/Observable';
import { CurrentUserService } from 'src/app/services/current-user.service';

@Injectable()
export class CodeofconductDialogService {
  public dialogRef: MatDialogRef<CodeOfConductComponent>;
  public afterClosed: Subject<any> = new Subject<any>();
  public beforeClosed : Subject<any> = new Subject<any>();
  public data: CodeofconductForPortal;

  constructor(private matDialog: MatDialog, private contentService: ContentService, public currentUserService: CurrentUserService) {
    this.contentService.getCodeofconduct()
    .subscribe(section => this.data = section);
    this.currentUserService.user$.subscribe((res) => {
      res ? this.data.isLoggedIn = true : this.data.isLoggedIn = false;
      this.data.user = res;
    }); 
  }
  public open() {
    
    this.dialogRef = this.matDialog.open(CodeOfConductComponent,{data: this.data, panelClass: 'custom-dialog' });
    this.dialogRef.afterClosed().subscribe(data => {
      this.afterClosed.next(data);
    });
  }
  public close() {
    this.dialogRef.close();
  }
}
