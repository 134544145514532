import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { DashboardService } from '../../dashboard/dashboard.service';
import { EventListModelExtended, EventListModelConverter } from '../../dashboard/events/events.model';
import { ContentService } from '../../../services/content.service';
import { TokenService } from '../../../auth/services/token.service';
import { LoginDialogService } from '../../account/login/login.dialog.service';
import { filter } from 'rxjs/operators/filter';
import { CurrentUserService } from '../../../services/current-user.service';
import { appInjector } from '../../../app-injector';
import { ToasterService } from 'angular5-toaster/dist/angular5-toaster';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BASE_URL, transparentSource } from '../../../constants/constant';

@Component({
  selector: 'mvms-event-opportunities-detail',
  templateUrl: './event-opportunities-detail.component.html',
  styleUrls: ['./event-opportunities-detail.component.scss']
})
export class EventOpportunitiesDetailComponent implements OnInit, AfterViewInit {
  model: EventListModelExtended;
  visibleJoin = true;
  transparentSource = transparentSource;
  filterParams = {
    pageNo: 1,
    pageSize: 100,
    searchText: '',
    sortOrder: 'Asc'
  };
  constructor(
    private translate: TranslateService,
    protected dashboardService: DashboardService,
    private tokenService: TokenService,
    private loginDialogService: LoginDialogService,
    private contentService: ContentService,
    private currentUserService: CurrentUserService,
    protected route: ActivatedRoute,
    ) {
  }

  getMapLink(address:any) {
    return `https://maps.google.com/?q=${encodeURI(address)}`;
  }
  imageLink(id:any) {
    return `url(${BASE_URL}api/File/${id}/thumbnail)`;
  }
  ngAfterViewInit() {
    this.scrollToMain();
  }
  ngOnInit() {
    // window.scrollTo({ left: 0, top: this.el.nativeElement.offsetTop - 200, behavior: 'smooth' });
    this.route.params.subscribe((params: Params) => {
      this.contentService.getGlobalEventDetail(params['id'])
        .subscribe(data => {
          this.model = data;
        });
    });
    // window.onload = function(){
    //   this.eventDetail.nativeElement.focus();
    // }
  }
  scrollToMain() {
    const element = document.getElementById('main');
    $('body,html').animate({
      scrollTop: element.offsetTop - 50
    }, 800);
  }

  joinEvent(eventId:any) {
    // JOIN EVENT - NOT APPROVED
    if (this.tokenService.getToken()) {
      if (this.currentUserService.user && this.currentUserService.user.isAccepted) {

        this.contentService.joinEventl({ eventId: eventId }).subscribe(() => {
          this.visibleJoin = false;
        });
      }
      else {
        const toasterService = appInjector().get(ToasterService);
        if (this.translate.currentLang === 'en')
          toasterService.pop('error', 'Error', 'Event cannot be joined until your registration is approved.');
        else
          toasterService.pop('error', 'خطأ', 'لا يمكنك الإنضمام للفعالية حتى يتم قبول تسجيلك');
      }
    } else {
      this.loginDialogService.open();
    }

  }
}
