<div class="event-status">
  <h2 class="header">{{'DASHBOARD.event.status.Title' | translate }}</h2>
  <div class="content">
    <div class="row">
      <div class="event col l4 m6 s12">
        <h5 class="title">{{'DASHBOARD.event.status.Event' | translate }}</h5>
        <div class="status-card">
          <div class="status " [ngClass]="data.isPreviousEventRequirementOk ? 'accept':'reject'"></div>
        </div>
      </div>
      <div class="training col l4 m6 s12">
        <h5 class="title">{{'DASHBOARD.event.status.Training' | translate }}</h5>
        <div class="status-card" >
          <div class="status" [ngClass]="data.isTrainingRequirementOk ? 'accept':'reject'"></div>
        </div>
      </div>
      <!--div class="medical col l4 m6 s12">
        <h5 class="title">{{'DASHBOARD.event.status.Medical' | translate }}</h5>
        <div class="status-card">
          <div class="status" [ngClass]="data.isMedicalRequirementOk ? 'accept':'reject'"></div>
        </div>
      </div-->
    </div>
  </div>
  </div>

