import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  OnlineTrainingQuestionModel,
  SetOnlineTrainingResultModel,
  TrainingStatus,
  OnlineTrainingResultModelConverter,
  OnlineTrainingResultModelExtended
} from '../../training.model';
import { DashboardDataService } from '../../../../dashboard-data.service';
import { DashboardService } from '../../../../dashboard.service';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/switchMap';
import { switchMap } from 'rxjs/operators';
import { zip } from 'rxjs/observable/zip';

@Component({
  selector: 'mvms-training-quiz',
  templateUrl: './training-quiz.component.html',
  styleUrls: ['./training-quiz.component.scss']
})
export class TrainingQuizComponent implements OnInit, OnDestroy {
  TrainingStatus = TrainingStatus;
  checked = true;
  questions: OnlineTrainingQuestionModel[];
  result: OnlineTrainingResultModelExtended | null = null;
  isLoaded: boolean;

  protected componentDestroyed: Subject<any> = new Subject();

  constructor(
    private router: Router,
    protected dashboardService: DashboardService,
    protected dashboardDataService: DashboardDataService) { }

  ngOnInit() {
    if (this.dashboardService.currentTraining) {
      this.dashboardService.currentTraining.hasResult && !this.dashboardService.retakeTrainingMode ? this.getResultQuestions() : this.getQuestionsList();
      this.dashboardService.retakeTrainingMode = false;
    } else {
      this.router.navigate([`/dashboard/myEvents/${this.dashboardService.routeParams.id}/training`]);
    }

  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.dashboardService.retakeTrainingMode = false;
  }

  scrollTop() {
    const items = document.getElementsByClassName('content ');
    if (items.length > 0) {
      const item = <HTMLElement>items[0];
      window.scrollBy(0, item.offsetTop - window.pageYOffset + 300);
    }
  }

  getQuestionsList() {
    this.dashboardDataService.getOnlineTrainingQuestions(this.dashboardService.routeParams.trainingId)
      .takeUntil(this.componentDestroyed)
      .subscribe((questions: OnlineTrainingQuestionModel[]) => {
        this.questions = questions;
        this.isLoaded = true;
        setTimeout(() => {
          this.scrollTop();
        }, 100);
      });
  }

  getResultQuestions() {
    zip(
      this.dashboardDataService.getResultOnlineTraining(this.dashboardService.routeParams.trainingId),
      this.dashboardDataService.getResultQuestionsOnlineTraining(this.dashboardService.routeParams.trainingId)
    ).subscribe(([result, questions]) => {
      this.isLoaded = true;
      this.questions = questions;
      this.result = OnlineTrainingResultModelConverter.toOnlineTrainingResultModelExtended(result);
      setTimeout(() => {
        this.scrollTop();
      }, 100);
    });
  }

  onChangeAnswer(e, question) {
    question.isAnswered = false;
    if (e.checked) {
      question.isAnswered = true;
      return;
    } else {
      const answers = question.answers;
      for (let i = 0; i < answers.length; i++) {
        if (answers[i].isChecked) {
          question.isAnswered = true;
          return;
        }
      }
    }
  }

  submitAnswers() {
    for (let i = 0; i < this.questions.length; i++) {
      if (!this.questions[i].isAnswered) {
        setTimeout(() => {
          this.scrollTop();
        }, 100);
        return;
      }
    }

    const model: SetOnlineTrainingResultModel = {
      onlineTrainingId: this.dashboardService.routeParams.trainingId,
      questions: this.questions
    };

    this.dashboardDataService.setOnlineTrainingQuestions(model).pipe(
      switchMap(() => zip(
        this.dashboardDataService.getResultOnlineTraining(this.dashboardService.routeParams.trainingId),
        this.dashboardDataService.getResultQuestionsOnlineTraining(this.dashboardService.routeParams.trainingId)
      ))
    ).subscribe(([result, questions]) => {
      this.questions = questions;
      this.result = OnlineTrainingResultModelConverter.toOnlineTrainingResultModelExtended(result);
    });
  }

  retakeTest() {
    this.result = null;
    this.dashboardService.retakeTrainingMode = true;
    this.router.navigate([`/dashboard/myEvents/${this.dashboardService.routeParams.id}/online-training/${this.dashboardService.routeParams.trainingId}`]);
  }

  close() {
    this.router.navigate([`/dashboard/myEvents/${this.dashboardService.routeParams.id}/training`]);
  }


}
