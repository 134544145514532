import { appInjector } from '../../../../app-injector';
import { LanguageService } from '../../../../services/language.service';

export class TrainingModel {
  id: string;
  title: string;
  startDateTime: Date;
  submissionDateTime: Date;
  description: string;
  status?: TrainingStatus;
  appointmentId: string;
  resultId: string;
  capacity: number;
  groupSize: number;
  attempts: number;
  score: number;
  elapsedHrs: number;
  hasResult: boolean;
  isEnrolled: boolean;
  groupIsFull: boolean;
  canEnroll: boolean;
  location: string;
  longitude?: number;
  latitude?: number;
}

export class TrainingModelExtended extends TrainingModel {
  enrollmentStatusText: string;
}

export const TrainingModelConverter = {
  toTrainingModelExtended(trainingModel: TrainingModel): TrainingModelExtended {
    const model = { ...trainingModel } as TrainingModelExtended;

    const languageService = appInjector().get(LanguageService);

    if (typeof trainingModel.status === 'undefined') {
      trainingModel.status = TrainingStatus.None;
    }

    model.enrollmentStatusText = languageService.isCurrentLanguageArabic ?
      TrainingStatusText[trainingModel.status].ar :
      TrainingStatusText[trainingModel.status].en;

    return model;
  }
};

export enum TrainingStatus {
  Enrolled = 0,
  Attempted = 1,
  Graded = 2,
  Rejected = 3,
  All = 4,
  None = 5,
}

export const TrainingStatusText = {
  [TrainingStatus.Enrolled]: {
    en: 'Enrolled',
    ar: 'منضم'
  },
  [TrainingStatus.Attempted]: {
    en: 'Attempted',
    ar: 'غير مجتاز'
  },
  [TrainingStatus.Graded]: {
    en: 'Graded',
    ar: 'مجتاز'
  },
  [TrainingStatus.Rejected]: {
    en: 'Rejected',
    ar: 'غير موافق'
  },
  [TrainingStatus.All]: {
    en: 'All',
    ar: 'All'
  },
  [TrainingStatus.None]: {
    en: 'None',
    ar: 'غير ذلك'
  }
};

export class OnlineTrainingModel {
  id: string;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  scoreToPass: number;
  body: string;
  videoLink: string;
  hasResult: boolean;
}

export class OnlineTrainingListItemModel {
  id: string;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  maxAttempts: number;
  attempt: number;
  status?: TrainingStatus;
  videoLink: string;
  canAttend: boolean;
  hasResult: boolean;
}

export class OnlineTrainingListItemModelExtended extends OnlineTrainingListItemModel {
  enrollmentStatusText: string;
}

export const OnlineTrainingListItemModelConverter = {
  toOnlineTrainingListItemModelExtended(item: OnlineTrainingListItemModel): OnlineTrainingListItemModelExtended {
    const model = { ...item } as OnlineTrainingListItemModelExtended;

    const languageService = appInjector().get(LanguageService);

    if (!item.status) {
      item.status = TrainingStatus.None;
    }

    model.enrollmentStatusText = languageService.isCurrentLanguageArabic ?
      TrainingStatusText[item.status].ar :
      TrainingStatusText[item.status].en;

    return model;
  }
};

export class OnlineTrainingQuestionModel {
  id: string;
  body?: string;
  sequence?: number;
  answers: OnlineTrainingAnswerModel[];
  isAnswered: boolean;
}

export class OnlineTrainingAnswerModel {
  id: string;
  label?: string;
  isAnsweredCorrectly?: boolean;
  isChecked?: boolean;
}
export class SetOnlineTrainingResultModel {
  onlineTrainingId: string;
  questions: OnlineTrainingQuestionModel[];
}

export class OnlineTrainingResultModel {
  onlineTrainingId: string;
  id: string;
  status?: TrainingStatus;
  attempt: number;
  score: number;
  submissionDate: Date;
}

export class OnlineTrainingResultModelExtended extends OnlineTrainingResultModel {
  enrollmentStatusText: string;
}

export const OnlineTrainingResultModelConverter = {
  toOnlineTrainingResultModelExtended(item: OnlineTrainingResultModel): OnlineTrainingResultModelExtended {
    const model = { ...item } as OnlineTrainingResultModelExtended;

    const languageService = appInjector().get(LanguageService);

    if (!item.status) {
      item.status = TrainingStatus.None;
    }

    model.enrollmentStatusText = languageService.isCurrentLanguageArabic ?
      TrainingStatusText[item.status].ar :
      TrainingStatusText[item.status].en;

    return model;
  }
};
