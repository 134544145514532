import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TokenService } from './token.service';
import { Token } from '../models/token';
import { AuthConfig } from '../auth.config';
import { BodyOutputType, Toast, ToasterService } from 'angular5-toaster/dist';
import { ErrorToasterComponent } from '../../components/shared/error-toaster/error-toaster.component';
import { Subject } from 'rxjs/Subject';
import { AuthenticationService } from './authentication.service';
import { appInjector } from '../../app-injector';
import { NgProgress } from 'ngx-progressbar';

@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor {
  constructor(private config: AuthConfig,
    private _tokenService: TokenService,
    private injector: Injector,
    private toasterService: ToasterService) {
    this.config = new AuthConfig(config);
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: Token = this._tokenService.getToken();
    let lang = this._tokenService.getLanguage();
    if (token && !token.isExpired()) {
      const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
      let headers = req.headers.set(this.config.headerName, this.config.headerPrefix + ' ' + token.token);
      if (isIEOrEdge) {
        headers = req.headers.set(this.config.headerName, this.config.headerPrefix + ' ' + token.token).set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
          .set('If-Modified-Since', '0');
      }
      req = req.clone({ headers: headers });
    }
    const requestHideToaster = req.headers.get('hideToaster');
    if (lang) {
      lang = lang === 'en' ? lang + '-US' : lang + '-SA';
      req = req.clone({ headers: req.headers.set('X-Language', lang) });
    }

    if (req.url.indexOf('token') < 0) {
      const returnSubject = new Subject<HttpEvent<any>>();
      next.handle(req).subscribe((event: HttpEvent<any>) => {
        /// Success
        returnSubject.next(event);
      },
        (err: any) => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            const ngProgress = appInjector().get(NgProgress);
            ngProgress.done();
            if (err.status === 401 || err.status === 403) {
              // Circular reference if directly injected
              const auth = this.injector.get(AuthenticationService);
              auth.collectFailedRequest(req, returnSubject);
              auth.refreshToken().map(result => {
                auth.retryFailedRequests();
              }).subscribe(a => {

              }, (er: any) => {
                returnSubject.error(err);
              });
            } else {
              if (err.status === 400 || err.status === 500 || err.status === 0) {
                let lang = this._tokenService.getLanguage();
                let msg: string = lang === 'en' ?
                  'Unable to process your request. Check your connection and please try again.' :
                  'حصل خطأ أثناء معالجة الطلب. الرجاء التحقق من اتصال الانترنت والمحاولة لاحقاً.';

                const toast: Toast = {
                  type: 'error',
                  title: (err.error && err.error.message) ? err.error.message : msg,
                  body: ErrorToasterComponent,
                  bodyOutputType: BodyOutputType.Component,
                  data: err.error
                };
                if (!requestHideToaster) {
                  this.toasterService.pop(toast);
                }
                console.log(err);
              }
              returnSubject.error(err);
            }
          }
        });
      return returnSubject;
    } else {
      return next.handle(req);
    }
  }
}
