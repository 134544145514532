import {Component, Input, OnInit, ViewChild, OnChanges, EventEmitter, Output, ElementRef} from '@angular/core';

import {CroppieOptions} from 'croppie';
import {MatDialogRef} from '@angular/material/dialog';
import {MvmsNgxCroppieComponent} from '../mvms-ngx-croppie/mvms-ngx-croppie.component';

/**
 * Component containing Coppie instance and material design rotation buttons and slider.
 */
@Component({
  selector: 'angular-croppie',
  templateUrl: './croppie.component.html',
  styleUrls: ['./croppie.component.scss']
})

export class CroppieComponent implements OnInit {

  @Input()
  public options: CroppieOptions;
  @ViewChild('ngxCroppie', {static: true}) ngxCroppie: MvmsNgxCroppieComponent;
  //@Output() public handleUploadFile: EventEmitter<Object> = new EventEmitter();
 @Output() public handleUploadFile = new EventEmitter<FormData>();

  widthPx = '400';
  heightPx = '400';
  imageUrl = '';
  currentImage: string;
  croppieImage: string = '';
  public isVisibleUploadMessage = true;
  public file: any;
  public outputFormatOptions = {type: 'Blob', size: 'original'};

  public get imageToDisplay() {
    if (this.currentImage) {
      return this.currentImage;
    }
    if (this.imageUrl) {
      return this.imageUrl;
    }
    return `http://placehold.it/${this.widthPx}x${this.heightPx}`;
  }


  constructor(public dialogRef: MatDialogRef<CroppieComponent>) {
  }


  ngOnInit() {
    this.currentImage = this.imageUrl;
    this.croppieImage = this.imageUrl;
  }

  newImageResultFromCroppie(img: string) {
    this.croppieImage = img;

  }

  saveImageFromCroppie() {
    this.currentImage = this.croppieImage;
    if (this.croppieImage) {
      this.uploadImage();
    }
  }

  cancelCroppieEdit() {
    this.croppieImage = this.currentImage;
    this.isVisibleUploadMessage = true;
    this.dialogRef.close(true);
  }

  imageUploadEvent(evt: any) {
    if (!evt.target) {
      return;
    }
    if (!evt.target.files) {
      return;
    } else {
      this.isVisibleUploadMessage = false;
    }
    if (evt.target.files.length !== 1) {
      return;
    }
    this.file = evt.target.files[0];
    if (this.file.type !== 'image/jpeg' && this.file.type !== 'image/png' && this.file.type !== 'image/gif' && this.file.type !== 'image/jpg') {
      return;
    }
    const fr = new FileReader();
    fr.onloadend = (loadEvent) => {
      this.croppieImage = fr.result.toString();
      this.ngxCroppie.updateImg(this.croppieImage);
    };
    fr.readAsDataURL(this.file);
  }

  uploadImage() {
    const formData = new FormData();
    formData.append('file', this.currentImage, 'images.png');
    this.handleUploadFile.emit(formData);
  }


}
