import { Component, OnInit } from '@angular/core';
import {TaskListItemModel} from '../task.model';
import {TaskStatus} from '../../../../../constants/constant';
import {DashboardService} from '../../../dashboard.service';
import {DashboardDataService} from '../../../dashboard-data.service';
import {EventModel} from '../../events.model';
import {MvmsAnimations} from '../../../../../animations/animations';


@Component({
  selector: 'mvms-task-kanban',
  templateUrl: './task-kanban.component.html',
  styleUrls: ['./task-kanban.component.scss'],
  animations: MvmsAnimations
})
export class TaskKanbanComponent implements OnInit {

  public tasksStatus0 = Array<TaskListItemModel>();
  public tasksStatus1 = Array<TaskListItemModel>();
  public tasksStatus2 = Array<TaskListItemModel>();
  public currentEvent: EventModel;
  public taskStatus = TaskStatus;


  constructor(protected dashboardDataService: DashboardDataService,
              protected  dashboardService: DashboardService) {

    dashboardService.dataTaskList.subscribe(data => {
      if (data) {
        this.tasksStatus0 = data.items.filter(s => s.status === TaskStatus.ToDo);
        this.tasksStatus1 = data.items.filter(s => s.status === TaskStatus.Doing);
        this.tasksStatus2 = data.items.filter(s => s.status === TaskStatus.Done);
      }
    });


  }

  ngOnInit() {
    this.dashboardService.currentEvent.subscribe(event => {
      this.currentEvent = event;
      if (this.currentEvent) {
        this.dashboardDataService.getTaskList({
          pageNo: 1,
          pageSize: 100,
          searchText: '',
          eventId: this.currentEvent.id
        }).subscribe(data => {
          this.dashboardService.dataTaskList.next(data);
        });
      }
    });

  }
}
