<div class="upload-file">
  <div class="upload-msg" *ngIf="!croppieImage">{{ 'DASHBOARD.event.profile.uploadMessage' | translate }}</div>
  <mvms-ngx-croppie [hidden]="!croppieImage" #ngxCroppie [croppieOptions]="options" [outputFormatOptions]='outputFormatOptions'
    [imageUrl]="croppieImage" (result)="newImageResultFromCroppie($event)"></mvms-ngx-croppie>
  <div class="btn-group">
    <div class="upload-file-btn">
      <button mat-raised-button class='capitalize' color='accent' tabindex="-1">{{'SHARED.Button.Choose' | translate }}</button>
      <label for="fileupload"></label>
      <input class="input" type="file" id="fileupload" #imageUpload (change)="imageUploadEvent($event)" accept="image/gif, image/jpeg, image/png"
      />
    </div>
    <div class="btn-block">
      <div class="btn-item">
        <button type="button" mat-raised-button class='capitalize' color='accent' (click)="cancelCroppieEdit()">{{'SHARED.Button.Cancel' | translate }}
        </button>
      </div>
      <div class="btn-item">
        <button type="button" mat-raised-button class='capitalize' color='accent' (click)="saveImageFromCroppie()">{{'SHARED.Button.Upload' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
