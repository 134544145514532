<div class="view-result" [class.rtl]="isCurrentLanguageArabic" [attr.dir]="isCurrentLanguageArabic  ? 'rtl' : ''">
  <div class="header-panel">
    <h2 class="header">{{'DASHBOARD.event.ViewResult.header' | translate }}</h2>
    <div class="btn-close">
      <button mat-icon-button (click)="closeDialog()" tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="main" *ngIf="type === viewResultTypes.training">
    <div class="row">
      <div class="title col l6">{{'DASHBOARD.event.ViewResult.subDate' | translate }}</div>
      <div class="value col l6">
        <div class="data">{{data.submissionDateTime | dateTimeFormat}}</div>
        <div class="divider"></div>
      </div>
    </div>
    <div class="row">
      <div class="title col l6">{{'DASHBOARD.event.ViewResult.Status' | translate }}</div>
      <div class="value col l6">
        <div class="data">{{ data.enrollmentStatusText}}</div>
        <div class="divider"></div>
      </div>
    </div>
    <div class="row">
      <div class="title col l6">{{'DASHBOARD.event.ViewResult.Score' | translate }}</div>
      <div class="value col l6">
        <div class="data">{{data.score}}</div>
        <div class="divider"></div>
      </div>
    </div>
    <div class="row">
      <div class="title col l6">{{'DASHBOARD.event.ViewResult.TimeElaspsed' | translate }}</div>
      <div class="value col l6">
        <div class="data">{{data.elapsedHrs}} {{data.elapsedHrs > 1 ? 'hours' : 'hour'}}</div>
        <div class="divider"></div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="btn-group">
      <button type="button" mat-raised-button color="accent" (click)="closeDialog()">
        {{'SHARED.Button.Close' | translate }}
      </button>
    </div>
  </div>
  <div class="main" *ngIf="type === viewResultTypes.medical">
    <div class="row">
      <div class="title col l6">{{'DASHBOARD.event.ViewResult.AppointmentTime' | translate }}</div>
      <div class="value col l6">
        <div class="data">{{data.startDateTime | dateTimeFormat}}</div>
        <div class="divider"></div>
      </div>
    </div>
    <div class="row">
      <div class="title col l6">{{'DASHBOARD.event.ViewResult.City' | translate }}</div>
      <div class="value col l6">
        <div class="data">{{data.city}}</div>
        <div class="divider"></div>
      </div>
    </div>
    <div class="row">
      <div class="title col l6">{{'DASHBOARD.event.ViewResult.Clinic' | translate }}</div>
      <div class="value col l6">
        <div class="data">{{data.clinic}}</div>
        <div class="divider"></div>
      </div>
    </div>
    <div class="row">
      <div class="title col l6">{{'DASHBOARD.event.ViewResult.physician' | translate }}</div>
      <div class="value col l6">
        <div class="data">{{data.physician}}</div>
        <div class="divider"></div>
      </div>
    </div>
    <div class="row">
      <div class="title col l6">{{'DASHBOARD.event.ViewResult.Result' | translate }}</div>
      <div class="value col l6">
        <div *ngIf="data.isApproved; else failed" class="data">
          {{ 'DASHBOARD.event.ViewResult.pass' | translate }}
        </div>
        <ng-template>
          <div #failed>
            {{ 'DASHBOARD.event.ViewResult.fail' | translate }}
          </div>
        </ng-template>
        <div class="divider"></div>
      </div>
    </div>
    <div class="row">
      <div class="title col l6">{{'DASHBOARD.event.ViewResult.Comments' | translate }}</div>
      <div class="value col l6">
        <div class="data">{{data.isApproved}}</div>
        <div class="divider"></div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="btn-group">
      <button type="button" mat-raised-button color="accent" (click)="closeDialog()">
        {{'SHARED.Button.Close' | translate }}
      </button>
    </div>
  </div>
</div>
