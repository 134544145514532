import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginDialogService } from '../account/login/login.dialog.service';
import { BASE_URL,AWARD_URL } from '../../constants/constant';
import { ContentService } from '../../services/content.service';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { TokenService } from '../../auth/services/token.service';
import { CurrentUserService } from '../../services/current-user.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { MvmsAnimations } from '../../animations/animations';
import { DepartmentResources } from '../../services/models/department-resources.model';
import { LanguageService } from '../../services/language.service';
import { Location } from '@angular/common';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { HeaderDataService } from '../../components/shared/header/header-data.service';
import { Router } from '@angular/router';
import { toast } from 'angular2-materialize';
import { appInjector } from '../../app-injector';
import { ToasterService } from 'angular5-toaster/dist/angular5-toaster';


@Component({
  selector: 'mvms-award',
  templateUrl: './award.component.html',
  styleUrls: ['./award.component.scss']
})
export class AwardComponent implements OnInit {
  
  public isLoggedIn = false;
  public BASE_URL = BASE_URL;
  public AWARD_URL = AWARD_URL;
  APIToken : string = "?apiToken=" ;
  isCurrentLanguageArabic: boolean;
  counter: number = 0;
  public user: any;
  public token: string;
  
  constructor(
    public translate: TranslateService,
    public tokenService: TokenService,
    private contentService: ContentService,
    public currentUserService: CurrentUserService,
    public authService: AuthenticationService,
    public dashboardService: DashboardService,
    public loginDialogService: LoginDialogService,
    public headerDataService: HeaderDataService,
    private languageService: LanguageService,
    private router: Router,
    private location: Location

  ) {
    
   }
  
  ngOnInit() { 
    if (this.currentUserService.user) {
      this.isLoggedIn = true;
      this.user = this.currentUserService.user
    }
    this.currentUserService.user$.subscribe((res) => {
      res ? this.isLoggedIn = true : this.isLoggedIn = false;
    }); 
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
     
  }
  applyAward():void {
   
    if(this.isLoggedIn)
    {
       this.token = this.tokenService.getToken().token
       if(this.isCurrentLanguageArabic)
          window.open(AWARD_URL+"ar/ithra-volunteer-award"+this.APIToken+this.token,'_blank')
       else 
        window.open(AWARD_URL+"en/ithra-volunteer-award"+this.APIToken+this.token,'_blank')
    }
    else
    {
      this.loginDialogService.open();
      this.loginDialogService.dialogRef.beforeClosed().subscribe(result => {
        if(result){
          this.token = this.tokenService.getToken().token
          if(this.isCurrentLanguageArabic)
            window.open(AWARD_URL+"ar/ithra-volunteer-award"+this.APIToken+this.token,'_blank')
          else 
            window.open(AWARD_URL+"en/ithra-volunteer-award"+this.APIToken+this.token,'_blank')
        }
      });
      
      
    }
  }
  ngAfterViewInit() {
    this.scrollToMain();
  }
  scrollToMain() {
    const element = document.getElementById('main');
    $('body,html').animate({
      scrollTop: element.offsetTop - 50
    }, 800);
  }
  
}