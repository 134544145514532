<div *ngIf="currentEvent">
  <div class="action-list" *ngIf="medicalList && currentEvent.requireMedicalCheckup">
    <div class="card-info z-depth-3" *ngFor="let medical of medicalList">
      <h2 class="header">
        {{medical.title}}
      </h2>
      <div class="row">
        <div class="info-box col l12">
          <div class="title">{{'DASHBOARD.event.medical.date' | translate }}</div>
          <div class="value">{{medical.startDateTime | dateTimeFormat}}</div>
        </div>
      </div>
      <div class="row">
        <div class="info-box col l12">
          <div class="title">{{'DASHBOARD.event.medical.clinic' | translate }}</div>
          <div class="value">{{medical.clinic}}</div>
        </div>
      </div>
      <div class="row">
        <div class="info-box col l12">
          <div class="title">{{'DASHBOARD.event.medical.remarks' | translate }}</div>
          <div class="value">{{medical.remarks}}</div>
        </div>
      </div>
      <div class="row-flex static">
        <div class="btn-group ">
          <div class="btn-group-item" *ngIf="medical.hasResult">
            <button type="button" mat-raised-button color="accent" [@expandCollapseBoolean]="medical.hasResult" (click)="showViewresultDialog(medical)">
              {{'DASHBOARD.event.training.ViewResult' | translate }}
            </button>
          </div>
          <div class="btn-group-item" *ngIf="medical.canEnroll">
            <button type="button" mat-raised-button color="accent" [@expandCollapseBoolean]="medical.canEnroll" mvmsDebounceClick (debounceClick)="attendToMedical(medical.id)"
              [debounceTime]="1500">
              {{'DASHBOARD.event.training.Attend' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mvms-no-records *ngIf="medicalList && medicalList.length === 0"></mvms-no-records>

  <div class="card-info z-depth-3" *ngIf="currentEvent.requireMedicalConsent">
    <h2 class="header">
      {{'DASHBOARD.event.medical.consent' | translate }}
    </h2>
    <div class="message-test" [innerHTML]="termMeassage | async | safeHtml">
    </div>
    <div class="checkbox">
      <mat-checkbox color="primary" [disabled]='isApproved' [(ngModel)]="agreeTerms">{{'DASHBOARD.event.medical.understand' | translate }}
      </mat-checkbox>
    </div>
    <div class="row">
      <div class="info-box col l12">
        <p class="title">{{'DASHBOARD.event.medical.fullName' | translate }}</p>
        <form class="form">
          <mat-form-field class="full-width">
            <input matInput [formControl]="fullName" type='text' placeholder="{{'DASHBOARD.event.medical.fullName' | translate }}">
            <mat-error *ngIf="fullName.hasError('required')">
              {{'SHARED.Errors.emptyField' | translate }}
            </mat-error>
            <mat-error *ngIf="fullName.hasError('maxlength')">
              {{'SHARED.Errors.contestMedicalSignature' | translate }}
            </mat-error>
          </mat-form-field>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="btn-group" *ngIf="!isApproved">
        <button type="button" mat-raised-button color="accent" [disabled]="!agreeTerms || fullName.value === '' || fullName.invalid"
          mvmsDebounceClick (debounceClick)="submitAnswer(true)" [debounceTime]="1500">
          {{'DASHBOARD.event.medical.agree' | translate }}
        </button>
        <button type="button" mat-raised-button color="accent" (click)="return()">
          {{'DASHBOARD.event.medical.decline' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div #googlemap style='width: 100%; height: 500px;'></div>
  </div>
</div>
