// temp place for these models

export interface HomepageSectionForPortal {
    id: string;
    typeId: HomepageSectionTypeEnum;
    isVisible: boolean;
    title: string;
    content: string;
}
export interface CodeofconductForPortal{
    id: string;
    title: string;
    content: string;
    isDefault: boolean;
    isVisible: boolean;
    isLoggedIn: boolean;
    user: any;

}

export enum HomepageSectionTypeEnum {
    home = 0,
    blog = 1,
    joinUs = 2,
    about = 3,
    events = 4,
    training = 5,
    gallery = 6,
    contact = 7,
    footer = 8,
    medicalConsent = 9,
    codeOfConduct = 10
}
