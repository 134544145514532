import {Injectable} from '@angular/core';
import {BASE_URL} from '../../../constants/constant';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AccountProfileModel, BacicProfileModel, PersonalInfoProfileModel, SkillsProfileModel} from './user-profile.model';
import {Toast, ToasterMessagesTypes} from '../../../decorators/toast.decorator';
import {IResponseModel} from '../../../../types';
import {ProgressBar} from '../../../decorators/progress-bar.decorator';

@Injectable()
export class UserProfileDataService {
  private url = `${BASE_URL}api/public/volunteer`;

  constructor(private http: HttpClient) {
  }

  getBasicProfile(): Observable<BacicProfileModel> {
    return this.http.get<BacicProfileModel>(`${this.url}/basicProfile`);
  }

  getPersonalInfo(): Observable<PersonalInfoProfileModel> {
    return this.http.get<PersonalInfoProfileModel>(`${this.url}/personalInfo`);
  }

  getSkillsProfile(): Observable<SkillsProfileModel> {
    return this.http.get<SkillsProfileModel>(`${this.url}/skillInfo`);
  }

  getAccountProfile(): Observable<AccountProfileModel> {
    return this.http.get<AccountProfileModel>(`${this.url}/account`);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyUpdated)
  updatePersonalInfo(model): Observable<PersonalInfoProfileModel> {
    return this.http.put<PersonalInfoProfileModel>(`${this.url}/personalInfo`, model);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyUpdated)
  updateSkillsProfile(model:any): Observable<SkillsProfileModel> {
    return this.http.put<SkillsProfileModel>(`${this.url}/skillInfo`, model);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyUpdated)
  updateAccountProfile(model:any): Observable<AccountProfileModel> {
    const headers = new HttpHeaders().set('hideToaster', 'true');
    return this.http.put<AccountProfileModel>(`${this.url}/account`, model , {headers: headers});
  }

  @ProgressBar()
  uploadFile(image:any): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.post<IResponseModel>(`${this.url}/uploadAvatar`, image, {headers: headers});
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyUpdated)
  UpdateCOC(model:any): Observable<any>
  {
    const headers = new HttpHeaders();
    return this.http.post<IResponseModel>(`${this.url}/submitVolunteerCoc`, model,{headers: headers});
  }

}
