import { Component } from '@angular/core';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'mvms-change-password-container',
  templateUrl: './change-password-container.component.html',
  styleUrls: ['./change-password-container.component.scss']
})
export class ChangePasswordContainerComponent {

  constructor(private matDialog: MatDialog) {
    this.showDialog();
  }

  showDialog() {
    this.matDialog.open(ChangePasswordComponent, { disableClose: true });
  }

}
