import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';

import {Subject} from 'rxjs/Subject';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MvmsAnimations} from '../../../../../animations/animations';
import {EvaluationDataService} from '../evaluation-data.service';
import {DashboardService} from '../../../dashboard.service';
import {EvaluationCriteriaModel, EvaluationModel, EvaluationRequestPartyType} from '../evaluation.model';
import {EventModel} from '../../events.model';
import {EvaluationViewEditComponent} from '../evaluation-view-edit/evaluation-view-edit.component';
import {CreateEnquiryComponent} from '../../../enquiry/create-enquiry/create-enquiry.component';
import {Subscription} from '../../../../../../../node_modules/rxjs';

const TemplateType = {
  evaluate: 'evaluate',
  sentEvaluation: 'sentEvaluation',
  receivedEvaluation: 'receivedEvaluation'
};

@Component({
  selector: 'mvms-evaluation-list',
  templateUrl: './evaluation-list.component.html',
  styleUrls: ['./evaluation-list.component.scss'],
  animations: MvmsAnimations
})

export class EvaluationListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  isLoading: boolean;

  displayedColumns = [
    'eventTitle',
    'eventStartDateTime',
    'evaluationEndDate',
    'sendEvaluation',
    'evaluationReceived',
    'userFullName'
  ];

  myDataArray;

  subscription: Subscription[] = [];

  currentEvent: EventModel;

  evaluationRequestPartyType = EvaluationRequestPartyType;
  protected componentDestroyed: Subject<any> = new Subject();


  private criteriaList: EvaluationCriteriaModel[];
  private evaluationData: EvaluationModel;

  constructor(
    private evaluationDataService: EvaluationDataService,
    private dashboardService: DashboardService,
    private matDialog: MatDialog) {
    this.evaluationDataService.emitRefreshList
      .takeUntil(this.componentDestroyed).subscribe(
      () => {
        this.refreshList();

        this.isLoading = false;
      }
    );
  }

  ngOnInit() {
    this.refreshList();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  refreshList() {
    this.isLoading = true;

    this.dashboardService.currentEvent.takeUntil(this.componentDestroyed)
      .subscribe(event => {
        this.currentEvent = event;

        if (this.currentEvent) {
          this.evaluationDataService.getList({
            pageNo: 1,
            pageSize: 100,
            searchText: '',
            eventId: this.currentEvent.id,
          })
            .takeUntil(this.componentDestroyed).subscribe(data => {
              this.isLoading = false;

              if (!data) {
                return;
              }

              data.items.map(item => {
                item.eventTitle = this.currentEvent.title;

                item.eventStartDateTime = this.currentEvent.startDateTime;
              });

              this.myDataArray = new MatTableDataSource(data.items);

              setTimeout(() => {
                this.myDataArray.sort = this.sort;
              }, 600);
            },
            () => this.isLoading = false
          );
        }
      });
  }

  sendEvaluationView(evaluationPartyId) {
    this.evaluationDataService.getSendEvaluation(evaluationPartyId).subscribe(data => {
      this.evaluationData = data;
      const dialogRef = this.matDialog.open(EvaluationViewEditComponent, {panelClass: 'view-mode'});
      dialogRef.componentInstance.type = TemplateType.sentEvaluation;
      dialogRef.componentInstance.event = this.currentEvent;
      dialogRef.componentInstance.evaluationPartyId = evaluationPartyId;
      dialogRef.componentInstance.model = this.evaluationData;
      const sub = dialogRef.componentInstance.handleEscalate.subscribe(() => {
        this.escalateToAdmin(evaluationPartyId);
      });
      dialogRef.afterClosed().subscribe(() => {
        sub.unsubscribe();
      });
    });
  }

  evaluate(evaluationPartyId:any) {
    this.evaluationDataService.getCriteriaList(evaluationPartyId).subscribe((criteriaList) => {
      const dialogRef = this.matDialog.open(EvaluationViewEditComponent);
      dialogRef.componentInstance.event = this.currentEvent;
      dialogRef.componentInstance.evaluationPartyId = evaluationPartyId;
      dialogRef.componentInstance.criteriaList = criteriaList;
      dialogRef.componentInstance.type = TemplateType.evaluate;
      const sub = dialogRef.componentInstance.handleUpdateList.subscribe(() => {
        this.refreshList();
      });
      dialogRef.afterClosed().subscribe(() => {
        sub.unsubscribe();
      });
    });
  }

  evaluationReceivedView(evaluationPartyId) {
    this.evaluationDataService.getReceivedEvaluation(evaluationPartyId).takeUntil(this.componentDestroyed).subscribe(data => {
      const dialogRef = this.matDialog.open(EvaluationViewEditComponent, {panelClass: 'view-mode'});
      dialogRef.componentInstance.model = data;
      dialogRef.componentInstance.event = this.currentEvent;
      dialogRef.componentInstance.evaluationPartyId = evaluationPartyId;
      dialogRef.componentInstance.type = TemplateType.receivedEvaluation;
      const sub = dialogRef.componentInstance.handleEscalate.subscribe(() => {
        this.escalateToAdmin(evaluationPartyId);
      });
      dialogRef.afterClosed().subscribe(() => {
        sub.unsubscribe();
      });
    });
  }

  escalateToAdmin(evaluationPartyId) {
    setTimeout(() => {
      const dialogRef = this.matDialog.open(CreateEnquiryComponent);
      dialogRef.componentInstance.evaluationData = {
        event: this.currentEvent.title,
        personal: this.myDataArray.data.filter(i => i.id === evaluationPartyId)[0].userFullName
      };
    }, 300);
  }

}
