import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';
import {BASE_URL} from '../../../../constants/constant';


@Injectable()
export class TrainingService {

  private  url  = `${BASE_URL}api/public/training`;

  constructor(private http: HttpClient) { }

  getTrainings(): Observable<any> {
    const  filterParam = {
      pageNo: 1,
      pageSize: 25
    };

    return this.http.post(`${this.url}/list`, filterParam);
  }

  enrollToTraining(param): Observable<any> {
    return this.http.post(`${this.url}/enroll`, param);
  }


}
