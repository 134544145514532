import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {TokenService} from './token.service';
import {AuthConfig} from '../auth.config';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import {CurrentUserService} from '../../services/current-user.service';
import { ProgressBar } from '../../decorators/progress-bar.decorator';

@Injectable()
export class AuthenticationService {

  constructor(private http: HttpClient,
              private authConfig: AuthConfig,
              private tokenService: TokenService,
              private currentUserService: CurrentUserService,
              private router: Router) {
    this.authConfig = new AuthConfig(authConfig);
  }

  cachedFailedRequests: Array<{ request: HttpRequest<any>, subject: Subject<any> }> = [];
  isRefreshInProgress = false;

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  @ProgressBar()
  login(username: string, password: string): Observable<boolean> {
    const grant_type = 'password';
    const client_id = 'client-spa';
    const client_secret = 'secret';

    const body = new HttpParams()
      .set('grant_type', grant_type)
      .set('client_id', client_id)
      .set('client_secret', client_secret)
      .set('username', username)
      .set('password', password)
      .set('portal', 'public');

    const header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(this.authConfig.loginEndPoint, body, {headers: header}).map((response: any) => {
      if (response && response.access_token) {
        const token = response.access_token;
        const refreshToken = response.refresh_token;

        if (token) {
          this.tokenService.setToken(token);
          this.tokenService.setRefreshToken(refreshToken);
          return true;
        } else {
          return false;
        }
      }
      return false;
    });
  }

  refreshToken(): Observable<boolean> {
    if (this.isRefreshInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    }

    const grant_type = 'refresh_token';
    const client_id = 'client-spa';
    const client_secret = 'secret';
    this.isRefreshInProgress = true;

    const body = new HttpParams()
      .set('grant_type', grant_type)
      .set('client_id', client_id)
      .set('client_secret', client_secret)
      .set('refresh_token', this.tokenService.getRefreshToken());

    const header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(this.authConfig.loginEndPoint, body, {headers: header}).map((response: any) => {
      if (response && response.access_token) {
        const refreshToken = response.refresh_token;
        const token = response.access_token;
        this.tokenService.setToken(token);
        this.tokenService.setRefreshToken(refreshToken);
        this.isRefreshInProgress = false;
        return true;
      }
      this.logout();
    }).catch((err: any, caught: Observable<any>) => {
      this.isRefreshInProgress = false;
      this.logout();
      return Observable.throw(err);
    });
  }

  logout(): void {
    this.tokenService.removeTokens();
    this.currentUserService.clearCurrentUser();
    this.router.navigate(['/']);
  }

  public collectFailedRequest(request: HttpRequest<any>, subject: Subject<any>): void {
    this.cachedFailedRequests.push({request, subject});
  }

  public retryFailedRequests(): void {
    if (this.cachedFailedRequests.length === 0) {
      return;
    }

    const item = this.cachedFailedRequests.shift();
    const request = item.request.clone();
    this.http.request(request)
      .subscribe(response => {
          item.subject.next(response);
          this.retryFailedRequests();
        },
        (error) => {
          item.subject.error(error);
          this.retryFailedRequests();
        }
      );
  }
}
