import { Component, Input, OnInit } from '@angular/core';
import { RewardModel } from '../../../rewards/reward.model';
import { Router } from '@angular/router';

@Component({
  selector: 'mvms-badge-item',
  templateUrl: './badge-item.component.html',
  styleUrls: ['./badge-item.component.scss']
})
export class BadgeItemComponent implements OnInit {
  @Input() reward: RewardModel;

  constructor(private router: Router) { }

  ngOnInit() {
  }
  goToBadges() {
    this.router.navigate([`/dashboard/rewards`]);
  }

}
