import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import { Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from '../../../auth/services/authentication.service';
import { RegexpPattern} from '../../../constants/constant';
import {CurrentUserService} from '../../../services/current-user.service';
import {RecoverPasswordComponent} from '../recover-password/recover-password.component';
import { LanguageService } from '../../../services/language.service';
import { Subject } from '../../../../../node_modules/rxjs';
import {CodeofconductDialogService} from '../../code-of-conduct/codeofconduct.dialog.service'

@Component({
  selector: 'mvms-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private componentDestroyed: Subject<any> = new Subject();
  private resetServerValidation: boolean;

  public loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(new RegExp(RegexpPattern.email))]],
    password: ['', [Validators.required, Validators.pattern(new RegExp(RegexpPattern.password)) ]]
  });

  public invalidEmail: boolean;
  public invalidPassword: boolean;
  public pendingSatus: boolean;
  public disabledSatus: boolean;
  public lockedSatus: boolean;
  public isCurrentLanguageArabic: boolean;

  constructor(
    public fb: FormBuilder,
    private authService: AuthenticationService,
    private currentUserService: CurrentUserService,
    private router: Router,
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<LoginComponent>,
    public cocDialogService: CodeofconductDialogService,
    private languageService: LanguageService
   ) {
  }


  ngOnInit() {
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;

    this.loginForm.valueChanges.takeUntil(this.componentDestroyed).subscribe(() => {
      if (this.resetServerValidation) {
        this.resetServerValidation = false;
        this.setInvalidFieldsFromServer(false, false);
        this.setInvalidFromServer(false, false, false, false, false);
      }
    });
  }

  public login(): void {

    if (this.loginForm.valid) {
      const user = this.loginForm.value;
      this.authService.login(user.email, user.password)
        .subscribe(
          (data: any) => {
            this.currentUserService.getCurrentUser().subscribe(result => {
              if(!result.isCOC){
                  this.cocDialogService.open();
                  this.cocDialogService.dialogRef.beforeClosed().subscribe(data =>{
                    if(!data)
                    {
                      this.authService.logout();
                    }

                  });
              }
            });
            this.dialogRef.close(true);
            this.checkRouting();
          },
        error => {
          this.resetServerValidation = true;

          if (error.error.error_description === 'not approved') {
            this.setInvalidFromServer(false, false, true, false, false);
            this.setInvalidFieldsFromServer(true, false);
          } else  if (error.error.error_description === 'is disabled') {
            this.setInvalidFromServer(false, false, false, true, false);
            this.setInvalidFieldsFromServer(true, false);
          } else if (error.error.error_description === 'locked out') {
            this.setInvalidFromServer(false, false, false, false, true);
            this.setInvalidFieldsFromServer(true, false);
          } else if (error.error.error_description === 'invalid username') {
            this.setInvalidFromServer(true, true, false, false, false);
            this.setInvalidFieldsFromServer(true, true);
           } else {
            this.setInvalidFromServer(false, true, false, false, false);
            this.setInvalidFieldsFromServer(false, true);
           }
        });
    }
  }

  setInvalidFieldsFromServer(isEmailInvalid: boolean, isPasswordinvalid: boolean) {
    if (isEmailInvalid) {
      this.loginForm.controls.email.setErrors({ isInvalid: true });
    } else {
      if (this.loginForm.controls.email.errors) {
        this.loginForm.controls.email.errors['isInvalid'] = null;
      }
      this.loginForm.controls.email.updateValueAndValidity({emitEvent: false});
    }

    if (isPasswordinvalid) {
      this.loginForm.controls.password.setErrors({ isInvalid: true });
    } else {
      if (this.loginForm.controls.password.errors) {
        this.loginForm.controls.password.errors['isInvalid'] = null;
      }
      this.loginForm.controls.password.updateValueAndValidity({emitEvent: false});
    }
  }

  setInvalidFromServer(invalidEmail: boolean, invalidPassword: boolean, pendingSatus: boolean, disabledSatus: boolean, lockedSatus: boolean) {
    this.invalidEmail = invalidEmail;
    this.invalidPassword = invalidPassword;
    this.pendingSatus = pendingSatus;
    this.disabledSatus = disabledSatus;
    this.lockedSatus = lockedSatus;
  }

  showRecoverDialog() {
    this.dialogRef.close();
    this.matDialog.open(RecoverPasswordComponent, {disableClose: true});
  }

  checkRouting() {
    if (this.router.url.includes('join-us')) {
      this.router.navigate(['/opportunities']);
    }
  }

}
