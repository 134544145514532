<div class="section dashboardsublogo no-pad">
  <div>
    <div class="container counters">
      <div class="center">
        <h1>{{'DASHBOARD.YourDashboard' | translate }}</h1>
      </div>
      <div class="row counts counts-card " *ngIf="counter">
        <div class="col l4 m6 s6">
          <div>
            <span class="value"><h3>{{counter.volunteeringHours}}</h3>{{'DASHBOARD.counter.VolunteeringHours' | translate }}</span>
          </div>
        </div>
        <div class="col l4 m6 s6">
          <div>
            <span class="value"><h3>{{counter.taskCompleted}}</h3>{{'DASHBOARD.counter.TasksCompleted' | translate }}</span>
          </div>
        </div>
        <div class="col l4 m6 s6">
          <div>
            <span class="value"><h3>{{counter.badgesEarned}}</h3>{{'DASHBOARD.counter.BadgesEarned' | translate }}</span>
          </div>
        </div>
        <!-- <div class="col l3 m6 s6">
          <div>
            <span class="value"><h3>{{counter.evaluations | number :'1.2-2'}}</h3>{{'DASHBOARD.counter.Evaluation' | translate }}</span>
          </div>
        </div> -->
      </div>

    </div>
  </div>
</div>

<div id='dashboardmain' class="section no-pad transparent">
    <div class="container">
      <div class="row">
        <div class="nav-menu col l3 m1 s0">
          <mvms-nav-panel></mvms-nav-panel>
        </div>
        <div class="content col l9 m11 s12">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

</div>

  <!-- <div class="section no-pad row">
    <div class="col l12 greengold">
    </div>
  </div> -->
