<div class="form-container">
  <form class='form' [formGroup]="skillsExperincesForm" (ngSubmit)="update()" #form="ngForm">
    <div class="form-section">
      <h2 class="header">{{'DASHBOARD.event.profile.SkillsTabTitle' | translate }}</h2>
      <div class="row">
        <h4 class="title col l4 IE-padding">{{'DASHBOARD.event.profile.ExperiencesTab.haveVolunteered' | translate }}</h4>
        <mat-radio-group class="col l4 radio-btn" formControlName="hasExperience">
          <mat-radio-button [value]="true" color="primary">{{'SHARED.Button.Yes' | translate }}</mat-radio-button>
          <mat-radio-button [value]="false" color="primary">{{'SHARED.Button.No' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="row" *ngIf="skillsExperincesForm.controls['hasExperience'].value">
        <h4 class="title col l4 IE-padding">{{'DASHBOARD.event.profile.ExperiencesTab.Where' | translate }}</h4>
        <mat-form-field class="col l4" floatLabel="never">
          <input matInput formControlName="experienceLocation" type='text' [placeholder]="'DASHBOARD.event.profile.ExperiencesTab.Location' | translate">
        </mat-form-field>
      </div>
      <div class="row">
        <h4 class="title col l4 IE-padding">{{'DASHBOARD.event.profile.ExperiencesTab.Experiences' | translate }}</h4>
        <mat-form-field class="col l4" floatLabel="never">
          <textarea matInput formControlName="experienceType" type='text' matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="5"
            [placeholder]="'DASHBOARD.event.profile.ExperiencesTab.Description' | translate">
                 </textarea>
        </mat-form-field>
      </div>
      <div class="row">
        <h4 class="title col l4 IE-padding">{{'DASHBOARD.event.profile.ExperiencesTab.Skills' | translate }}</h4>
        <mat-form-field class="col l4" floatLabel="never">
          <mat-select [placeholder]="'DASHBOARD.event.profile.ExperiencesTab.Skills' | translate"
                      formControlName="skills" panelClass="mvms-public"
                      multiple
                      [compareWith]="compareWithFn">
            <mat-option class="IE-select IE-padding" *ngFor="let skill of skillsList" [value]="skill">

              {{skill.value}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="!skillsExperincesForm.controls.skills.valid">
            {{'REGISTRATION.Errors.Skills' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <h4 class="title col l4 IE-padding">{{'DASHBOARD.event.profile.ExperiencesTab.ShareLink' | translate }}</h4>
        <mat-form-field class="col l4 ltr" floatLabel="never">
          <input matInput formControlName="validationLink" type='text' [placeholder]="'DASHBOARD.event.profile.ExperiencesTab.linkPlaceholder' | translate">
          <mat-hint class="hint" align="start"> {{'DASHBOARD.event.profile.ExperiencesTab.ShareLinkHint' | translate}}</mat-hint>
          <mat-error *ngIf="skillsExperincesForm.controls['validationLink'].hasError('link') || skillsExperincesForm.controls['validationLink'].hasError('https')">
            {{ ( getErrorMessage() === 'link' ? 'SHARED.Errors.linkInvalid' : 'SHARED.Errors.httpsInvalid') | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <h4 class="title col l4 IE-padding">{{'DASHBOARD.event.profile.ExperiencesTab.ReasonsJoin' | translate }}</h4>
        <mat-form-field class="col l4" floatLabel="never">
          <mat-select [placeholder]="'DASHBOARD.event.profile.ExperiencesTab.ReasonsJoin' | translate" formControlName="joiningReason"
            [compareWith]="compareWithFn">
            <mat-option class="IE-select IE-padding" *ngFor="let reason of reasonsList" [value]="reason">
              {{reason.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row">
        <h4 class="title col l4 IE-padding">{{'DASHBOARD.event.profile.ExperiencesTab.PossibleDay' | translate }}</h4>
        <mat-form-field class="col l4" floatLabel="never">
          <mat-select [placeholder]="'DASHBOARD.event.profile.ExperiencesTab.PossibleDay' | translate" formControlName="participationDays"
            multiple>
            <mat-option class="IE-select IE-padding" *ngFor="let day of participationDayType" [value]="day.id">
              {{day.value}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="skillsExperincesForm.controls['participationDays'].hasError('required')">
            {{'SHARED.Errors.emptyField' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <h4 class="title col l4 IE-padding">{{'DASHBOARD.event.profile.ExperiencesTab.PossibleTime' | translate }}</h4>
        <mat-form-field class="col l4" floatLabel="never">
          <mat-select class="IE-select" [placeholder]="'DASHBOARD.event.profile.ExperiencesTab.PossibleTime' | translate" formControlName="participationTime">
            <mat-option class="IE-select IE-padding" *ngFor="let time of participationTimeType" [value]="time.id">
              {{time.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row">
        <h4 class="title col l4 IE-padding">{{'DASHBOARD.event.profile.ExperiencesTab.PossibleHours' | translate }}</h4>
        <div class="col l4" floatLabel="never">
          <mvms-dropdown-select [data]="participationHoursType" [allowCustomValues]="false" [inputControl]="skillsExperincesForm.controls['maxHoursPerDay']"
            [placeholder]="'DASHBOARD.event.profile.ExperiencesTab.PossibleHours' | translate">
          </mvms-dropdown-select>
        </div>
      </div>
      <div class="row">
        <h4 class="title col l4 IE-padding">{{'DASHBOARD.event.profile.ExperiencesTab.SelectOne' | translate }}</h4>
        <mat-radio-group class="col l4 radio-btn diseasesRadio" formControlName="hasDiseases">
          <mat-radio-button [value]="false" color="primary">{{'DASHBOARD.event.profile.ExperiencesTab.NoDiseases' | translate }}</mat-radio-button>
          <mat-radio-button [value]="true" color="primary">{{'DASHBOARD.event.profile.ExperiencesTab.SomeDiseases' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <!--div class="row" *ngIf="skillsExperincesForm.controls['hasDiseases'].value">
        <h4 class="title col l4 IE-padding">{{'DASHBOARD.event.profile.ExperiencesTab.WhichDisease' | translate }}</h4>
        <mat-form-field class="col l4" floatLabel="never">
          <textarea matInput formControlName="diseases" type='text' matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="5"
            [placeholder]="'DASHBOARD.event.profile.ExperiencesTab.Description' | translate">
                 </textarea>
          <mat-error *ngIf="skillsExperincesForm.controls['diseases'].hasError('required')">
            {{'SHARED.Errors.emptyField' | translate }}
          </mat-error>
          <mat-error *ngIf="skillsExperincesForm.controls['diseases'].hasError('pattern')">
            {{'SHARED.Errors.alphabetsOnly' | translate }}
          </mat-error>
        </mat-form-field>
      </div-->
    </div>
    <div class="row form-row_btn-group">
      <div class="btn-group col l12">
        <div class="btn-group-item">
          <button type="submit" [disabled]="isLoading || !formChanged" mat-raised-button class='capitalize IE-padding' color='accent'>
            {{'SHARED.Button.Save' | translate }}
          </button>
        </div>
        <div class="btn-group-item">
          <button type="button" mat-raised-button class='capitalize IE-padding' color='accent' (click)="reset()">{{'SHARED.Button.Cancel' | translate }}</button>
        </div>
      </div>
    </div>
  </form>
</div>
