import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

@Injectable()
export class RecaptchaDynamicLanguageLoaderService {
  private static ready: BehaviorSubject<any>;
  public ready: Observable<any>;
  public language = '';

  constructor() {
    this.init();
    this.ready = RecaptchaDynamicLanguageLoaderService.ready.asObservable();
  }

  public updateLanguage(newLang: string): void {
    this.language = newLang;
    RecaptchaDynamicLanguageLoaderService.ready.next(null);
    this.init();
  }

  private init() {
    if (RecaptchaDynamicLanguageLoaderService.ready) {
      if (RecaptchaDynamicLanguageLoaderService.ready.getValue()) {
        return;
      }
    } else {
      RecaptchaDynamicLanguageLoaderService.ready = new BehaviorSubject<any>(null);
      (window as any).ng2recaptchaloaded = () => {
        try {
          RecaptchaDynamicLanguageLoaderService.ready.next(grecaptcha);
        } catch {}
      };
    }
    setTimeout(() => {
      $('[src^="https://www.google.com/recaptcha/api.js?render=explicit&amp;onload=ng2recaptchaloaded"]').remove();
      const script = document.createElement('script') as HTMLScriptElement;
      script.innerHTML = '';
      const langParam = this.language ? '&hl=' + this.language : '';
      script.src = `https://www.google.com/recaptcha/api.js?render=explicit&onload=ng2recaptchaloaded${langParam}`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }, 1000);
  }
}
