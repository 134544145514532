import {Component, OnInit, AfterViewInit} from '@angular/core';
import {ContentService} from '../../services/content.service';
import {BASE_URL} from '../../constants/constant';

@Component({
  selector: 'mvms-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss']
})

export class OpportunitiesComponent implements OnInit, AfterViewInit {
  BASE_URL = BASE_URL;
  public events: any[];
  public isLoading;

  constructor(private contentService: ContentService) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.contentService.getGlobalEventListExclMine(
      {
        pageNo: 1,
        pageSize: 100,
        searchText: '',
        sortOrder: 'Asc'
      }
    ).subscribe((data: any) => {
      this.isLoading = false;
      this.events = data.items;
      this.contentService.GlobalEventList$.next(this.events);
    });
  }
  ngAfterViewInit() {
    this.scrollToMain();
  }
  scrollToMain() {
    const element = document.getElementById('main');
    $('body,html').animate({
      scrollTop: element.offsetTop - 50
    }, 800);
  }
}
