import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';

import {AccountService} from '../account.service';
import {RegexpPattern} from '../../../constants/constant';
import {CommonValidators} from '../../../common-validators';
import {LoginDialogService} from '../login/login.dialog.service';
import {MatDialogRef} from '@angular/material/dialog';
import {ChangePasswordContainerComponent} from '../change-password-container/change-password-container.component';
import { LanguageService } from '../../../services/language.service';
import { Subject } from '../../../../../node_modules/rxjs';

@Component({
  selector: 'mvms-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  public isCurrentLanguageArabic: boolean;
  public userId = '';
  public token = '';

  public changePasswordForm = this.fb.group({
    newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50), Validators.pattern(new RegExp(RegexpPattern.password))]],
    confirmNewPassword: ['', [Validators.required]]
  }, {validator: CommonValidators.isMatch('newPassword', 'confirmNewPassword')});

  private componentDestroyed: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private loginDialogService: LoginDialogService,
    private router: Router,
    private dialogRef: MatDialogRef<ChangePasswordContainerComponent>,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService
    ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.userId = params['userId'];
      this.token = params['token'];
    });
  }

  ngOnInit() {
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;

    this.changePasswordForm.valueChanges.takeUntil(this.componentDestroyed).subscribe(() => {
      if (this.changePasswordForm.hasError('isMatch')) {
        this.changePasswordForm.controls.confirmNewPassword.setErrors({ isMatch: true });
      } else {
        this.changePasswordForm.controls.confirmNewPassword.updateValueAndValidity({emitEvent: false});
      }
    });
  }

  ngOnDestroy() {
    this.router.navigate(['/']);
  }

  public setNewPassword() {
    if (this.changePasswordForm.valid) {
      const model = {
        newPassword: this.changePasswordForm.value.newPassword,
        confirmPassword: this.changePasswordForm.value.confirmNewPassword,
        token: this.token,
        userId: this.userId
      };
      this.accountService.setNewPassword(model).subscribe(data => {
        this.router.navigateByUrl('/');
        this.dialogRef.close();
        this.openLogin();
      });
    }
  }

  public getErrorMessage() {
    return this.changePasswordForm.controls.confirmNewPassword.hasError('required') ? 'You must enter a value' :
      this.changePasswordForm.hasError('isMatch') ? ' Passwords do not match' : '';
  }

  public openLogin() {
    this.loginDialogService.open();
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
