<div *ngIf="enquiryList && enquiryList.length; else noRecords" class="enquiries z-depth-1">
  <div class="enquiries-list">
    <h3 class="list-header">{{'DASHBOARD.Enquiries.createSubject' | translate }}</h3>
    <div class="according-panel">
      <mat-accordion multi="false">
          <mat-expansion-panel [expanded]="currentItemId === item.id" *ngFor="let item of enquiryList"  (click)="readItem(item)">
            <mat-expansion-panel-header class="mat-accent-bg">
              <div class="title-wrapper" fxLayout="row" fxLayoutAlign="space-between stretch">
                  <span class="IE-padding" [title]='item.subject' *ngIf="item.isReplyRead !== null && !item.isReplyRead"><b>{{item.subject}}</b></span>
                  <span class="IE-padding"[title]='item.subject' *ngIf="item.isReplyRead === null || item.isReplyRead">{{item.subject}}</span>
                  <div class="value IE-padding">{{item.requestDate | timeAgo}}</div>
              </div>
            </mat-expansion-panel-header>

            <div class="info-table" >
                <div class="enquiries-details expand" [@expandCollapseHorizontal]="currentItem">
                    <div class="details request" *ngIf="currentItem">
                      <div class="row">
                        <div class="info-box col l6 m12">
                          <div class="title">{{'DASHBOARD.Enquiries.From' | translate }}</div>
                          <div class="value">{{currentItem.volunteerEmail}}</div>
                        </div>
                        <div class="info-box col l6 m12">
                          <div class="title">{{'DASHBOARD.Enquiries.Date' | translate }}</div>
                          <div class="value">{{currentItem.requestDate | dateTimeFormat}}</div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="info-box col l12">
                          <div class="value" [innerHTML]="currentItem.requestBody"></div>
                        </div>
                      </div>
                    </div>
                    <div class="details reply" *ngIf="currentItem.replyDate">
                      <div class="row">
                        <div class="info-box col l6 m12">
                          <div class="title">{{'DASHBOARD.Enquiries.From' | translate }}</div>
                          <div class="value">{{currentItem.employeeEmail}}</div>
                        </div>
                        <div class="info-box col l6 m12">
                          <div class="title">{{'DASHBOARD.Enquiries.Date' | translate }}</div>
                          <div class="value">{{currentItem.replyDate | dateTimeFormat}}</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="info-box col l12">
                          <div class="value" [innerHTML]="currentItem.replyBody"></div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="list">
      <mat-selection-list >
        <mat-list-option class="list-item" *ngFor="let item of enquiryList"
        (click)="readItem(item)">
          <span fxFill class="enq-title" [title]='item.subject' *ngIf="item.isReplyRead !== null && !item.isReplyRead"><b>{{item.subject}}</b></span>
          <span fxFill class="enq-title" [title]='item.subject' *ngIf="item.isReplyRead === null || item.isReplyRead">{{item.subject}}</span>
          <span fxFill class="value">{{item.requestDate | dateTimeFormat}}</span>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>
  <div class="enquiries-details" [@expandCollapseHorizontal]="currentItem">
    <h3 class="details-title">{{currentItem.subject}}</h3>
    <div class="details request" *ngIf="currentItem">
      <div class="row">
        <div class="info-box col l6 m12">
          <div class="title">{{'DASHBOARD.Enquiries.From' | translate }}</div>
          <div class="value">{{currentItem.volunteerEmail}}</div>
        </div>
        <div class="info-box col l6 m12">
          <div class="title">{{'DASHBOARD.Enquiries.Date' | translate }}</div>
          <div class="value">{{currentItem.requestDate | dateTimeFormat}}</div>
        </div>
      </div>

      <div class="row">
        <div class="info-box col l12">
          <div class="value" [innerHTML]="currentItem.requestBody"></div>
        </div>
      </div>
    </div>
    <div class="details reply" *ngIf="currentItem.replyDate">
      <div class="row">
        <div class="info-box col l6 m12">
          <div class="title">{{'DASHBOARD.Enquiries.From' | translate }}</div>
          <div class="value">{{currentItem.employeeEmail}}</div>
        </div>
        <div class="info-box col l6 m12">
          <div class="title">{{'DASHBOARD.Enquiries.Date' | translate }}</div>
          <div class="value">{{currentItem.replyDate | dateTimeFormat}}</div>
        </div>
      </div>
      <div class="row">
        <div class="info-box col l12">
          <div class="value" [innerHTML]="currentItem.replyBody"></div>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #noRecords>
    <mvms-no-records *ngIf="!isLoading"></mvms-no-records>
</ng-template>
