<div class="section" #sectionContainer *ngIf="isCurrentLanguageArabic">
<div class="row">
<div class="row">
<h2>طلب متطوعين</h2>
<div class="col s12 l4" style="margin-top:20px;">
    <p> <br/><img src="../../../assets/images/RequestVolunteer.jpg" style="border-radius:20px" alt="" /> </p>
    </div>
<div class="col s12 l8" style="margin-top:30px;">
<h4>رؤية إثراء</h4>
        <p>  أن يصبح مركز الملك عبد العزيز الثقافي العالمي (إثراء) منصّة رائدة للعمل التطوعي، عبر تقديم فرص تطوعيّة إثرائية لتبادل المعلومات وتطوير المهارات وتغذية الإبداع والتواصل الحضاري والثقافي مع العالم.

</p>
<h4>التطوع في إثراء</h4>
<p>انطلاقًا من رسالة  أرامكو السعودية التي تؤمن بالمساهمة الفاعلة في العمل على الارتقاء بمجتمعها ووطنها على كافة الأصعدة، يعمل  مركز الملك عبدالعزيز الثقافي العالمي (إثراء) - مبادرة أرامكو السعودية-  على الالتزام بقيمة المواطنة التي أرست أُسُسها الشركة بناء على طبيعة احتياجات المجتمع.
</p>
<p>وإيمانًا منّا بأن الشباب هم المصدر الحقيقي لطاقة هذا الوطن،  يشكّل المركز نموذجًا فريدًا في تطوير الشباب وإثرائهم في عدة مجالات تحت سقف واحد، عبر برامجه التطوعية في السينما والمسرح والمكتبات والمتاحف والإعلام الرقمي والعلوم والبرامج التعليمية المختلفة.
</p> </div>

</div>
</div>
<div class="row" style="margin: top 20px;">

<div class="col s12 l8" style="margin-top: -23px;">

<h4>القيم الأساسية لإثراء:</h4>
<h5>الاستدامة</h5>
<p>توفير أنشطة تطوعية مستدامة لتلبية احتياجات برامج المركز، لرفع الوعي وترسيخ ثقافة التطوع في جميع مناطق المملكة.
</p>
<h5>التعزيز</h5> 
<p>نشر وتوعية المجتمع المحلي والعالمي بجهود ومبادرات الشباب السعودي لإثراء العمل التطوعي بجميع أنواعه، وخلق فرص تطوعية عديدة. </p>
<h5>التمكين </h5>
<p>تمكين الشباب من المبادرة في أنشطة المجتمع، عن طريق توفير فعاليات إثرائية وبرامج تطوعية لتطوير وبناء مهاراتهم ونشر المعرفة والإبداع.
</p>
</div>
  <div class="col s12 l4" style="margin-top:30px;">
    <p> <br/><img src="../../../assets/images/request_volunteer2.jpg" style="border-radius:20px;margin-top:-50px" alt="" /> </p>
    </div>
    
            
    </div>
  
<div class="row">

  <div class="row Terms" style="border-radius: 20px; margin-top:80px;">
        <div class="col s12 l4"style="margin-top:25px"><h3>القواعد والمسؤوليات لطلب المتطوعين </h3> </div>
        <div class="col s12 l8"style="margin-top:22px ;margin-bottom:22px"><p> 1.على الموظف تسجيل جميع المتطوعين عند الوصول إلى الموقع.<br/>
                              2. على الموظف تقديم موجز قواعد السلامة لجميع المتطوعين حول الموقع والبرامج.<br/>
                              3.  لا يُسمح بإعطاء المتطوعين أجهزة  (Bravo) دون إذن من خدمات المتطوعين.
<br/>
                              4. يمنع منعًا باتًا إعطاء المتطوعين أجهزة (Tetra). <br/>
                              5. لا يُترك المتطوعين بمفردهم في الموقع دون مشرف.
<br/>
                              6. يجب على جميع المتطوعين أخذ استراحة تتراوح بين 45 -60 دقيقة.
<br/>
                              7. يجب إبلاغ خدمات المتطوعين إذا قام أحد المتطوعين بالمغادرة قبل انتهاء وقت الفرصة التطوعية.

<br/> </p>  
           
        </div>
   </div>
  

  <div class="row" [ngStyle]="{'text-align':'center'}" style="margin-top: 55px;">
            <button class="IE-padding" mvmsDebounceClick (debounceClick)="requestVolunteers()"
              [debounceTime]="2000">{{'RequestVolunteers.Title' | translate }}</button>
      </div>
 
</div>
</div>

<!-- English Content -->

<div class="section" #sectionContainer *ngIf="!isCurrentLanguageArabic">

<div class="row">
<div class="row">
 
  <h2>Request Volunteers</h2>
 
  <div class="col s12 l8"style="margin-top:30px;"><h4>Vision</h4>
        <p>  To be the thriving hub for volunteerism by providing enriching volunteering opportunities through exchanging knowledge, developing skills and cross-cultural engagement.

</p>
<h4>Volunteering at Ithra</h4>
<p>The King Abdulaziz Center for World Culture (Ithra) seeks to invest in our people, unleashing their creative potential. We realize that the true source of sustainable wealth is in the energy and creativity of our people.
</p></div>
<div class="col s12 l4">
    <p> <br/><img src="../../../assets/images/RequestVolunteer.jpg" style="border-radius:20px; " alt="" /> </p>
    </div>
</div>
</div>
<div class="row" >
  <div class="col s12 l4">
    <p> <br/><img src="../../../assets/images/request_volunteer2.jpg" style="border-radius:20px; margin-top:-50px" alt="" /> </p>
    </div>
    <div class="col s12 l8" style="margin-top: -23px;">

<h4>Ithra Key Values:</h4>
<h5>Sustainability</h5>
<p>We offer sustainable volunteer activities that can raise awareness and serve different needs in the Kingdom.</p>
<h5>Promotion</h5> 
<p> We aim to raise awareness on volunteerism, both nationally and internationally, as it is being taken responsibly by young Saudis. </p>
<h5>Empowerment </h5>
<p>We will empower the volunteerism and the creative spirit of our young volunteers.</p>

            
    </div>
    
    
  </div>
  
  
 
<div class="row">


  <div class="row Terms" style="border-radius: 20px; margin-top:80px;">
        <div class="col s12 l4"style="margin-top:25px"><h2>Rules and responsibilities </h2> </div>
        <div class="col s12 l8"style="margin-top:22px ;margin-bottom:22px"><p> 1. Attendance must be taken of all volunteers at the Center. <br/>
                              2. Safety briefs must be given to all volunteers regarding the Center and its 
programs.<br/>
                              3. It is not allowed to give volunteers Bravo without the permission 
of the volunteer’s services.<br/>
                              4. It is strictly prohibited to give volunteers Tetra.<br/>
                              5. Volunteers must have supervisions while in their positions.<br/>
                              6. All volunteers must take a break for 45 minutes to 1 hour.<br/>
                              7. If a volunteer leaves before the end of the day, 
he/she must inform the Volunteer Services.
<br/> </p>  
           
        </div>
   </div>

  <div class="row" [ngStyle]="{'text-align':'center'}" style="margin-top: 55px;">
            <button class="IE-padding" mvmsDebounceClick (debounceClick)="requestVolunteers()"
              [debounceTime]="2000">{{'RequestVolunteers.Title' | translate }}</button>
      </div>
 
</div>
</div>