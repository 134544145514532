<div *ngIf="hasAccess; else noAccess" class="rewards-list row">
  <div *ngIf="rewardList && rewardList.length; else noRecords" class="rewards-list row">
    <div class="reward col l4 m6 s12" *ngFor="let item of rewardList">
      <mvms-rewards-card [reward]="item"></mvms-rewards-card>
    </div>
  </div>
</div>

<ng-template #noAccess>
  <mvms-no-access></mvms-no-access>
</ng-template>

<ng-template #noRecords>
  <mvms-no-records *ngIf="!isLoading"></mvms-no-records>
</ng-template>