<div *ngIf="(trainingList && trainingList.length) || (onlineTrainingList && onlineTrainingList.length); else noRecords" class="action-list">
  <div class="offline-trainings-box" *ngIf="trainingList">
    <div class="card-info z-depth-3" *ngFor="let training of trainingList">
      <h2 class="header">{{training.title}}</h2>
      <div class="row">
        <div class="info-box col l12">
          <div class="title">{{'DASHBOARD.event.training.Date' | translate }}</div>
          <div class="value">{{training.startDateTime | dateTimeFormat}}</div>
        </div>
      </div>
      <div class="row">
        <div class="info-box col l12">
          <div class="title">{{'DASHBOARD.event.training.Status' | translate }}</div>
          <div class="value">{{(training.status || training.status == 0) ?  TrainingStatus[training.status] : 'None'}}
          </div>
      </div>
      <div class="row">
        <div class="info-box col l12">
          <div class="title">{{'DASHBOARD.event.training.Attempts' | translate }}</div>
          <div class="value">{{training.attempts}}</div>
        </div>
      </div>
      <div class="row-flex static">
        <div class="btn-group">
          <div class="btn-group-item"  *ngIf="training.hasResult">
            <button [@expandCollapseBoolean]="training.hasResult" type="button" mat-raised-button color="accent" (click)="showViewresultDialog(training)">
              {{'DASHBOARD.event.training.ViewResult' | translate }}
            </button>
          </div>
          <div class="btn-group-item" *ngIf="training.canEnroll">
            <button  [@expandCollapseBoolean]="training.canEnroll" type="button" mat-raised-button color="accent" (click)="attendToTraining(training.id)">
              {{'DASHBOARD.event.training.Attend' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="online-trainings-box" *ngIf="onlineTrainingList">
    <div class="card-info z-depth-3" *ngFor="let training of onlineTrainingList">
      <h2 class="header">{{training.title}}</h2>
      <div class="row">
        <div class="info-box col l12">
          <div class="title">{{'DASHBOARD.event.training.StartDate' | translate }}</div>
          <div class="value">{{training.startDate | dateTimeFormat}}</div>
        </div>
      </div>
      <div class="row">
        <div class="info-box col l12">
          <div class="title">{{'DASHBOARD.event.training.EndDate' | translate }}</div>
          <div class="value">{{training.endDate | dateTimeFormat}}</div>
        </div>
      </div>
      <div class="row">
        <div class="info-box col l12">
          <div class="title">{{'DASHBOARD.event.training.Status' | translate }}</div>
          <div class="value">{{(training.status || training.status == 0) ?  TrainingStatus[training.status] : 'None'}}
          </div>
      </div>
      <div class="row">
        <div class="info-box col l12">
          <div class="title">{{'DASHBOARD.event.training.Attempts' | translate }}</div>
          <div class="value">{{training.attempt ? training.attempt : '0'}} / {{training.maxAttempts}}</div>
        </div>
      </div>
      <div class="row-flex static">
        <div class="btn-group">
          <div class="btn-group-item" *ngIf="training.hasResult">
            <button [@expandCollapseBoolean]="training.hasResult" type="button" mat-raised-button color="accent" (click)="viewOnlineTrainingResult(training)">
              {{'DASHBOARD.event.training.ViewResult' | translate }}
            </button>
          </div>
          <div class="btn-group-item" *ngIf="training.canAttend && !training.hasResult">
            <button  [@expandCollapseBoolean]="training.canAttend" type="button" mat-raised-button color="accent"
                     mvmsDebounceClick (debounceClick)="attendToOnlineTraining(training)" [debounceTime]="1500">
              {{'DASHBOARD.event.training.Attend' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div #googlemap style='width: 100%; height: 500px;'></div>
  </div>
  </div>

<ng-template #noRecords>
  <mvms-no-records *ngIf="!isLoading"></mvms-no-records>
</ng-template>
