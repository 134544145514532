import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {Token} from '../models/token';

@Injectable()
export class TokenService {

  private _token: BehaviorSubject<Token>;
  private _refreshToken: BehaviorSubject<string>;
  private _language: BehaviorSubject<string>;

  constructor() {
    this._token = <BehaviorSubject<Token>>new BehaviorSubject(new Token(sessionStorage.getItem('auth_token')));
    this._refreshToken = <BehaviorSubject<string>>new BehaviorSubject(sessionStorage.getItem('refresh_token'));
    this._language = <BehaviorSubject<string>>new BehaviorSubject(localStorage.getItem('language'));
  }

  /**
   * Get the current token.
   */
  getToken(): Token {
    const token = this._token.getValue();
    return (token && token.token) ? token : null;
  }

  getLanguage(): string {
    const language = localStorage.getItem('language');
    return language  ? language : 'en';
  }

  /**
   * Get the current token.
   */
  getRefreshToken(): string {
    const token = this._refreshToken.getValue();
    return token;
  }

  /**
   * Returns an stream of tokens.
   */
  getTokenStream(): Observable<Token> {
    return this._token.asObservable();
  }

  /**
   * Update the current token.
   */
  setToken(token: string) {
    this._token.next(new Token(token));
    sessionStorage.setItem('auth_token', token);
  }

  setLanguage(lang: string) {
    console.log(lang);
    this._language.next(lang);
    localStorage.setItem('language', lang);
  }

  /**
   * Update the refresh token.
   */
  setRefreshToken(token: string) {
    this._refreshToken.next(token);
    sessionStorage.setItem('refresh_token', token);
  }

  /**
   * Remove the current token.
   */
  removeTokens() {
    this._refreshToken.next(null);
    sessionStorage.removeItem('refresh_token');
    this._token.next(null);
    sessionStorage.removeItem('auth_token');
  }
}
