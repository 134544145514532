import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TaskPriority, TaskStatus} from '../../../../../../constants/constant';
import {TaskListItemModel} from '../../task.model';
import {MvmsAnimations} from '../../../../../../animations/animations';
import {MatDialog} from '@angular/material/dialog';
import {ViewTaskResultComponent} from '../../view-task-result/view-task-result.component';
import {DashboardDataService} from '../../../../dashboard-data.service';

@Component({
  selector: 'mvms-scrumboard-board-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: MvmsAnimations
})
export class ScrumboardBoardCardComponent implements OnInit {
  @Input() task: TaskListItemModel;
  public TaskPriority = TaskPriority;
  public TaskStatus = TaskStatus;

  constructor(private matDialog: MatDialog, protected dashboardDataService: DashboardDataService) {
  }

  ngOnInit() {

  }

  updateTask(id) {

    this.dashboardDataService.getTask(id).subscribe((task) => {
      const dialogRef = this.matDialog.open(ViewTaskResultComponent, {panelClass: 'view-result'});
      dialogRef.componentInstance.model = task;
      dialogRef.componentInstance.taskId = id;
      dialogRef.componentInstance.taskStatus = task.status;
      dialogRef.componentInstance.taskComments = task.assigneeComment;
    });
  }
}
