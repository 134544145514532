<div class="reward-card">
  <mvms-gradient-border [disabled]="!reward.isUnlocked"></mvms-gradient-border>
  <div class="reward-header">
    <div class="icon">
      <mvms-image *ngIf="!reward.isUnlocked" [imageId]="reward.iconId" [isAvatar]="false" class="locked-badge-img"></mvms-image>
      <mvms-image *ngIf="reward.isUnlocked" [imageId]="reward.iconId" [isAvatar]="false"></mvms-image>
    </div>
    <h3 class="title" [title]="reward.title">{{reward.title}}</h3>
  </div>
    <div class="reward-details" [class.lock]="!reward.isUnlocked">
    <p class="title" *ngIf="reward.volunteeringHours">{{'DASHBOARD.Rewards.VolunteeringHours' | translate }}<span class="value">{{reward.volunteeringHours}}</span></p>
    <p class="title" *ngIf="reward.tasksCompleted">{{'DASHBOARD.Rewards.TasksCompleted' | translate }}<span class="value">{{reward.tasksCompleted}}</span></p>
    <p class="title" *ngIf="reward.deadlinesMissed">{{'DASHBOARD.Rewards.DeadlinesMissed' | translate }}<span class="value">{{reward.deadlinesMissed}}</span></p>
    <p class="title" *ngIf="reward.averageEvaluationScore">{{'DASHBOARD.Rewards.AverageEvaluationScore' | translate }}<span class="value">{{reward.averageEvaluationScore}} {{'DASHBOARD.Rewards.outOf' | translate }} 5</span></p>
  </div>
</div>