import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../services/content.service';
import { HomepageSectionTypeEnum, HomepageSectionForPortal } from '../../services/models/homepage-section-for-portal.model';

@Component({
  selector: 'mvms-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})

export class AboutUsComponent implements OnInit {
  aboutSection: HomepageSectionForPortal;

  constructor(public contentService: ContentService) { }

  ngOnInit(): void {
    this.contentService.getHomepageSection(HomepageSectionTypeEnum.about)
      .subscribe(section => this.aboutSection = section);
  }
}
