import { Injectable } from '@angular/core';

import { TranslateService } from '../../../node_modules/@ngx-translate/core';
import { LANGUAGES } from '../constants/constant';

@Injectable()
export class LanguageService {
    get isCurrentLanguageArabic(): boolean {
        return this.translateService.currentLang === LANGUAGES.arabic;
    }

    constructor(private translateService: TranslateService) { }

}
