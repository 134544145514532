<div *ngIf="hasAccess; else noAccess" class="">
    <mat-list *ngIf="rewardList && rewardList.length; else noRecords" class="">
      <div *ngFor="let item of rewardList">
        <mvms-badge-item [reward]="item" *ngIf="item.isUnlocked"></mvms-badge-item>
      </div>
    </mat-list>
  </div>
  
  <ng-template #noAccess>
    <mvms-no-access></mvms-no-access>
  </ng-template>
  
  <ng-template #noRecords>
    <mvms-no-records *ngIf="!isLoading"></mvms-no-records>
  </ng-template>