import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-requestvolunteers',
  templateUrl: './requestvolunteers.component.html',
  styleUrls: ['./requestvolunteers.component.scss']
})
export class RequestvolunteersComponent implements OnInit {
  isCurrentLanguageArabic: boolean;
  constructor(
    public translate: TranslateService,private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
  }
    requestVolunteers(){
      window.open('https://forms.ithra.com/f/request-volunteers','_blank')
    
  }

}
