import {NgProgress} from 'ngx-progressbar';
import {Observable} from 'rxjs/Observable';
import {appInjector} from '../app-injector';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

export function ProgressBar() {
  return function (target: any, key: string, descriptor: any) {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: any[]) {
      const result = originalMethod.apply(this, args); 
      const ngProgress = appInjector().get(NgProgress);
      ngProgress.start();
      return (result as Observable<any>).do(() => {
        ngProgress.done();
      }).catch(error => {
        ngProgress.done();
        return Observable.throw(error);
      });
    };

    return descriptor;
  };
}
