import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroupDirective, Validators} from '@angular/forms';

import { Subject } from 'rxjs/Subject';
import { ParticipationHours, RegexpPattern } from '../../../../constants/constant';
import { CreateUserService } from '../../../../services/create-user.service';
import { UserProfileDataService } from '../user-profile-data.service';
import { SkillsProfileModel } from '../user-profile.model';
import { RegexValidator, OrderedValidator } from '../../../shared/utils';
import { AnyRecord } from 'dns';

@Component({
  selector: 'mvms-skills-experiences',
  templateUrl: './skills-experiences.component.html',
  styleUrls: ['./skills-experiences.component.scss']
})
export class SkillsExperiencesComponent implements OnInit, OnDestroy {
  public skillsExperincesForm = this.fb.group({
    hasExperience: ['', [Validators.required]],
    experienceLocation: [''],
    experienceType: [''],
    skills: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
    joiningReason: ['', [Validators.required]],
    participationDays: ['', [Validators.required]],
    participationTime: ['', [Validators.required]],
    maxHoursPerDay: ['', [Validators.required]],
    hasDiseases: ['', []],
    // diseases: ['', [Validators.required, Validators.pattern(new RegExp(RegexpPattern.enumeration))]],
    validationLink: [null, [OrderedValidator.call(this, [RegexValidator.call(this, new RegExp(RegexpPattern.https), { 'https': true }), RegexValidator.call(this, new RegExp(RegexpPattern.link), { 'link': true })])]],
  });

  public participationHoursType = ParticipationHours;
  public model: SkillsProfileModel;
  public reasonsList:any;
  public skillsList:any;
  public participationDayType:any;
  public participationTimeType:any;
  public isDataLoaded = false;
  public formChanged = false;
  private componentDestroyed: Subject<any> = new Subject();

  isLoading: boolean;
  @ViewChild('form') form: FormGroupDirective;

  constructor(
    private fb: FormBuilder,
    private createUserService: CreateUserService,
    private userProfileDataService: UserProfileDataService
  ) { }

  ngOnInit() {
    this.createUserService.getJoiningReason().takeUntil(this.componentDestroyed).subscribe(reasons => this.reasonsList = reasons);
    this.createUserService.getSkills().takeUntil(this.componentDestroyed).subscribe(skills => this.skillsList = skills);
    this.createUserService.getDayOfWeek().takeUntil(this.componentDestroyed).subscribe(dayOfWeek => this.participationDayType = dayOfWeek);
    this.createUserService.getParticipationTime().takeUntil(this.componentDestroyed).subscribe(participationTime => this.participationTimeType = participationTime);

    this.userProfileDataService.getSkillsProfile().subscribe((model) => {
      this.model = model;
      this.isDataLoaded = true;
      this.patchModelInForms();
      this.skillsExperincesForm.valueChanges.takeUntil(this.componentDestroyed).subscribe(() => {
        this.formChanged = true;
      });
    });

    /*this.skillsExperincesForm.get('hasDiseases').valueChanges.takeUntil(this.componentDestroyed).subscribe((status: number) => {
      if (status) {
        this.skillsExperincesForm.get('diseases').setValidators([Validators.required, Validators.pattern(new RegExp(RegexpPattern.enumeration))]);
        this.skillsExperincesForm.controls['diseases'].updateValueAndValidity();
      } else {
        this.clearValidators('skillsExperincesForm', ['diseases']);
      }
    });*/
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  clearValidators(form, fieldsArr:any) {
    for (let i = 0; i < fieldsArr.length; i++) {
      this[form].get(fieldsArr[i]).clearValidators();
      this[form].get(fieldsArr[i]).updateValueAndValidity();
    }
  }

  getErrorMessage() {
    return this.skillsExperincesForm.controls.validationLink.hasError('link') ? 'link' : 'https';
  }

  public patchModelInForms() {
    this.skillsExperincesForm.patchValue(this.model);
    this.skillsExperincesForm.controls.validationLink.markAsTouched();
    this.skillsExperincesForm.get('validationLink').updateValueAndValidity();
    this.skillsExperincesForm.updateValueAndValidity();
  }

  compareWithFn(item1:any, item2:any) {
    return item1 && item2 ? item1.id === item2.id : item1 === item2;
  }

  public update() {
    if (this.skillsExperincesForm.valid) {
      this.isLoading = true;

      const model = {
        skills: {
          hasExperience: this.skillsExperincesForm.value.hasExperience,
          experienceLocation: this.skillsExperincesForm.value.experienceLocation,
          experienceType: this.skillsExperincesForm.value.experienceType,
          skillIds: this.skillsExperincesForm.value.skills.map(i => i.id),
          validationLink: this.skillsExperincesForm.value.validationLink === '' ? null : this.skillsExperincesForm.value.validationLink
        },
        misc: {
          joiningReasonId: this.skillsExperincesForm.value.joiningReason.id,
          participationDays: this.skillsExperincesForm.value.participationDays,
          participationTime: this.skillsExperincesForm.value.participationTime,
          maxHoursPerDay: this.skillsExperincesForm.value.maxHoursPerDay,
          hasDiseases: this.skillsExperincesForm.value.hasDiseases,
          diseases: this.skillsExperincesForm.value.hasDiseases ? 'Yes' : null
        }
      };
      this.userProfileDataService.updateSkillsProfile(model)
        .subscribe(
          () => {
            //this.skillsExperincesForm.controls['diseases'].setValue(model.misc.diseases);

            this.isLoading = false;
            this.formChanged = false;
          },
          () => {
            this.isLoading = false;
            this.formChanged = false;
          }
        );
    }
  }

  reset() {
    this.patchModelInForms();
    this.form.resetForm(this.model);
    this.formChanged = false;
  }
}
