<div class="form-container" >
  <form class='form' [formGroup]="accountForm" (ngSubmit)="updateForm()" #form="ngForm">
      <div class="form-section">
        <h2 class="header">{{'DASHBOARD.event.profile.Account' | translate }}</h2>
        <div class="row">
          <h4 class="title col l4">{{'DASHBOARD.event.profile.AccountTab.Email' | translate }}</h4>
          <mat-form-field class="col l4" floatLabel="never">
            <input matInput formControlName="email" type='email'  [placeholder]="'DASHBOARD.event.profile.AccountTab.Email' | translate" (blur)="checkEmail()" >
            <mat-error *ngIf="accountForm.controls.email.hasError('notUnique')">
              {{'SHARED.Errors.EmailExist' | translate }}
            </mat-error>
            <mat-error *ngIf="accountForm.controls.email.hasError('pattern')">
              {{'SHARED.Errors.emailInvalid' | translate }}
            </mat-error>
            <mat-error *ngIf="accountForm.controls.email.hasError('required')">
              {{'SHARED.Errors.emptyField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <h4 class="title col l4">{{'DASHBOARD.event.profile.AccountTab.Mobile' | translate }}</h4>
          <mat-form-field class="phone col l4" floatLabel="never">
            <input  class="phone ltr" matInput formControlName="phoneNo" type='text' [placeholder]="'SHARED.Placeholders.phone' | translate" (blur)="checkPhoneOnBlur()">
            <mat-error *ngIf="accountForm.controls.phoneNo.hasError('notUnique')">
              {{'SHARED.Errors.PhoneExist' | translate }}
            </mat-error>
            <mat-error *ngIf="accountForm.controls.phoneNo.hasError('notValid')">
            {{'SHARED.Errors.PhoneNotValid' | translate }}
          </mat-error>
            <mat-error *ngIf="accountForm.controls.phoneNo.hasError('equal')">
              {{'SHARED.Errors.equalNumber' | translate }}
            </mat-error>
            <mat-error *ngIf="accountForm.controls.phoneNo.hasError('required')">
              {{'SHARED.Errors.emptyField' | translate }}
            </mat-error>
            <mat-error *ngIf="accountForm.controls.phoneNo.hasError('pattern')">
              {{'SHARED.Errors.PhoneNotValid' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <h4 class="title col l4">{{'DASHBOARD.event.profile.AccountTab.Password' | translate }}</h4>
          <mat-form-field class="col l4 big-error-input" floatLabel="never">
            <input matInput formControlName="currentPassword" [type]="showPassword3.checked ? 'text' : 'password'" [placeholder]="'DASHBOARD.event.profile.AccountTab.Password' | translate" mvmsBlockPaste autocomplete="new-password">
            <mat-button-toggle #showPassword3 matSuffix><mat-icon *ngIf="showPassword3.checked">visibility</mat-icon><mat-icon *ngIf="showPassword3.checked===false">visibility_off</mat-icon></mat-button-toggle> 

            <mat-error *ngIf="accountForm.controls.currentPassword.hasError('pattern') || accountForm.controls.currentPassword.hasError('minlength') || accountForm.controls.currentPassword.hasError('maxlength')" >
              {{'SHARED.Errors.passwordMust' | translate }}
            </mat-error>
            <mat-error *ngIf="accountForm.controls.currentPassword.hasError('required')">
              {{'SHARED.Errors.emptyField' | translate }}
            </mat-error>
            <mat-error *ngIf="accountForm.controls.currentPassword.hasError('invalid')">
              {{'SHARED.Errors.PasswordInvalid' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <h4 class="title col l4" [class.disabled]="accountForm.controls.newPassword.disabled">{{'DASHBOARD.event.profile.AccountTab.NewPassword' | translate }}</h4>
          <mat-form-field class="col l4 big-error-input" floatLabel="never" [class.disabled]="accountForm.controls.newPassword.disabled">
            <input matInput formControlName="newPassword"  [type]="showPassword4.checked ? 'text' : 'password'" [placeholder]="'DASHBOARD.event.profile.AccountTab.NewPassword' | translate" mvmsBlockPaste>
            <mat-button-toggle #showPassword4 matSuffix><mat-icon *ngIf="showPassword4.checked">visibility</mat-icon><mat-icon *ngIf="showPassword4.checked===false">visibility_off</mat-icon></mat-button-toggle> 
            <mat-error *ngIf="accountForm.controls.newPassword.hasError('pattern') || accountForm.controls.newPassword.hasError('minlength') || accountForm.controls.newPassword.hasError('maxlength')">
              {{'SHARED.Errors.passwordMust' | translate }}
            </mat-error>
            <mat-error *ngIf="accountForm.controls.newPassword.hasError('required')">
              {{'SHARED.Errors.emptyField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <h4 class="title col l4" [class.disabled]="accountForm.controls.newPassword.disabled">{{'DASHBOARD.event.profile.AccountTab.PasswordConfirmation' | translate }}</h4>
          <mat-form-field class="col l4" floatLabel="never" [class.disabled]="accountForm.controls.newPassword.disabled">
            <input matInput formControlName="passwordConfirmation"  [type]="showPassword5.checked ? 'text' : 'password'" [placeholder]="'DASHBOARD.event.profile.AccountTab.PasswordConfirmation' | translate" mvmsBlockPaste>
            <mat-button-toggle #showPassword5 matSuffix><mat-icon *ngIf="showPassword5.checked">visibility</mat-icon><mat-icon *ngIf="showPassword5.checked===false">visibility_off</mat-icon></mat-button-toggle> 
            <mat-hint *ngIf="accountForm.hasError('isMatch')  && accountForm['controls']['currentPassword'].touched && accountForm['controls']['passwordConfirmation'].touched">
              {{'SHARED.Errors.PasswordNotMatch' | translate }}
            </mat-hint>
            <mat-error *ngIf="accountForm.controls.passwordConfirmation.hasError('pattern')">
              {{'SHARED.Errors.passwordMust' | translate }}
            </mat-error>
            <mat-error *ngIf="accountForm.controls.passwordConfirmation.hasError('required')">
              {{'SHARED.Errors.emptyField' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
      <div class="btn-group col l12">
        <div class="btn-group-item">
          <button type="submit"  mvmsDebounceClick (debounceClick)="form.onSubmit($event)" [debounceTime]="1500"
                  [disabled]="accountForm.controls.email.value === initialEmail && accountForm.controls.phoneNo.value === initialPhoneNo && !accountForm.controls.currentPassword.value" mat-raised-button class='capitalize IE-padding' color='accent'>{{'SHARED.Button.Save' | translate }}</button>
        </div>
        <div class="btn-group-item">
          <button type="button" mat-raised-button class='capitalize IE-padding' color='accent' (click)="reset()">{{'SHARED.Button.Cancel' | translate }}</button>
        </div>
      </div>
  </div>
    </form>
</div>

