<div class="training-quiz  z-depth-2">
  <ng-container *ngIf="isLoaded">
    <div class="result z-depth-1" *ngIf="result">
      <div class="main">
        <div class="row">
          <div class="title col l6">{{'DASHBOARD.event.training.Date' | translate }}</div>
          <div class="value col l6">
            <div class="data">{{result.submissionDate | dateTimeFormat}}</div>
            <div class="divider"></div>
          </div>
        </div>
        <div class="row">
          <div class="title col l6">{{'DASHBOARD.event.training.Status' | translate }}</div>
          <div class="value col l6">
            <div class="data">{{ result.enrollmentStatusText }}</div>
            <div class="divider"></div>
          </div>
        </div>
        <div class="row">
          <div class="title col l6">{{'DASHBOARD.event.training.Score' | translate }}</div>
          <div class="value col l6">
            <div class="data">{{result.score}}</div>
            <div class="divider"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="questions-list">
      <div class="question-box z-depth-1" *ngFor="let question of questions">
        <p class="question">{{question.body}}</p>
        <ul class="answers-list">
          <li class="answer" *ngFor="let answer of question.answers">
            <ng-container *ngIf="answer.hasOwnProperty('isAnsweredCorrectly')">
              <mat-icon color="primary" *ngIf="answer?.isAnsweredCorrectly">done</mat-icon>
              <mat-icon color="warn" *ngIf="!answer?.isAnsweredCorrectly">close</mat-icon>
            </ng-container>
            <div class="checkbox-container" [ngClass]="{'checkbox-container_right': answer.isChecked && answer.hasOwnProperty('isAnsweredCorrectly') && answer?.isAnsweredCorrectly,
               'checkbox-container_fail' :  answer.isChecked && answer.hasOwnProperty('isAnsweredCorrectly') && !answer?.isAnsweredCorrectly}">
              <div class="checkbox">
                <mat-checkbox color="primary" [disabled]="answer.hasOwnProperty('isAnsweredCorrectly')" (change)="onChangeAnswer($event, question)"
                  [(ngModel)]="answer.isChecked"></mat-checkbox>
              </div>
              <p class="text">{{answer.label}}</p>
            </div>
          </li>
        </ul>
        <mat-error *ngIf="!question.isAnswered">
          {{'SHARED.Errors.onlineTrainingIsNotAnswered' | translate }}
        </mat-error>
      </div>
    </div>
    <div class="btn-group">
      <div class="btn-group-item">
        <button type="button" mat-raised-button color="accent" (click)="close()">
          {{'SHARED.Button.Close' | translate }}
        </button>
      </div>
      <div class="btn-group-item">
        <button type="button" mat-raised-button color="accent" [disabled]="dashboardService.currentTraining.maxAttempts && result.attempt >= dashboardService.currentTraining.maxAttempts"
          (click)="retakeTest()" *ngIf="dashboardService.currentTraining && result">
          {{'DASHBOARD.event.training.RetakeTest' | translate }}
        </button>
        <button type="button" mat-raised-button color="accent" mvmsDebounceClick (debounceClick)="submitAnswers()" [debounceTime]="1500"
          *ngIf="!result">
          {{'DASHBOARD.event.training.submitView' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
