import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnquirieModel} from '../enquiry/enquiries.model';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {BASE_URL} from '../../../constants/constant';

@Injectable()
export class RewardsDataService {

  private urlVolunteer = `${BASE_URL}api/public/volunteer`;

  public emitRefreshList: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {}

  getList(): Observable<any> {
    return this.http.get<any>(`${this.urlVolunteer}/achievements`);
  }

  // getItem(id): Observable<EnquirieModel> {
  //   return this.http.get<EnquirieModel>(`${this.url}/${id}`);
  // }

}
