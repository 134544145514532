import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { EditorConfig } from '../../../../constants/constant';
import { EnquiriesDataService } from '../enquiries-data.service';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'mvms-create-enquiry',
  templateUrl: './create-enquiry.component.html',
  styleUrls: ['./create-enquiry.component.scss']
})
export class CreateEnquiryComponent implements OnInit {
  ckEditorConfig = EditorConfig;

  evaluationData = null;

  validate = false;

  isCurrentLanguageArabic: boolean;

  createForm = this.fb.group({
    subject: ['', [Validators.required, Validators.maxLength(50)]],
    body: ['', [Validators.required, Validators.maxLength(500)]],
  });

  constructor(
    private dialogRef: MatDialogRef<CreateEnquiryComponent>,
    private enquiriesDataService: EnquiriesDataService,
    private fb: FormBuilder,
    private languageService: LanguageService) {  }

  ngOnInit() {
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
    this.ckEditorConfig.contentsLangDirection = this.languageService.isCurrentLanguageArabic ? 'rtl' : 'ltr';

    if (this.evaluationData) {
      this.createForm.patchValue({ subject: `Evaluation escalation: ${this.evaluationData.event} - ${this.evaluationData.personal}` });
      this.createForm.get('subject').disable();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  create() {
    if (this.createForm.valid) {
      const model = this.createForm.value;
      model.subject = this.createForm.controls.subject.value;
      this.enquiriesDataService.create(model).subscribe(() => {
        this.closeDialog();
        this.enquiriesDataService.emitRefreshList.next(true);
      });
    } else {
      this.createForm.controls['subject'].markAsTouched();
      this.validate = true;
    }
  }

}
