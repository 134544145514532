import {Component, OnInit} from '@angular/core';
import {TaskModel} from '../task.model';
import { TaskPriority, TaskStatus } from '../../../../../constants/constant';
import {DashboardDataService} from '../../../dashboard-data.service';
import {MatDialogRef} from '@angular/material/dialog';
import {DashboardService} from '../../../dashboard.service';
import {FormBuilder, Validators} from '@angular/forms';
import {Subject} from 'rxjs/Subject';
import { LanguageService } from '../../../../../services/language.service';

@Component({
  selector: 'mvms-view-task-result',
  templateUrl: './view-task-result.component.html',
  styleUrls: ['./view-task-result.component.scss']
})

export class ViewTaskResultComponent implements OnInit {
  public model: TaskModel;
  public taskId: string;
  public TaskStatus = TaskStatus;
  public TaskPriority = TaskPriority;
  public taskStatus: any;
  public taskComments = '';
  private componentDestroyed: Subject<any> = new Subject();
  public taskForm = this.fb.group({
    comment: ['', [Validators.maxLength(1000)]]
  });
  public isCurrentLanguageArabic: boolean;
  constructor(private fb: FormBuilder, protected  dashboardService: DashboardService,
              protected  dashboardDataService: DashboardDataService,
              public dialogRef: MatDialogRef<ViewTaskResultComponent>,
              private languageService: LanguageService) {
  }

  ngOnInit() {
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
  }

  update() {
    const model = {
      id: this.model.id,
      status: this.taskStatus,
      assigneeComment: this.taskComments
    };
    this.dashboardDataService.updateTask(model).subscribe(() => {
      this.dashboardService.doRefreshTaskList();
      this.dialogRef.close();
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
