import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BASE_URL } from '../../../constants/constant';
import { ContentService } from '../../../services/content.service';
import { AuthenticationService } from '../../../auth/services/authentication.service';
import { LoginDialogService } from '../../account/login/login.dialog.service';
import { TokenService } from '../../../auth/services/token.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { MvmsAnimations } from '../../../animations/animations';
import { DepartmentResources } from '../../../services/models/department-resources.model';
import { LanguageService } from '../../../services/language.service';
import { Location } from '@angular/common';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { HeaderDataService } from './header-data.service';
import { Router } from '@angular/router';
import {CodeofconductDialogService} from '../../code-of-conduct/codeofconduct.dialog.service'

declare var $: any;

@Component({
  selector: 'mvms-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: MvmsAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  public user: any;
  public BASE_URL = BASE_URL;
  private menuEdge = 'left';

  departmentResources: DepartmentResources;
  isCurrentLanguageArabic: boolean;

  private hubConnection: HubConnection;
  public notifications: any[];
  counter: number = 0;

  constructor(
    public translate: TranslateService,
    public tokenService: TokenService,
    private contentService: ContentService,
    public currentUserService: CurrentUserService,
    public authService: AuthenticationService,
    public dashboardService: DashboardService,
    public loginDialogService: LoginDialogService,
    public cocDialogService: CodeofconductDialogService,
    public headerDataService: HeaderDataService,
    private languageService: LanguageService,
    private router: Router,
    private location: Location) {
    translate.addLangs(['en', 'ar']);
    const hash = this.location.path(true).split('#')[1];
    if (['en', 'ar'].indexOf(hash) >= 0) {
      translate.setDefaultLang(hash);
      this.tokenService.setLanguage(hash);
      this.location.go(this.location.path(false));
    } else {
      translate.setDefaultLang(tokenService.getLanguage() || 'en');
    }
    translate.use(this.tokenService.getLanguage());
    this.loginDialogService.afterClosed.subscribe(result => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    this.contentService.departmentResources$.subscribe(
      r => this.departmentResources = r
    );

    this.currentUserService.user$.subscribe((user) => {
      this.user = user;
      $('.button-collapse').sideNav();

      if (user) {
        this.getNotifications(user.volunteerId);

        //iniate and connection signal R connection.
        this.establishSignalRConnection();
      }
      else
        this.stopSignalRConnection();
    });

    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
    this.menuEdge = this.languageService.isCurrentLanguageArabic ? 'right' : 'left';

  }

  isHome() {
    if (window.location.href.includes('dashboard')) {
      return false;
    }
    else {return true; }
  }

  establishSignalRConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${BASE_URL}notify`)
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('SignalR Connection started'))
      .catch(err => {
        console.log('Error while starting connection: ' + err)
      })

    this.hubConnection.on('ReceiveMessage', (volunteerId: string) => {
      //console.log(volunteerId);
      if (this.user.volunteerId === volunteerId) {
        this.getNotifications(volunteerId)
      };
    });
  }

  stopSignalRConnection() {
    this.hubConnection
      .stop()
      .then(() => console.log('SignalR Connection stoped'))
      .catch(err => {
        console.log('Error while closing connection: ' + err)
      })
  }

  getNotifications(volunteerId: string) {
    this.headerDataService.getNotificationsList({ volunteerId })
      .subscribe(data => {
        this.notifications = data;
        this.counter = this.notifications.filter(item => item.isAcknowledged == false).length;
      });
  }

  markAknowledged() {
    this.counter = 0;
    this.headerDataService.markAknowledged({ id: this.user.volunteerId }).subscribe();
    this.notifications.forEach(function (item) {
      item.isAcknowledged = true;
    })
  }
  
redirectTo(notification){
  this.router.navigate([`${(notification.redirectUrl.replace(/ /g, ''))}`]);
}
  showLoginDialog() {
    this.loginDialogService.open();
  }
  showCOCDialog(){
    this.cocDialogService.open();
  }

  logout() {
    this.authService.logout();
    this.user = null;
  }

  setLanguage(lang) {
    this.translate.use(lang);
    this.tokenService.setLanguage(lang);
    this.reloadPage();
  }

  reloadPage() {
    window.location.reload();
  }
}
