import {Component, OnInit, OnDestroy} from '@angular/core';
import {MvmsAnimations} from '../../../animations/animations';
import {DashboardService} from '../dashboard.service';
import {AuthenticationService} from '../../../auth/services/authentication.service';
import { Subscription } from '../../../../../node_modules/rxjs';
import { EnquiriesDataService } from '../enquiry/enquiries-data.service';

@Component({
  selector: 'mvms-nav-panel',
  templateUrl: './nav-panel.component.html',
  styleUrls: ['./nav-panel.component.scss'],
  animations: MvmsAnimations
})
export class NavPanelComponent implements OnInit, OnDestroy {

  subscribtions: Subscription[] = [];
  unread = 0;
  constructor(public  dashboardService: DashboardService,
              public authService: AuthenticationService,
              private enquiriesDataService: EnquiriesDataService) {

                this.subscribtions.push(
                  this.enquiriesDataService.emitRefreshList
                    .subscribe(
                      () => this.refreshUnread()
                    )
                );
  }

  ngOnInit() {
    this.refreshUnread();
  }
  ngOnDestroy() {
    this.subscribtions.forEach(s => s.unsubscribe());
  }

  logout() {
    this.authService.logout();
  }

  removeOverlay() {
    $('#sidenav-overlay').trigger('click');
  }

  refreshUnread() {

    this.subscribtions.push(
      this.enquiriesDataService.getList({
        pageNo: 1,
        pageSize: 100,
        searchText: '',
        readFilter: 'Unread',
        answerFilter: 'all'
      })
        .subscribe(data => {
          this.unread = data.totalCount;
        },
          // () => isLoading = false
        )
    );

  }
}

