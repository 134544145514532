import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JoinFormsComponent } from './components/join-forms/join-forms.component';
import { MedicalComponent } from './components/dashboard/events/medical/medical.component';
import { TrainingComponent } from './components/dashboard/events/training/training.component';
import { UserProfileComponent } from './components/dashboard/user-profile/user-profile.component';
import { AboutUsComponent } from './components/aboutus/aboutus.component';
import { SkillsExperiencesComponent } from './components/dashboard/user-profile/skills-experiences/skills-experiences.component';
import { PersonalInfoComponent } from './components/dashboard/user-profile/personal-info/personal-info.component';
import { AccountSettingsComponent } from './components/dashboard/user-profile/account-settings/account-settings.component';
import { RecoverPasswordComponent } from './components/account/recover-password/recover-password.component';
import { ContentComponent } from './components/content/content.component';
import { NewsComponent } from './components/news/news.component';
import { EventsComponent } from './components/dashboard/events/events.component';
import { EventComponent } from './components/dashboard/events/event/event.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OpportunitiesComponent } from './components/opportunities/opportunities.component';
import { TaskKanbanComponent } from './components/dashboard/events/tasks/task-kanban/task-kanban.component';
import { MyDashboardComponent } from './components/dashboard/my-dashboard/my-dashboard.component';
import { EventOpportunitiesDetailComponent } from './components/opportunities/event-opportunities-detail/event-opportunities-detail.component';
import { EnquiryComponent } from './components/dashboard/enquiry/enquiry.component';
import { EvaluationComponent } from './components/dashboard/events/evaluation/evaluation.component';
import { RewardsComponent } from './components/dashboard/rewards/rewards.component';
import { ChangePasswordContainerComponent } from './components/account/change-password-container/change-password-container.component';
import { TrainingQuizComponent } from './components/dashboard/events/training/online-training/training-quiz/training-quiz.component';
import { OnlineTrainingComponent } from './components/dashboard/events/training/online-training/online-training.component';
import { AuthGuard } from './auth/guard/auth.guard';
import { AttendanceComponent } from './components/dashboard/attendance/attendance.component';
import { CodeOfConductComponent } from './components/code-of-conduct/code-of-conduct.component';
import { AwardComponent } from './components/award/award.component';
import { RequestvolunteersComponent } from './components/requestvolunteers/requestvolunteers.component';

const routes: Routes = [
  {
    // path: ':lang', component: LanguageComponent,
    // children: [
    //   {
        path: '', component: ContentComponent, children: [
          { path: '', pathMatch: 'full', redirectTo: 'about-us' },
          { path: 'opportunities', component: OpportunitiesComponent },
          { path: 'opportunities/:id', component: EventOpportunitiesDetailComponent },
          { path: 'news', component: NewsComponent },
          { path: 'join-us', canActivate: [AuthGuard], component: JoinFormsComponent },
          { path: 'account/recover-password', component: RecoverPasswordComponent },
          { path: 'account/change-password', component: ChangePasswordContainerComponent },
          { path: 'about-us', component: AboutUsComponent },
          { path:'award', component:AwardComponent },
          { path:'request-volunteers', component:RequestvolunteersComponent },
        ]
      },
      {
        path: '', component: ContentComponent, data: { isSimple: true }, children: [
          { path: 'code-of-conduct', component: CodeOfConductComponent },
        ]
      },
      {
        path: 'dashboard', component: DashboardComponent, children: [
          { path: '', pathMatch: 'full', redirectTo: 'myDashboard' },
          { path: 'myDashboard', component: MyDashboardComponent },
          { path: 'myEvents', component: EventsComponent },
          { path: 'myEvents/:id', component: EventComponent },
          { path: 'myEvents/:id/training', component: TrainingComponent },
          { path: 'myEvents/:id/online-training/:trainingId/quiz', component: TrainingQuizComponent },
          { path: 'myEvents/:id/online-training/:trainingId', component: OnlineTrainingComponent },
          { path: 'myEvents/:id/medical', component: MedicalComponent },
          { path: 'myEvents/:id/tasks', component: TaskKanbanComponent },
          { path: 'myEvents/:id/evaluation', component: EvaluationComponent },
          { path: 'attendance', component: AttendanceComponent },
          { path: 'enquiries', component: EnquiryComponent },
          { path: 'rewards', component: RewardsComponent },
          {
            path: 'profile', component: UserProfileComponent, children: [
              { path: '', pathMatch: 'full', redirectTo: 'personal' },
              { path: 'personal', component: PersonalInfoComponent },
              { path: 'skills', component: SkillsExperiencesComponent },
              { path: 'account', component: AccountSettingsComponent },
              { path: '**', redirectTo: 'personal', pathMatch: 'full' }
            ]
          },
          { path: '**', redirectTo: 'myEvents', pathMatch: 'full' }
        ]
      },
      { path: '**', redirectTo: 'about-us', pathMatch: 'full' }
  //   ]
  // },
  // { path: '**', redirectTo: '/en/about-us', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
