import { Component, OnInit, OnDestroy } from '@angular/core';
import { RewardsDataService } from '../rewards-data.service';
import { RewardModel } from '../reward.model';
import { Subscription } from '../../../../../../node_modules/rxjs';
import { CurrentUserService } from '../../../../services/current-user.service';

@Component({
  selector: 'mvms-rewards-list',
  templateUrl: './rewards-list.component.html',
  styleUrls: ['./rewards-list.component.scss']
})
export class RewardsListComponent implements OnInit, OnDestroy {
  public rewardList: RewardModel[];
  public hasAccess: boolean;

  isLoading: boolean;

  subscription: Subscription = Subscription.EMPTY;

  constructor(
    public rewardsDataService: RewardsDataService,
    private currentUserService: CurrentUserService) { }

  ngOnInit() {
   this.loadRewardsList();
   this.currentUserService.getCurrentUser().subscribe(res => {
    this.hasAccess = res.isAccepted;
  });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loadRewardsList() {
    this.isLoading = true;

    this.rewardsDataService.getList()
      .subscribe(rewardList => {
        this.rewardList = rewardList;

        this.isLoading = false;
      },
        () => this.isLoading = false);
  }

}
