import {Component, Input, OnInit} from '@angular/core';
import {RewardModel} from '../reward.model';

@Component({
  selector: 'mvms-rewards-card',
  templateUrl: './rewards-card.component.html',
  styleUrls: ['./rewards-card.component.scss']
})
export class RewardsCardComponent implements OnInit {
  @Input() reward: RewardModel;

  constructor() {
  }

  ngOnInit() {
  }

  downloadBadge() {

  }

  printBadge() {

  }

}
