import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../constants/constant';
import { Observable } from 'rxjs/Observable';
import { EventModel } from './events/events.model';
import { OnlineTrainingModel, OnlineTrainingQuestionModel, OnlineTrainingResultModel, TrainingModel } from './events/training/training.model';
import { MedicalModel } from './events/medical/medical.model';
import { IResponseModel } from '../../../types';
import { TaskModel } from './events/tasks/task.model';
import { DashboardCountModel } from './dashboard.model';
import { Toast, ToasterMessagesTypes } from '../../decorators/toast.decorator';
import { ProgressBar } from '../../decorators/progress-bar.decorator';
import { MyTasksAndEvents } from './my-dashboard/my-events-tasks.model';


@Injectable()
export class DashboardDataService {

  private urlEvent = `${BASE_URL}api/public/Event`;
  private urlVolunteer = `${BASE_URL}api/public/volunteer`;
  private urlOnlineTraining = `${BASE_URL}api/PublicOnlineTraining`;

  constructor(private http: HttpClient) {
  }

  getEventsList(filterParam): Observable<any> {
    return this.http.post<any>(`${this.urlEvent}/list`, filterParam);
  }

  getEvent(id): Observable<EventModel> {
    return this.http.get<EventModel>(`${this.urlEvent}/${id}`);
  }

  getEventsTasks(eventCount): Observable<MyTasksAndEvents> {
    return this.http.post<MyTasksAndEvents>(`${this.urlVolunteer}/eventsAndTasks`, eventCount);
  }

  getDashboardCounter(): Observable<DashboardCountModel> {
    return this.http.get<DashboardCountModel>(`${this.urlVolunteer}/dashboard`);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyWithdrawn)
  withdrawEvent(params): Observable<any> {
    return this.http.post<any>(`${this.urlEvent}/withdraw`, params);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyEnrolled)
  enrollToEvent(eventId): Observable<any> {
    return this.http.post(`${this.urlEvent}/enroll`, eventId);
  }

  getOfflineTrainingList(eventId, sort): Observable<TrainingModel[]> {
    return this.http.get<TrainingModel[]>(`${this.urlEvent}/${eventId}/trainings/${sort}`);
  }

  getOnlineTrainingList(eventId, sort): Observable<IResponseModel> {
    return this.http.get<IResponseModel>(`${this.urlEvent}/${eventId}/onlineTrainings/${sort}`);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyApplied)
  attendToTraining(trainingScheduleId): Observable<any> {
    return this.http.post<any>(`${this.urlEvent}/attendToTraining`, trainingScheduleId);
  }

  getMedicalList(eventId, sort): Observable<MedicalModel[]> {
    return this.http.get<MedicalModel[]>(`${this.urlEvent}/${eventId}/medicals/${sort}`);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyApplied)
  attendToMedical(medicalScheduleId): Observable<any> {
    return this.http.post<any>(`${this.urlEvent}/attendToMedical`, medicalScheduleId);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyApplied)
  submitMedicalConsent(model): Observable<any> {
    return this.http.post<any>(`${this.urlVolunteer}/submitMedicalConsent`, model);
  }

  printCertificate(range){
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
   return this.http.post<any>(`${this.urlVolunteer}/certificate`, range,httpOptions);   
  }
  printExperienceCertificate(model){
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
   return this.http.post<any>(`${this.urlVolunteer}/Experience`, model,httpOptions);   
  }

  printEventCertificate(eventId: string, id: string, lan: string) {
    window.open(`${this.urlVolunteer}/${eventId}/${id}/certificate/${lan}`).print(); return false;
  }


  getTaskList(filtersParams): Observable<IResponseModel> {
    return this.http.post<IResponseModel>(`${this.urlEvent}/task/list`, filtersParams);
  }

  getTask(id): Observable<TaskModel> {
    return this.http.get<TaskModel>(`${this.urlEvent}/task/${id}`);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyUpdated)
  updateTask(model:any): Observable<any> {
    return this.http.put<any>(`${this.urlEvent}/updateTask`, model);
  }

  @ProgressBar()
  changeTaskStatus(model:any): Observable<any> {
    return this.http.put<any>(`${this.urlEvent}/changeTaskStatus`, model);
  }

  getOnlineTraining(id): Observable<OnlineTrainingModel> {
    return this.http.get<OnlineTrainingModel>(`${this.urlOnlineTraining}/${id}`);
  }

  getOnlineTrainingQuestions(id:any): Observable<OnlineTrainingQuestionModel[]> {
    return this.http.get<OnlineTrainingQuestionModel[]>(`${this.urlOnlineTraining}/${id}/questions`);
  }

  getResultOnlineTraining(id:any): Observable<OnlineTrainingResultModel> {
    return this.http.get<OnlineTrainingResultModel>(`${this.urlOnlineTraining}/${id}/result`);
  }

  getResultQuestionsOnlineTraining(id:any): Observable<OnlineTrainingQuestionModel[]> {
    return this.http.get<OnlineTrainingQuestionModel[]>(`${this.urlOnlineTraining}/${id}/result/questions`);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyRegistered)
  setOnlineTrainingQuestions(model) {
    return this.http.post(`${this.urlOnlineTraining}`, model);
  }

}
