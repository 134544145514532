<div class="upcoming-event">
    <div *ngIf="hasAccess; else noAccess">
      <div *ngIf="eventDays; else noUpcoming">
        <div *ngIf="!isCurrentLanguageArabic" class="days-count">{{eventDays}} Day<span *ngIf="eventDays > 1">s</span></div>
        <div *ngIf="isCurrentLanguageArabic" class="days-count">{{eventDays}} 
          <span *ngIf="eventDays > 2">ايام</span>
          <span *ngIf="eventDays == 1 || eventDays == 2">يوم</span>
        </div>
            <!-- <span *ngIf="eventDays == 1">Day</span> -->
        
        <div class="upcoming-event">
           <span *ngIf="!isCurrentLanguageArabic">to</span>
           <span *ngIf="isCurrentLanguageArabic">على فعالية</span>
            <b style="cursor: pointer;" (click)="goToEvent()">{{upcomingEventName}}</b>
        </div>
        
      </div>
    </div>
    <ng-template #noAccess>
      <p>{{ 'DASHBOARD.myDashboard.noUpcoming' | translate }} </p>
    </ng-template>
  
    <ng-template #noUpcoming>
      <p *ngIf="!isLoading"><br/> {{ 'DASHBOARD.myDashboard.noUpcoming' | translate }}<br/> {{ 'DASHBOARD.myDashboard.checkOpportunities' | translate }}</p>
    </ng-template>
  
  </div>