import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import {BASE_URL} from '../constants/constant';

@Injectable()
export class CurrentUserService {
  private url = `${BASE_URL}api/public/volunteer/basicProfile`;
  public user$: Subject<any> = new Subject<any>();
  public user:any;
  private observable: Observable<any>;
  constructor(private http: HttpClient) { }

  getCurrentUser(): Observable<any> {
    if (this.user != null) {
      this.user$.next(this.user);
      return Observable.of(this.user);
    } else if (this.observable) {
      return this.observable;
    } else {
      this.observable = this.http.get(`${this.url}`)
        .do((user) => {
          if (user) {
            this.user = user;
            this.observable = null;
            this.user$.next(this.user);
            return Observable.of(this.user);
          }
        }).share();
      return this.observable;
    }
  }

  clearCurrentUser() {
    this.user = null;
    this.user$.next(null);
  }
}
