<div class="task-board z-depth-2">
  <!-- TO DO LIST -->
  <mvms-kanban-board-list
    class="status-board"
    [tasks]="tasksStatus0"
    [title]="'DASHBOARD.event.task.todo' | translate"
    [@animate]="{value:'*',params:{duration:'350ms',x:'100%'}}"
    [status]="taskStatus.ToDo"
    fxLayout="column"
    fxFlex="1 1">
  </mvms-kanban-board-list>
  <!-- / TO DO LIST -->

  <!-- In Progress LIST -->
  <mvms-kanban-board-list
    class="status-board"
    [tasks]="tasksStatus1"
    [title]="'DASHBOARD.event.task.doing' | translate"
    [@animate]="{value:'*',params:{duration:'350ms',x:'100%'}}"
    fxLayout="column"
    [status]="taskStatus.Doing"
    fxFlex="1 1">
  </mvms-kanban-board-list>
  <!-- / In Progress LIST -->

  <!-- Done LIST -->
  <mvms-kanban-board-list
    class="status-board"
    [tasks]="tasksStatus2"
    [title]="'DASHBOARD.event.task.done' | translate"
    [@animate]="{value:'*',params:{duration:'350ms',x:'100%'}}"
    [status]="taskStatus.Done"
    fxLayout="column"
    fxFlex="1 1">
  </mvms-kanban-board-list>
  <!-- / Done LIST -->
</div>
