import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, OnInit, ViewChild, ViewEncapsulation, NgZone, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ContentService } from '../../services/content.service';
import { GoogleMapsService } from 'google-maps-angular2';
import { PublicService } from '../../services/public.service';
import { BASE_URL, RegexpPattern } from '../../constants/constant';
import { CurrentUserService } from '../../services/current-user.service';
import { RecaptchaDynamicLanguageLoaderService } from '../../services/recaptcha-dynamic-language-loader.service';
import { RecaptchaLoaderService } from 'ng-recaptcha';
import { DepartmentResources } from '../../services/models/department-resources.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { truncateSync } from 'node:fs';



export declare var grecaptcha: any;
declare var $: any;
@Component({
  selector: 'mvms-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ContentComponent implements OnInit, AfterViewInit, AfterViewChecked {

  BASE_URL = BASE_URL;

  public galleryItems: any[];
  public captchaKey: string;
  public contactUsSent = false;
  public contactUsSentError = false;
  public slidecount: number;

  public opportunitiesCount = 0;
  public volunteeringHoursTotalCount = 0;
  public volunteeringHoursCount = 0;
  public isGalleryInitialized = false;
  public SlideOptions = {
    items: 5,
    dots: true,
    nav: true,
    loop: false,
    rewind: true,
    autoplay: false,
    mouseDrag: true,
    slideBy: 1,
    margin: 10,
    // rtl: true,
    lazyLoad: true,
    autoplayTimeout: 6000,
    touchDrag: true,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>'
    ],
    navContainer: '.scroll-container .custom-nav',
    responsive: {
      0: {
        items: 1,
        nav: true,
        slideBy: 1,
        autoplay: true,
      },
      600: {
        items: 3,
        nav: false
      },
      1000: {
        items: 5,
        nav: false,
        autoplay: false,
      }
    }
  };

  public contactUsForm = this.fb.group({
    fromName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20), Validators.pattern(new RegExp(RegexpPattern.enumeration))]],
    fromEmail: ['', [Validators.required, Validators.pattern(new RegExp(RegexpPattern.email))]],
    message: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]],
    recaptchaResponse: [null, [Validators.required]]
  });

  departmentResources: DepartmentResources;

  @ViewChild('googlemap',{static:false}) mapElement: ElementRef;
  @ViewChild('carouselElement',{static:false}) carouselElement: ElementRef;
  actions = new EventEmitter<string>();
  subActions = new EventEmitter<string>();
  public carouselInitialized = false;
  public isLoggedIn = false;
  public loaderReady = false;
  public isSimple = false;

  constructor(private translate: TranslateService,
    private contentService: ContentService,
    private gapi: GoogleMapsService,
    private publicService: PublicService,
    private currentUserService: CurrentUserService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    @Inject(RecaptchaLoaderService) private loader: RecaptchaDynamicLanguageLoaderService,
    private zone: NgZone) {
  }

  ngOnInit(): void {
    // $('body,html').animate({scrollTop: 0}, 800);

    this.loader.ready.subscribe(v => {
      setTimeout(() => this.zone.run(() => this.loaderReady = !!v), 0);
    });
    this.contentService.getGalleryAlbumList({ pageNo: 1, pageSize: 12, searchText: '' }).subscribe((data: any) => {
      this.galleryItems = data.items;
     
    });

    if (this.currentUserService.user) {
      this.isLoggedIn = true;
    }
    this.currentUserService.user$.subscribe((res) => {
      res ? this.isLoggedIn = true : this.isLoggedIn = false;
    });

    this.captchaKey = window.location.host.startsWith('localhost')
      ? '6LeEUzQaAAAAAJC2noxFx1L6aL2yPx16EacWU4o1' : '6LeEUzQaAAAAAJC2noxFx1L6aL2yPx16EacWU4o1';
    setTimeout(() => this.loader.updateLanguage(this.translate.currentLang));

    this.activatedRoute.data.subscribe(data => {
      this.isSimple = data && data.isSimple;
    });
  }

  ngAfterViewInit(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.contentService.departmentResources$.subscribe(
      r => {
        this.departmentResources = r;
        if (r) {
          const video = <HTMLVideoElement>document.getElementById('videoBackground');
          video.innerHTML = `<source src="assets/video/${r.videoLink}" type="${this.getVideoType('assets/video/' + r.videoLink)}">`;
          video.load();
          video.autoplay = true;
          video.muted = true;
          // video.play();
        }
        // this.departmentResources.videoLink = encodeURI(r.videoLink);
      }
    );
    const mapElement = this.mapElement;
    this.gapi.init.then((maps: any) => {
      const geocoder = new maps.Geocoder();
      geocoder.geocode({
        'address': ' King Abdulaziz Center for World Culture'
      }, function (results, status) {
        if (status === maps.GeocoderStatus.OK) {
          const pos = { lng: results[0].geometry.location.lng(), lat: results[0].geometry.location.lat() };
          const myOptions = {
            zoom: 15,
            center: pos
          };
          const map = new maps.Map(mapElement.nativeElement, myOptions);

          const marker = new maps.Marker({
            map: map,
            position: pos
          });
        }
      });
    });

    $('#gallery .gallery-expand').galleryExpand({
      inDuration: 500
    });

    $(document).on('gallery.close', function () {
      $('.expandWrapper').hide();
    });
  }
  getVideoType(vid) {
    return `video/${vid.substring(vid.lastIndexOf('.') + 1)}`;
  }

  ngAfterViewChecked() {
    if (!this.isGalleryInitialized && $('.scroll-container .item a').length > 0) {
      this.isGalleryInitialized = true;
      setTimeout(() => { const smartPhoto = new window['SmartPhoto']('.scroll-container .item a') }, 1000);
    }
  }

  scrollToMain() {
    const element = document.getElementById('main');
    $('body,html').animate({
      scrollTop: element.offsetTop - 50
    }, 800);
  }

  contactUs() {
    if (this.contactUsForm.valid && this.contactUsForm.dirty) {
      const value = this.contactUsForm.value;
      this.publicService.contactUs(value).subscribe(r => {
        this.contactUsForm.controls.recaptchaResponse.reset();
        this.resetFormValue('contactUsForm');
        this.contactUsSent = true;
        this.contactUsSentError = false;
      }, () => {
        this.contactUsSent = false;
        this.contactUsSentError = true;
        this.contactUsForm.controls.recaptchaResponse.reset();
      });
    }
  }

  resetFormValue(form) {
    for (const name in this[form].controls) {
      if (this[form].controls.hasOwnProperty(name)) {
        this[form].controls[name].setValue('');
        this[form].controls[name].setErrors(null);
      }
    }
  }
}
