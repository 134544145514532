<div>
    <mat-table #table [ngClass]="'task-table'" [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element" class="priority">
            <mat-icon class="toDo" *ngIf="element.status == 0">check_box_outline_blank</mat-icon>
            <mat-icon class="inProgress" *ngIf="element.status == 1">indeterminate_check_box</mat-icon>
            <mat-icon class="done" *ngIf="element.status == 2">check_box</mat-icon>
          </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef>{{ 'DASHBOARD.myDashboard.title' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.title}}</mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="priority">
          <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
          <mat-cell *matCellDef="let element" class="priority">
            <mat-icon>more_horiz</mat-icon>
            <!-- <img class="responsive-img indicator" *ngIf="element.priority == 0" [attr.title]="'DASHBOARD.myDashboard.priority.low' | translate "
                 src="data:image/png;base64,iVBORw0KGg oAAAANSUhEUgAAADQAAAA0CAYAAADFeB vrAAAC80lEQVRoBe3aTUsbQRgH8CcxSm yjDbggRYh9gb4g9CKxvSl49lJIL7bH2k /gp/ATWHprvVTopedCxUtVeimUvhxqFK QIUaKmNRjNlv+WhWz6bDIz+0zcBuey2W dnZ5/fZl9mJkm4rutSF5VkF1k8ygUo7t 9o131DKZtnvFo7og/FRdrcXyGqE113Ju nBtWeU7h2wdtiEradc+XiHXm08ooPqVi D5K+lRepx/Tdn+kUBcasXKJQfM0kbhHw ySBhDbUMdGEQf5mHJ1OzRfbLOFEgWpYH ylLZQYSAdjEyUCMsHYQkUGRcHYQEUCSW CkUcYgSYwkyujFqopJJFN072rBz9dbfv q5TG79NBBrXhnsG6En998YvXy1QaoYJN nXk6H56a+BfBfe3aGTs0ogxq2YorQuOR 0Ml6RO7PBkh16uPdTuUSiDOonx4SYoJd B5YExRbUHniTFBtQTFAaOLCgXFCaODYk HHtQNa+ligVkMA/yCdXuJBgdyqp4fsoV nQ+tYilX+Hj2fYljoYRG5rxRfsEVnQj7 1VtnKcgsX992w6LIit+Z8EWdBNZyr26d 8YmmRzZEETo08peynH7hCHIHLL5+bYVF hQOjVIs+PLlE3HD4VOK3ILm9tr2dvWfR dhuDBzd4GczG3v7CWTPTQ8MBY4k7tHn6 leP/Nipco3evtlvu1wwm9ApQfeEoSGdF GXex1vItHJ3PLzYJelyndvIvJXrcRubw 6qYLAPe8k1NoYZztm8+uWHBDFjioTDii 2MEgiVJFE2McogKZRtDPJsew+hUmMxva fQBi5F6XumMTd81gZhJxMU9rONMQb5KI z50fuVLKpPs7Bjtn3Khe2IBwWmmpCAVI mKQR7GIOwsiZLARAZJoaQwIqCoKEmMGM gUJY0RBemibGDEQaooWxgroEYUfsJvLo iZ/rLQ3Ba3btRT4BriYvjjxfr2c9rcWy Fy//7xYiI3Fzo449rQjVkF6SYjUT/Si1 UiAek2LkDSZ1S6va77hv4A/1f3lX6x43 AAAAAASUVORK5CYII=">
            <img class="responsive-img indicator"  *ngIf="element.priority == 1" [attr.title]="'DASHBOARD.myDashboard.priority.medium' | translate "
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAADaElEQVRoBe3az08TQRQH8O9roEj4URMuBisRBDziSeMJNcGDKAQCJspJD4rRq/F/0CuJGM+aGCVKjXrgoHginPSk4k8sRk04WMCQYsIzM+3SXTvt/pppa8Mkm915bzozn7YM2ekSMzOqqESqyCIp26BK/0Sr7hOqMfqOb6yAX90Evr4AIgTEe0EHLgHRZmPDkrFVbnUJPD0CWvnkmDw3d4AGHwBNcUdcV8UMSGAeDoLWksp5cuMe0NC0EZT+vyEXjBAKqABjdUkJDhPUC/KAsSZrCqUP5ANjEqUHFABjChUeFAJjAhUOpAGjGxUcpBGjExUMZADjRA0EXtL9gwxicijxjzkYyh+oBJiwKO+gEmLCoLyByoAJinIHlRETBFUcVAEYv6jCIA8Y3rHTGq/omSO1EIeXUqxPWnNf/dSgdAqcGC58P9N6GBibA51fAM69AXeNKOfKdTHg+C3QxUV5oG8SHG1St90/KvuSfY7NgcUYiiJRiWEg/UuRBZQgcdtMqS/KF3BjHNR/F4h1ZPL1LaC+CfCug3ntqfc60DkEUE3m6BoG9d7Ia8eth0DHJoD6lkwu1gE6cQfc0JrXVgTk3F7fVuaUICRnlY1lsL0fqG34Jx8BdY86YkxRYN8pR0xWOgcgc7YMdZ8GiGwRANFGoP2kM2arcfK5rZa7VINy+fyrCt9oVYPajuZDrMjiE2Bjzaplz5vgd/cdMeIN4EPCEZOV948gc7YMv70HYNMWQWaMz4+dMVuN2o7YarlLJYh6LoBje3OtbFe0+g387CyQyu7mrC+DZy6Dfs7bWmUuefYqsDAFbP7JHAtT4JfX8trRj3nwzBVgfTmTS30EPz0D+v09r60IyLn1jCtzhXd9vCzbdTFQOqXs2B60lmwSMJciVsZifcpFaShRcMeoMEgM7AHlMj+taTeMGEz5lduaRVNc7p+JfbRyFy8Yd5BoUQEorxhvoDKj/GC8g8qE8ovxByoxKgjGP6hEqKCYYCAHSv9PImEwwUFbqATEBHSVsJhwIM0oHZjwIE0oXRg9oJAonRh9oIAo3Ri9IJ8oExj9II8oUxgzIDuqObuRImLZIn/WL3I/Y7ULei5+PxS0V+t18sGLSSCZffBit3jwYvw/ffDCQpX4XPwGr8ST0THcNkjHu2iyj6r7hP4CQBrAUZ09uO8AAAAASUVORK5CYII=">
            <img class="responsive-img indicator" *ngIf="element.priority == 2" [attr.title]="'DASHBOARD.myDashboard.priority.high' | translate "
                 src="data:image/png;base64,iVBORw0KGg oAAAANSUhEUgAAADQAAAA0CAYAAADFeB vrAAACt0lEQVRoBe3aT2sTQRQA8De7wV pNLkpRG4tfY7UfQvDgRYsJWg9e/CxevF RIaqUXQfBD2PRrSJsqDQp2V2KhycpbGM iGl+7MmzfNNHQuwz5mZt9vd/bfJCrP8x wWqEQLZCkoV6DQz+jCnaGazyOepymkO1 0Y9vYgVhFcSxJobLRANRredqt83eVGP/ rwa3MTzg6/l5Kv3X8At7e2IL7XLMWlNr yAEDN41YZxv0/mGTWbsPKh4wUlfg1VYV CIUARjW+kiCjLBaIAvlBjIBuMTJQLiYH yhnEEuGB8oJ5AERhrFBkliJFGs55ApRt ViWH78ROdb1MOvXyA/G5Vi0xvR3VVY6X RZzylrkCmmSPJmA1a/7ZfyPVpPAP6mpR i1wUVZTTkrDJWlRWz88wgG7Zb1w9cYdJ EY7eagjEDzwHBRlaB5Yjioc0EhYGxRM0 EhYWxQJGic/oHB65czv2f0DuZRFzcKzO 3khNw9Cco+bsP44IDsEEIQc8t2d8hUSN Bwv0c2Din4r7dHpkOCyJaXJEiCbjxaDz 795Yd0juS7HF5wx8+eOl9HI6UgrtdLB2 eUZRA7LqdHa2twZ/czuRxGgjCDEG/bmF fVSys55bAjrpvhUhMuOYVSqjAF+LxkQ0 KZYNAyc8pNQtnTb2kJbr17PzkU/H77Bu D0tBSr2jDF4DhGIGzIQjl84GmkDQb7zL yG9IC6nsf0s8VYgbDxRaI4GMzReMrpM4 W16fS7FIskGlag2i3At1/Jwj0zOgfja0 h30HUx/Trd4kGnY661Kwb3zwZhZ0mUBM YZJIWSwoiAXFGSGDEQFyWNEQXZonxgxE GmKF8YL6BJFP6EP10wxv1lYXosapv1pk ANRMXwjxfZp23ARRcFCq4nCdSfvyC/NK n+nJhXECch1z5OD1bXnfvofwXycVQlx1 y4M/Qfc2jGlPwec4AAAAAASUVORK5CYI I="> -->
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="dueDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DASHBOARD.myDashboard.dueDate' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.dueDate| dateTimeFormat }}</mat-cell>
        </ng-container>
      
        <mat-header-row *matHeaderRowDef="displayedColumns" ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToTask(row)"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>
</div>