import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { EventListModelExtended, EventListModelConverter } from '../events.model';
import { CertificateRange } from '../events.model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DashboardService } from '../../dashboard.service';
import { GoogleMapsService } from 'google-maps-angular2';
import { Subscription } from '../../../../../../node_modules/rxjs';
import { filter } from '../../../../../../node_modules/rxjs/operators';
import { CurrentUserService } from '../../../../services/current-user.service';
import { DashboardDataService } from '../../dashboard-data.service';
import { LanguageService } from '../../../../services/language.service';
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { appInjector } from 'src/app/app-injector';
import { ToasterService } from 'angular5-toaster/dist';

@Component({
  selector: 'mvms-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit, OnDestroy {
  @ViewChild('googlemap') mapElement: ElementRef;

  public eventsList: EventListModelExtended[];
  public currentEventsList: EventListModelExtended[];
  public pastEventsList: EventListModelExtended[];
  public hasAccess: boolean;
  public queryString: string;
 // public range : CertificateRange;
  public minValue: number = 2018;
  public maxValue: number = new Date().getFullYear();
  options: Options = {
    floor: 2018,
    ceil: new Date().getFullYear(),
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "" + value;
        case LabelType.High:
          return "" + value;
        default:
          return "" + value;
      }
    }
  };

  isLoading: boolean;
  subscription: Subscription = Subscription.EMPTY;
  user: any;

  constructor(
    protected dashboardService: DashboardService,
    protected dashboardDataService: DashboardDataService,
    private gapi: GoogleMapsService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private currentUserService: CurrentUserService,
    private languageService: LanguageService) {
    iconRegistry.addSvgIcon(
      'search',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/scale.svg'));
  }

  ngOnInit() {
    this.isLoading = true;
    this.currentUserService.getCurrentUser().subscribe((user) => {
      this.user = user;
    });
    this.dashboardService.refreshEventList();

    this.subscription = this.dashboardService.data
      .pipe(filter(res => !!res))
      .subscribe(res => {
        if (res && res.items && res.items.length) {
          this.eventsList = res.items.map(EventListModelConverter.toEventListModelExtended);

          var now = new Date();
          this.currentEventsList = this.eventsList.filter((item: any) => { return new Date(item.endDateTime).getTime() >= now.getTime() });
          this.pastEventsList = this.eventsList.filter((item: any) => { return new Date(item.endDateTime).getTime() < now.getTime() });

         // this.loadMap();
        }
        this.isLoading = false;
      },
        () => this.isLoading = false
      );

    this.currentUserService.getCurrentUser().subscribe(res => {
      this.hasAccess = res.isAccepted;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // loadMap(): void {
  //   const mapElement = this.mapElement;
  //   this.gapi.init.then((maps: any) => {
  //     const map = new maps.Map(mapElement.nativeElement, {
  //       zoom: 5,
  //       center: new maps.LatLng(this.eventsList[0].latitude, this.eventsList[0].longitude),
  //       mapTypeId: maps.MapTypeId.ROADMAP
  //     });

  //     for (let i = 0; i < this.eventsList.length; i++) {
  //       const marker = new maps.Marker({
  //         position: new maps.LatLng(this.eventsList[i].latitude, this.eventsList[i].longitude),
  //         map: map
  //       });
  //     }
  //   });
  // }

  search(event) {
    const value = event.target.value;
    this.dashboardService.$queryString.next(value);
  }

  printCertificate() {
   // const range : CertificateRange = new CertificateRange(this.user.volunteerId,this.languageService.isCurrentLanguageArabic ? 'ar' : 'en',this.minValue,this.maxValue)
    this.dashboardDataService.printCertificate({
      VolunteerId: this.user.volunteerId,
      Lang : this.languageService.isCurrentLanguageArabic ? 'ar' : 'en',
      CheckInYear: this.minValue,
      CheckOutYear: this.maxValue
    }).subscribe((res : Blob )=> {
      var url = window.URL.createObjectURL(res);
      window.open(url);
    }, (error) =>{
      //return error;
      const toasterService = appInjector().get(ToasterService);
        if (!this.languageService.isCurrentLanguageArabic)
          toasterService.pop('error', 'Error', 'No attendance found for the selected range');
        else
          toasterService.pop('error', 'خطأ', 'لم يتم العثور على حضور');
    }
    
   );

  }
}
