<mat-form-field fxFlex="100" >
  <mat-select class="IE-select" [placeholder]="placeholder" [formControl]="inputControl" [attr.dir]="isCurrentLanguageArabic  ? 'rtl' : ''"
              panelClass="mvms-public">
    <mat-option class="IE-select" *ngFor="let item of filteredArray | async" [value]="item.id"  [class.rtl]="isCurrentLanguageArabic" [attr.dir]="isCurrentLanguageArabic  ? 'rtl' : ''">
      {{item.value}}
    </mat-option>
  </mat-select>

  <mat-error *ngIf="inputControl.hasError('required')">
    {{'SHARED.Errors.emptyField' | translate }}
  </mat-error>
</mat-form-field>
