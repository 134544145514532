<div class="create-form-dialog" [class.rtl]="isCurrentLanguageArabic" [attr.dir]="isCurrentLanguageArabic  ? 'rtl' : ''">
  <div class="header-panel">
    <h2 class="header">{{ (evaluationData ? 'DASHBOARD.event.evaluation.escalate' : 'DASHBOARD.Enquiries.createTitle') | translate }} </h2>
    <div class="btn-close">
      <button mat-icon-button (click)="closeDialog()" tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="main">
    <form class="form" [formGroup]="createForm" (ngSubmit)="create()" #form="ngForm">
      <div class="row">
        <h4 class="title col m2 s12">{{'DASHBOARD.Enquiries.createSubject' | translate }}</h4>
        <mat-form-field class="col m10 s12" floatLabel="never">
          <input matInput formControlName="subject" type='text' [placeholder]="'DASHBOARD.Enquiries.createSubject' | translate">
          <mat-hint align="end">{{createForm.controls['subject'].value.length}} / 50</mat-hint>
          <mat-error *ngIf="(createForm.controls['subject'].touched || validate) && createForm.controls['subject'].hasError('required')">
            {{'SHARED.Errors.emptyField' | translate }}
          </mat-error>
          <mat-error *ngIf="createForm.controls['subject'].hasError('maxlength')">
            {{'SHARED.Errors.maxLength' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <h4 class="title col m2 s12">{{'DASHBOARD.Enquiries.createBody' | translate }}</h4>
        <div class="col m10 s12">
          <ckeditor [ngClass]="{'ng-touched': validate, 'cke_rtl': isCurrentLanguageArabic}" formControlName="body" [config]="ckEditorConfig" debounce="500">
          </ckeditor>
          <p class="custom-hint">
            {{createForm.controls['body'].value.length}} / 500
          </p>
          <p class="custom-ck-error" *ngIf="(createForm.controls['body'].touched || validate) && createForm.controls['body'].hasError('required')">
            {{'SHARED.Errors.emptyField' | translate }}
          </p>
          <p class="custom-ck-error" *ngIf="createForm.controls['body'].hasError('maxlength')">
            {{'SHARED.Errors.maxLength' | translate }}
          </p>
        </div>
      </div>
      <div class="btn-group">
        <button type="submit" mvmsDebounceClick (debounceClick)="form.onSubmit($event)" [debounceTime]="1500" mat-raised-button color="accent">
          {{'SHARED.Button.Send' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
