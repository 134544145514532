<div *ngIf="myDataArray && myDataArray.data.length; else noRecords" class="mat-elevation-z3">
  <mat-table #table [dataSource]="myDataArray" matSort>
    <ng-container matColumnDef="eventTitle">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'DASHBOARD.event.evaluation.title' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{currentEvent.title }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="eventStartDateTime">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'DASHBOARD.event.evaluation.date' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.eventStartDateTime | dateTimeFormat }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="evaluationEndDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'DASHBOARD.event.evaluation.enddate' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.evaluationEndDate | dateFormat }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="sendEvaluation">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'DASHBOARD.event.evaluation.sendeval' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span *ngIf="row.hasEmployeeResult">
          <a class="link" (click)="sendEvaluationView(row.id)">{{'DASHBOARD.event.evaluation.view' | translate }}</a>
        </span>
        <span *ngIf="!row.hasEmployeeResult && row.type === evaluationRequestPartyType.Bidirectional">
          <a class="link" (click)="evaluate(row.id)">{{'DASHBOARD.event.evaluation.evaluate' | translate }}</a>
        </span>
        <span *ngIf="!row.hasEmployeeResult && row.type === evaluationRequestPartyType.Unidirectional">{{'DASHBOARD.event.evaluation.n_a' | translate }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="evaluationReceived">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'DASHBOARD.event.evaluation.receivedeval' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span *ngIf="row.hasVolunteerResult">
          <a class="link" (click)="evaluationReceivedView(row.id)">{{'DASHBOARD.event.evaluation.view' | translate }}</a>
        </span>
        <span *ngIf="!row.hasVolunteerResult">{{'DASHBOARD.event.evaluation.n_a' | translate }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="userFullName">
      <mat-header-cell *matHeaderCellDef>{{'DASHBOARD.event.evaluation.user' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.userFullName}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>

<ng-template #noRecords>
    <mvms-no-records *ngIf="!isLoading"></mvms-no-records>
</ng-template>