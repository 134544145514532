<div class="scrumboard-board-list">
  <div class="list" fxLayout="column" fxFlex>

    <!-- LIST HEADER -->
    <div class="list-header">
      {{title}}
    </div>
    <!-- / LIST HEADER -->

    <!-- LIST CONTENT -->
    <div class="list-content">
      <div class="list-cards ngx-dnd-container z-depth-2"
           [model]="tasks"
           ngxDroppable="tasks" (drop)="setStatus($event)">
        <mvms-scrumboard-board-card ngxDraggable
                                    class="row ngx-dnd-item"
                                    *ngFor="let task of tasks"
                                    [model]="task"
                                    [task]="task">
        </mvms-scrumboard-board-card>
      </div>
    </div>
    <!-- / LIST CONTENT -->

  </div>
</div>

