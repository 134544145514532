import { Injectable } from '@angular/core';
import {BASE_URL} from '../../../../constants/constant';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {Toast, ToasterMessagesTypes} from '../../../../decorators/toast.decorator';
import {EvaluateModel, EvaluationCriteriaModel, EvaluationModel} from './evaluation.model';
import {IResponseModel} from 'src/types';
import {ProgressBar} from '../../../../decorators/progress-bar.decorator';
import { AnyMxRecord } from 'dns';

@Injectable()
export class EvaluationDataService {
  private url = `${BASE_URL}api/PublicEvaluation`;

  public emitRefreshList: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {}

  getList(filterParam:any): Observable<IResponseModel> {
    return this.http.post<IResponseModel>(`${this.url}/list`, filterParam);
  }

  getItem(id:any): Observable<EvaluationModel> {
    return this.http.get<EvaluationModel>(`${this.url}/${id}`);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyCreated)
  create(model:any) {
    return this.http.post<any>(`${this.url}/submit`, model);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyEvaluationSent)
  evaluate(model: EvaluateModel) {
    return this.http.post<EvaluateModel>(`${this.url}/evaluate`, model);
  }

  getCriteriaList(id:any): Observable<EvaluationCriteriaModel[]> {
    return this.http.get<EvaluationCriteriaModel[]>(`${this.url}/criteria/${id}`);
  }

  getSendEvaluation(id:any): Observable<EvaluationModel> {
    return this.http.get<EvaluationModel>(`${this.url}/sent/${id}`);
  }

  getReceivedEvaluation(id:any): Observable<EvaluationModel> {
    return this.http.get<EvaluationModel>(`${this.url}/received/${id}`);
  }

}
