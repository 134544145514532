import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EvaluationDataService } from '../evaluation-data.service';
import { EventModel } from '../../events.model';
import { EvaluateModel, EvaluationModel } from '../evaluation.model';
import { Subject } from 'rxjs/Subject';
import { LanguageService } from '../../../../../services/language.service';

const TemplateType = {
  evaluate: 'evaluate',
  sentEvaluation: 'sentEvaluation',
  receivedEvaluation: 'receivedEvaluation'
};

@Component({
  selector: 'mvms-evaluation-view-edit',
  templateUrl: './evaluation-view-edit.component.html',
  styleUrls: ['./evaluation-view-edit.component.scss']
})
export class EvaluationViewEditComponent implements OnInit, OnDestroy {
  @Output() handleUpdateList: EventEmitter<Object> = new EventEmitter();
  @Output() handleEscalate: EventEmitter<Object> = new EventEmitter();
  @HostBinding('class') type: string;

  event: EventModel;
  evaluationPartyId: string;
  model: EvaluationModel;
  criteriaList: any;
  isDataLoaded = false;

  criteriFormList: FormGroup = this.fb.group({
    items: this.fb.array([]),
    comments: ['', Validators.maxLength(250)]
  });

  public isCurrentLanguageArabic: boolean;
  private componentDestroyed: Subject<any> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<EvaluationViewEditComponent>,
    private evaluationDataService: EvaluationDataService,
    private fb: FormBuilder,
    private languageService: LanguageService
  ) {
  }

  ngOnInit() {
    this.type === TemplateType.evaluate ?  this.initFormArray(this.criteriaList) : this.patchModelInForms();
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
  }

  isTypeEvaluate(): boolean {
    return this.type === TemplateType.evaluate;
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  patchModelInForms() {
    if (this.model.items.length > 0) {
      const arr = [];
      this.model.items.forEach(i => {
        const formGroup = this.fb.group({});
        formGroup.addControl('criteriaId', new FormControl(i.criteriaId));
        formGroup.addControl('englishName', new FormControl(i.criteriaName));
        formGroup.addControl('value', new FormControl(i.value, Validators.required));
        arr.push(formGroup);
      });
      this.criteriFormList.setControl('items', this.fb.array(arr));
    } else {
      this.criteriFormList.setControl('items', this.fb.array([]));
    }
    this.criteriFormList.patchValue({ comments: this.model.comment });
    this.criteriFormList.get('comments').disable();
    this.criteriFormList.addControl('description', new FormControl('', [Validators.required, Validators.maxLength(250)]));
  }

  initFormArray(criteriaList:any) {
    if (criteriaList.length > 0) {
      const arr:any = [];
      criteriaList.forEach(i => {
        const formGroup = this.fb.group({});
        formGroup.addControl('criteriaId', new FormControl(i.id));
        formGroup.addControl('englishName', new FormControl(i.name));
        formGroup.addControl('value', new FormControl(3, Validators.required));
        arr.push(formGroup);
      });
      this.criteriFormList.setControl('items', this.fb.array(arr));
      this.criteriFormList.patchValue({ comments: '' });
    } else {
      this.criteriFormList.setControl('items', this.fb.array([]));
    }
    this.criteriFormList.addControl('comments', new FormControl('', [Validators.required, Validators.maxLength(250)]));
  }

  evaluate() {
    if (this.criteriFormList.valid) {
      const model: EvaluateModel = {
        criteriaValues: this.criteriFormList.value.items,
        comment: this.criteriFormList.value.comments,
        evaluationPartyId: this.evaluationPartyId
      };
      this.evaluationDataService.evaluate(model).subscribe(() => {
        this.handleUpdateList.emit();
        this.closeDialog();
      });
    }
  }

  escalate() {
    this.handleEscalate.emit();
    this.closeDialog();
  }

}
