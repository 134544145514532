import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { TrainingService } from './training.service';
import { OnlineTrainingListItemModel, TrainingModelConverter, TrainingStatus, TrainingModelExtended, TrainingModel, OnlineTrainingListItemModelConverter } from './training.model';
import { DashboardDataService } from '../../dashboard-data.service';
import { DashboardService } from '../../dashboard.service';
import { ViewResultComponent } from './view-result/view-result.component';
import { ViewResultTypes } from '../../../../constants/constant';
import { Subject } from 'rxjs/Subject';
import { MvmsAnimations } from '../../../../animations/animations';
import { GoogleMapsService } from 'google-maps-angular2';
import { IResponseModel } from '../../../../../types';

@Component({
  selector: 'mvms-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss'],
  animations: MvmsAnimations
})
export class TrainingComponent implements OnInit, OnDestroy {
  @ViewChild('googlemap') mapElement: ElementRef;

  noRecords: any;
  public TrainingStatus = TrainingStatus;
  trainings:any;
  user:any;
  trainingList: TrainingModelExtended[];
  onlineTrainingList: OnlineTrainingListItemModel[];
  isLoading: boolean;

  protected componentDestroyed: Subject<any> = new Subject();

  constructor(private trainingService: TrainingService,
    private router: Router,
    protected dashboardDataService: DashboardDataService,
    protected dashboardService: DashboardService,
    private cdRef: ChangeDetectorRef,
    private gapi: GoogleMapsService,
    private matDialog: MatDialog) {
  }

  ngOnInit() {
    this.isLoading = true;

    this.dashboardService.currentEvent.takeUntil(this.componentDestroyed)
      .subscribe(event => {
        if (event) {
          this.dashboardService.currentEvent.value.requireOfflineTrainings ?
            this.getOfflineTrainingList()
            : this.getOnlineTrainingList();
        }
      });
  }

  getOfflineTrainingList() {
    this.isLoading = true;

    this.dashboardDataService.getOfflineTrainingList(this.dashboardService.routeParams.id, 'Desc')
      .takeUntil(this.componentDestroyed).subscribe(list => {
        list.map(item => {
          if (!item.isEnrolled) {
            item.status = TrainingStatus.None;
          } else {
            if (!item.hasResult) {
              item.status = TrainingStatus.Enrolled;
            }
          }
        });
        this.trainingList = list.map(TrainingModelConverter.toTrainingModelExtended);
        setTimeout(() => {
          this.loadMap();
        }, 600);

        this.isLoading = false;
      },
        () => this.isLoading = false);
  }

  getOnlineTrainingList() {
    this.isLoading = true;

    this.dashboardDataService.getOnlineTrainingList(this.dashboardService.routeParams.id, 'Desc')
      .takeUntil(this.componentDestroyed)
      .subscribe((data: IResponseModel) => {
        this.onlineTrainingList = data.items.map(OnlineTrainingListItemModelConverter.toOnlineTrainingListItemModelExtended);

        this.isLoading = false;
      },
        () => this.isLoading = false
      );
  }

  loadMap(): void {
    if (this.trainingList && this.trainingList.length === 0) {
      return;
    }

    this.gapi.init.then((maps: any) => {
      const mapElement = this.mapElement;
      const map = new maps.Map(mapElement.nativeElement, {
        zoom: 5,
        center: new maps.LatLng(this.trainingList[0].latitude, this.trainingList[0].longitude),
        mapTypeId: maps.MapTypeId.ROADMAP
      });

      for (let i = 0; i < this.trainingList.length; i++) {
        const marker = new maps.Marker({
          position: new maps.LatLng(this.trainingList[i].latitude, this.trainingList[i].longitude),
          map: map
        });
      }
    });
  }

  attendToTraining(trainingId:any) {
    this.dashboardDataService.attendToTraining({ trainingScheduleId: trainingId }).subscribe(() => {
      this.trainingList.find(item => item.canEnroll = false);
      this.trainingList.forEach(i => {
        if (i.id === trainingId) {
          i.status = 0;
        }
        this.cdRef.detectChanges();
      });
    });
  }

  enroll(id:any) {
    const param = {
      VolunteerId: this.user.id,
      TrainingScheduleId: id
    };

    this.trainingService.enrollToTraining(param).subscribe(result => {
      console.log(result);
      if (result) {
        this.router.navigate(['/']);
      }
    });
  }

  showViewresultDialog(training) {
    const dialogRef = this.matDialog.open(ViewResultComponent, { panelClass: 'view-result' });
    dialogRef.componentInstance.data = TrainingModelConverter.toTrainingModelExtended(training);
    dialogRef.componentInstance.type = ViewResultTypes.training;
  }

  viewOnlineTrainingResult(training:any) {
    this.dashboardService.currentTraining = training;
    this.router.navigate([`/dashboard/myEvents/${this.dashboardService.routeParams.id}/online-training/${training.id}/quiz`]);
  }

  attendToOnlineTraining(training) {
    this.dashboardService.currentTraining = training;
    this.router.navigate([`/dashboard/myEvents/${this.dashboardService.routeParams.id}/online-training/${training.id}`]);
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}
