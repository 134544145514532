<div class="mvms-public" [class.rtl]="isCurrentLanguageArabic" [attr.dir]="isCurrentLanguageArabic  ? 'rtl' : ''"
  (click)="click($event)">
  <mvms-header></mvms-header>
  <router-outlet></router-outlet>

  <footer id="footer" class="section no-pad">
    <div class="container">
      <div class="row">
        <div *ngIf="footerSection" class="col s12 content">
        <div class="left" >
            <div *ngIf="footerSection" class="col s12 l9 content" [innerHTML]="footerSection.content | safeHtml">
            </div>
          </div>
          <div class="col-sm-2 col-sm-12 right" style="text-align:right">
            <a *ngIf="!isCurrentLanguageArabic" href="https://www.ithra.com/en/" target="_blank">
              <mvms-image *ngIf="departmentResources" [imageId]="departmentResources.footerId" [isAvatar]="false">
              </mvms-image>
            </a>
            <a *ngIf="isCurrentLanguageArabic" href="https://www.ithra.com/ar/" target="_blank">
              <mvms-image *ngIf="departmentResources" [imageId]="departmentResources.footerId" [isAvatar]="false">
              </mvms-image>
            </a>
          </div>
        </div>
      </div>
   
      <div class="divider"></div>
      <div class="copyright">
        <div class="col-sm-6 col-md-5">
          <p *ngIf="!isCurrentLanguageArabic">© {{year}} King Abdulaziz Center for World Culture</p>
          <p *ngIf="isCurrentLanguageArabic">© {{year}} مركز الملك عبدالعزيز الثقافي العالمي</p>
        </div>
<!-- Privacy and Terms&Conditions added -->
      <div>
        <p *ngIf="!isCurrentLanguageArabic">
          <a href="https://www.ithra.com/en/privacy-policy/" target="_blank">
          Privacy Policy
          </a>
          </p>
          <p *ngIf="isCurrentLanguageArabic">
          <a href="https://www.ithra.com/ar/privacy-policy/" target="_blank">
          سياسة خاصة
          </a>
          </p>
        </div>
        <div>
        <p *ngIf="!isCurrentLanguageArabic">
          <a href="https://www.ithra.com/en/terms-conditions/" target="_blank">
          Terms & Conditions
          </a>
          </p>
          <p *ngIf="isCurrentLanguageArabic">
          <a href="https://www.ithra.com/ar/terms-conditions/" target="_blank">
          الشروط والأحكام
          </a>
          </p>
        </div>
        <!--<div class="col-sm-6 col-md-5 version">
          <ul>
            <<li *ngIf="currentVersion && isCurrentLanguageArabic"> &nbsp;انطلاقة تجريبية<h6>{{ currentVersion }}</h6>
            </li>
            <li *ngIf="currentVersion && !isCurrentLanguageArabic">Beta Version &nbsp;<h6>{{ currentVersion }}</h6>
            </li>
          </ul>
        </div>-->
      </div>
    </div>
  </footer>
  <mvms-scroll-top></mvms-scroll-top>
  <div class="toaster-box">
    <toaster-container [toasterconfig]="toasterconfig"></toaster-container>
  </div>
</div>

<!-- Core Javascript -->
<script src="js/imagesloaded.pkgd.min.js"></script>
<script src="js/masonry.pkgd.min.js"></script>
<script src="https://cdn.jsdelivr.net/materialize/0.98.0/js/materialize.min.js"></script>
<script src="js/color-thief.min.js"></script>
<script src="js/galleryExpand.js"></script>
<script src="js/init.js"></script>