<div class="nav-wrapper">
  <a href="dashboard/myEvents" data-activates="dash-nav-mobile" class="button-collapse show-on-medium-and-down menu-btn"><i class="material-icons">menu</i></a>
  <div class="navbar">
    <ul class="nav navbar-nav hide-on-med-and-down">
      <li class="nav-item">
       
        <a class="nav-link IE-padding" routerLink="myDashboard" routerLinkActive="active">
          <span> <mat-icon>dashboard</mat-icon> </span>
          <span style="position:absolute">{{'DASHBOARD.navMenu.dashboard' | translate }}</span></a>
      </li>
      <li class="nav-item">
       
          <a class="nav-link IE-padding" routerLink="/opportunities" routerLinkActive="active">
          <span><mat-icon>confirmation_number</mat-icon></span>  
          <span style="position:absolute">{{'DASHBOARD.navMenu.VolunteeringOpportunities' | translate }}</span></a>
        </li>
      <li class="nav-item">

        <a class="nav-link IE-padding" routerLink="myEvents" routerLinkActive="active">
        <span> <mat-icon>favorite</mat-icon> </span>
          <span style="position:absolute">{{'DASHBOARD.navMenu.Event' | translate }}</span></a>
        <div class="sub-menu" [@expandCollapseHorizontal]="dashboardService.currentEventId.value != null">
          <ul>
            <li class="item">
              <a class="sub-nav-link IE-padding" [routerLink]="['myEvents/' + dashboardService.currentEventId.value + '/training']" routerLinkActive="sub-active"><span>{{'DASHBOARD.subNavMenu.Training' | translate }}</span></a>
            </li>
            <!--li class="item">
              <a class="sub-nav-link" [routerLink]="['myEvents/' + dashboardService.currentEventId.value + '/medical']" routerLinkActive="sub-active">{{'DASHBOARD.subNavMenu.Medical' | translate }}</a>
            </li-->
            <li class="item">
              <a class="sub-nav-link IE-padding" [routerLink]="['myEvents/' + dashboardService.currentEventId.value + '/tasks']" routerLinkActive="sub-active"><span>{{'DASHBOARD.subNavMenu.Task' | translate }}</span></a>
            </li>
            <li class="item">
              <a class="sub-nav-link IE-padding" [routerLink]="['myEvents/' + dashboardService.currentEventId.value + '/evaluation']" routerLinkActive="sub-active"><span>{{'DASHBOARD.subNavMenu.Evaluation' | translate }}</span></a>
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
       
        <a class="nav-link IE-padding" routerLink="attendance" routerLinkActive="active">
        <span><mat-icon>spellcheck</mat-icon></span>
          <span style="position:absolute">{{'DASHBOARD.navMenu.Attendance' | translate }}</span>
        </a>
      </li>
      <li class="nav-item">
        
        <a class="nav-link IE-padding" routerLink="rewards" routerLinkActive="active">
        <span ><mat-icon> local_activity</mat-icon></span>  
        <span style="position:absolute">{{'DASHBOARD.navMenu.Rewards' | translate }}</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link IE-padding" routerLink="enquiries" routerLinkActive="active">
         <span><mat-icon>input</mat-icon> </span> 
        <span style="position:absolute">{{'DASHBOARD.navMenu.Enquiries' | translate }}
        <span class="badge" *ngIf="unread>0">{{unread}}</span></span></a>
      </li>
      <li class="nav-item">
        
        <a class="nav-link IE-padding" routerLink="profile" routerLinkActive="active">
        <span><mat-icon>account_circle</mat-icon></span>  
        <span style="position:absolute">{{'DASHBOARD.navMenu.Profile' | translate }}</span></a>
      </li>
    </ul>
  </div>
</div>

<ul class="side-nav" id="dash-nav-mobile">
    <li class="nav-item">
        <a class="nav-link IE-padding" (click)="removeOverlay()" routerLink="/opportunities" routerLinkActive="active">{{'DASHBOARD.navMenu.VolunteeringOpportunities' | translate }}</a>
      </li>
  <!--li class="nav-item">
    <a class="nav-link IE-padding" routerLink="myDashboard" routerLinkActive="active">{{'DASHBOARD.navMenu.dashboard' | translate }}</a>
  </li-->
  <li class="nav-item">
    <a class="nav-link IE-padding" routerLink="myEvents" routerLinkActive="active" (click)="removeOverlay()">{{'DASHBOARD.navMenu.Event' | translate }}</a>
    <div class="sub-menu" [@expandCollapseHorizontal]="dashboardService.currentEventId.value != null">
      <ul>
        <li class="item">
          <a class="sub-nav-link IE-padding" (click)="removeOverlay()" [routerLink]="['myEvents/' + dashboardService.currentEventId.value + '/training']" routerLinkActive="sub-active">{{'DASHBOARD.subNavMenu.Training' | translate }}</a>
        </li>
        <li class="item">
          <a class="sub-nav-link IE-padding" (click)="removeOverlay()" [routerLink]="['myEvents/' + dashboardService.currentEventId.value + '/medical']" routerLinkActive="sub-active">{{'DASHBOARD.subNavMenu.Medical' | translate }}</a>
        </li>
        <li class="item">
          <a class="sub-nav-link IE-padding" (click)="removeOverlay()" [routerLink]="['myEvents/' + dashboardService.currentEventId.value + '/tasks']" routerLinkActive="sub-active">{{'DASHBOARD.subNavMenu.Task' | translate }}</a>
        </li>
        <li class="item">
          <a class="sub-nav-link IE-padding" (click)="removeOverlay()" [routerLink]="['myEvents/' + dashboardService.currentEventId.value + '/evaluation']" routerLinkActive="sub-active">{{'DASHBOARD.subNavMenu.Evaluation' | translate }}</a>
        </li>
      </ul>
    </div>
  </li>
  <li class="nav-item">
    <a class="nav-link IE-padding" (click)="removeOverlay()" routerLink="attendance" routerLinkActive="active">{{'DASHBOARD.navMenu.Attendance' | translate }}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link IE-padding" (click)="removeOverlay()" routerLink="rewards" routerLinkActive="active">{{'DASHBOARD.navMenu.Rewards' | translate }}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link IE-padding" (click)="removeOverlay()" routerLink="enquiries" routerLinkActive="active">{{'DASHBOARD.navMenu.Enquiries' | translate }}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link IE-padding" (click)="removeOverlay()" routerLink="profile" routerLinkActive="active">{{'DASHBOARD.navMenu.Profile' | translate }}</a>
  </li>
  <li class="nav-item">
    <a mat-raised-button (click)="removeOverlay()" class="right submit-button" (click)="logout()">{{'HOME.MainNavMenu.SignOut' |
      translate }}</a>
  </li>
</ul>
