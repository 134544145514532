import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {ITabNavItem} from '../../../../types';

@Component({
  selector: 'mvms-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss']
})
export class TabNavigationComponent implements OnInit {

  constructor( private el: ElementRef) { }
  @Input() navLinks: ITabNavItem[];
  ngOnInit() {
  }

  left() {
    const el = this.el.nativeElement.querySelector('.mat-tab-label-container');
    el.scrollLeft -= 100;
  }

  right() {
    const el = this.el.nativeElement.querySelector('.mat-tab-label-container');
    el.scrollLeft += 100;
  }

}
