import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from '../../../../dashboard.service';
import { DashboardDataService } from '../../../../dashboard-data.service';
import { Router } from '@angular/router';
import { TaskListItemModel } from '../../task.model';
import { TaskStatus } from '../../../../../../constants/constant';
import { MvmsAnimations } from '../../../../../../animations/animations';

@Component({
  selector: 'mvms-kanban-board-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: MvmsAnimations
})
export class ListComponent implements OnInit {

  @Input() tasks: Array<TaskListItemModel>;
  @Input() title: string;
  @Input() status: TaskStatus;

  constructor(private router: Router,
    protected dashboardDataService: DashboardDataService,
    protected dashboardService: DashboardService) {
  }

  ngOnInit(): void {
  }

  // openTaskDetails($event, id) {
  //   let clearCurrent = false;
  //   if ($event.ctrlKey) {
  //     clearCurrent = true;
  //     const alreadySelectedIndex = this.sectionService.selectedIds.indexOf(id);
  //     if (alreadySelectedIndex >= 0) {
  //       this.sectionService.selectedIds.splice(alreadySelectedIndex, 1);
  //     }
  //     else {
  //       this.sectionService.selectedIds.push(id);
  //     }
  //   }
  //   else if ($event.shiftKey) {
  //     clearCurrent = true;
  //     const selectedIndex = this.tasks.findIndex(v => v.id === this.sectionService.onCurrentChanged.value.id);
  //     const clickedIndex = this.tasks.findIndex(v => v.id === id);
  //
  //     if (selectedIndex >= 0) {
  //       this.sectionService.selectedIds = [];
  //       this.tasks.slice(Math.min(clickedIndex, selectedIndex), Math.max(clickedIndex, selectedIndex) + 1).map(t => this.sectionService.selectedIds.push(t.id));
  //     }
  //   }
  //   else {
  //     this.sectionService.selectedIds = [id];
  //   }
  //   this.sectionService.selectedIds = this.sectionService.selectedIds.filter((value, index, self) => self.indexOf(value) === index); // unique
  //   if (clearCurrent) {
  //     this.sectionService.onCurrentChanged.next(null);
  //     this.router.navigate([`${Section.Tasks}/task`]);
  //   }
  //   else {
  //     this.router.navigate([`${Section.Tasks}/task/${id}`]);
  //   }
  //
  //   this.sectionService.handleRowCheckChange(this.sectionService.selectedIds);
  // }

  setStatus($event) {
    const idsForUpdate = [$event.value.id];
    this.dashboardDataService.changeTaskStatus({
      ids: idsForUpdate,
      status: this.status
    }).subscribe(r => {
      this.dashboardService.data.value.items.forEach(i => {
        if (idsForUpdate.indexOf(i.id) >= 0) {
          i.status = this.status;
        }
      });
      //this.sectionService.data.next(this.sectionService.data.value);
    },
      err => {
        this.dashboardService.currentEvent.subscribe(event => {
          if (event) {
            this.dashboardDataService.getTaskList({
              pageNo: 1,
              pageSize: 100,
              searchText: '',
              eventId: event.id
            }).subscribe(data => {
              this.dashboardService.dataTaskList.next(data);
            });
          }
        });
      });
  }

}
