<div class="form-container">
  <mat-horizontal-stepper #stepper [linear]="true" (selectionChange)="changeStep()" style="border-radius:20px;">
    <mat-step [stepControl]="personalForm">
      <div class="section">
        <h2 class="header">{{'REGISTRATION.firstStep.Title' | translate }}</h2>
        <form class='form' [formGroup]="personalForm">
          <ng-template matStepLabel>{{'REGISTRATION.firstStep.Title' | translate }}</ng-template>
          <div class="form-fields">
            <div class="row">
              <h4 class="title col l4">{{'REGISTRATION.firstStep.name' | translate }}</h4>
              <div class="col l8">
                <div class="form-fields-group row">
                  <mat-form-field class="field field_medium col">
                    <input matInput formControlName="firstName" type='text'
                      [placeholder]="'REGISTRATION.firstStep.firstName' | translate">
                    <mat-error *ngIf="personalForm.controls.firstName.hasError('pattern');else error1">
                      {{'REGISTRATION.Errors.firstNamePatternErrorEnglish' | translate }}
                    </mat-error>
                    <ng-template #error1>
                      <mat-error
                        *ngIf="personalForm.controls.firstName.hasError('minlength') || personalForm.controls.firstName.hasError('maxlength')">
                        {{'REGISTRATION.Errors.firstNameLengthError' | translate }}
                      </mat-error>
                    </ng-template>
                    <mat-error *ngIf="personalForm.controls.firstName.hasError('required')">
                      {{'SHARED.Errors.emptyField' | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="field field_medium col">
                    <input matInput formControlName="middleName" type='text'
                      [placeholder]="'REGISTRATION.firstStep.middleName' | translate">
                    <mat-error *ngIf="personalForm.controls.middleName.hasError('pattern'); else error3">
                      {{'REGISTRATION.Errors.middleNamePatternErrorEnglish' | translate }}
                    </mat-error>
                    <ng-template #error3>
                      <mat-error
                        *ngIf="personalForm.controls.middleName.hasError('minlength') || personalForm.controls.middleName.hasError('maxlength')">
                        {{'REGISTRATION.Errors.middleNameLengthError' | translate }}
                      </mat-error>
                    </ng-template>
                    <mat-error *ngIf="personalForm.controls.middleName.hasError('required')">
                      {{'SHARED.Errors.emptyField' | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="field field_medium col">
                    <input matInput formControlName="lastName" type='text'
                      [placeholder]="'REGISTRATION.firstStep.lastName' | translate">
                    <mat-error *ngIf="personalForm.controls.lastName.hasError('pattern'); else error4">
                      {{'REGISTRATION.Errors.lastNamePatternErrorEnglish' | translate }}
                    </mat-error>
                    <ng-template #error4>
                      <mat-error
                        *ngIf="personalForm.controls.lastName.hasError('minlength') || personalForm.controls.lastName.hasError('maxlength')">
                        {{'REGISTRATION.Errors.lastNameLengthError' | translate }}
                      </mat-error>
                    </ng-template>
                    <mat-error *ngIf="personalForm.controls.lastName.hasError('required')">
                      {{'SHARED.Errors.emptyField' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row">
              <h4 class="title col l4">{{'REGISTRATION.firstStep.arabicName' | translate }}</h4>
              <div class="col l8">
                <div class="form-fields-group row">
                  <mat-form-field class="field field_medium col">
                    <input matInput formControlName="arabicFirstName" type='text'
                      [placeholder]="'REGISTRATION.firstStep.firstName' | translate">
                    <mat-error *ngIf="personalForm.controls.arabicFirstName.hasError('pattern');else error5">
                      {{'REGISTRATION.Errors.firstNamePatternErrorArabic' | translate }}
                    </mat-error>
                    <ng-template #error5>
                      <mat-error
                        *ngIf="personalForm.controls.arabicFirstName.hasError('minlength') || personalForm.controls.arabicFirstName.hasError('maxlength')">
                        {{'REGISTRATION.Errors.firstNameLengthError' | translate }}
                      </mat-error>
                    </ng-template>
                    <mat-error *ngIf="personalForm.controls.arabicFirstName.hasError('required')">
                      {{'SHARED.Errors.emptyField' | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="field field_medium col">
                    <input matInput formControlName="arabicMiddleName" type='text'
                      [placeholder]="'REGISTRATION.firstStep.middleName' | translate">
                    <mat-error *ngIf="personalForm.controls.arabicMiddleName.hasError('pattern'); else error6">
                      {{'REGISTRATION.Errors.middleNamePatternErrorArabic' | translate }}
                    </mat-error>
                    <ng-template #error6>
                      <mat-error
                        *ngIf="personalForm.controls.arabicMiddleName.hasError('minlength') || personalForm.controls.arabicMiddleName.hasError('maxlength')">
                        {{'REGISTRATION.Errors.middleNameLengthError' | translate }}
                      </mat-error>
                    </ng-template>
                    <mat-error *ngIf="personalForm.controls.arabicMiddleName.hasError('required')">
                      {{'SHARED.Errors.emptyField' | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="field field_medium col">
                    <input matInput formControlName="arabicLastName" type='text'
                      [placeholder]="'REGISTRATION.firstStep.lastName' | translate">
                    <mat-error *ngIf="personalForm.controls.arabicLastName.hasError('pattern'); else error7">
                      {{'REGISTRATION.Errors.lastNamePatternErrorArabic' | translate }}
                    </mat-error>
                    <ng-template #error7>
                      <mat-error
                        *ngIf="personalForm.controls.arabicLastName.hasError('minlength') || personalForm.controls.arabicLastName.hasError('maxlength')">
                        {{'REGISTRATION.Errors.lastNameLengthError' | translate }}
                      </mat-error>
                    </ng-template>
                    <mat-error *ngIf="personalForm.controls.arabicLastName.hasError('required')">
                      {{'SHARED.Errors.emptyField' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row">
              <h4 class="title col l4">{{'REGISTRATION.firstStep.Gender' | translate }}</h4>
              <div class="field field_medium col l4">
                <mvms-dropdown-select [data]="genderType" [allowCustomValues]="false"
                  [inputControl]="personalForm.controls.gender"
                  [placeholder]="'REGISTRATION.firstStep.Gender' | translate">
                </mvms-dropdown-select>
              </div>
            </div>
            <div class="row">
              <h4 class="title col l4">{{'REGISTRATION.firstStep.BirthDate' | translate }}</h4>
              <mat-form-field class="field field_medium col l4">
                <input matInput formControlName="birthDate" (dateChange)="checkBirthDate()" [matDatepicker]="picker"
                  [placeholder]="'REGISTRATION.firstStep.BirthDate' | translate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error
                  *ngIf="personalForm.controls.birthDate.hasError('required') && !personalForm.controls.birthDate.hasError('matDatepickerParse')">
                  {{'SHARED.Errors.emptyField' | translate }}
                </mat-error>
                <mat-error *ngIf="personalForm.controls.birthDate.hasError('matDatepickerParse')">
                  {{'SHARED.Errors.birthdate' | translate }}
                </mat-error>
                <mat-error *ngIf="personalForm.controls.birthDate.hasError('youngerFiveYears')">
                  {{'SHARED.Errors.youngerFiveYears' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <h4 class="title col l4">{{ 'REGISTRATION.firstStep.nationality' | translate }}</h4>
              <div class="field field_medium col l4">
                <!-- <mvms-dropdown-select [data]="nationalityType" [allowCustomValues]="false"
                  [inputControl]="personalForm.controls.nationality"
                  [placeholder]="'REGISTRATION.firstStep.nationality' | translate"></mvms-dropdown-select> -->

                <mvms-autocomplete [data]="nationalityList" [inputControl]="personalForm.controls.nationalityId"
                  [placeholder]="'REGISTRATION.firstStep.nationality' | translate"></mvms-autocomplete>


              </div>
            </div>
            <!-- <div class="row" *ngIf="personalForm.controls.nationality.value === Nationality.Saudi"> -->
            <div class="row" *ngIf="personalForm.controls.nationalityId.value.id == SaudiNational">
              <h4 class="title col l4">{{'REGISTRATION.firstStep.NationalID' | translate }}</h4>
              <mat-form-field class="field field_medium col l4">
                <input matInput formControlName="nationalId" type='text'
                  [placeholder]="'REGISTRATION.firstStep.NationalID' | translate" (blur)="checkNationalID($event)">
                <mat-error *ngIf="personalForm.controls.nationalId.hasError('notUnique')">
                  {{'SHARED.Errors.nationalIdExist' | translate }}
                </mat-error>
                <mat-error *ngIf="personalForm.controls.nationalId.hasError('required')">
                  {{'SHARED.Errors.emptyField' | translate }}
                </mat-error>
                <mat-error
                  *ngIf="personalForm.controls.nationalId.hasError('minlength') || personalForm.controls.nationalId.hasError('maxlength') || personalForm.controls.nationalId.hasError('pattern')">
                  {{'SHARED.Errors.nationalIdInvalid' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row" *ngIf="!(personalForm.controls.nationalityId.value.id == SaudiNational)">
              <h4 class="title col l4">{{'REGISTRATION.firstStep.PassportID' | translate }}</h4>
              <mat-form-field class="field  field_medium col l4">
                <input matInput formControlName="passportNo" type='text'
                  [placeholder]="'REGISTRATION.firstStep.PassportID' | translate" (blur)="checkPassportID($event)">
                <mat-error *ngIf="personalForm.controls.passportNo.hasError('notUnique')">
                  {{'SHARED.Errors.passportIdExist' | translate }}
                </mat-error>
                <mat-error *ngIf="personalForm.controls.passportNo.hasError('required')">
                  {{'SHARED.Errors.emptyField' | translate }}
                </mat-error>

                <!--<mvms-input-errors [inputControl]="personalForm.controls.passportNo"-->
                <!--[errorsList]="[{ pattern:'SHARED.Errors.passportIdValid' },{ minlength:'SHARED.Errors.passportMinMax' }]"></mvms-input-errors>-->

                <mat-error *ngIf="personalForm.controls.passportNo.hasError('pattern'); else error2">
                  {{'SHARED.Errors.passportIdValid' | translate }}
                </mat-error>
                <ng-template #error2>
                  <mat-error
                    *ngIf="personalForm.controls.passportNo.hasError('minlength') || personalForm.controls.passportNo.hasError('maxlength')">
                    {{'SHARED.Errors.passportMinMax' | translate }}
                  </mat-error>
                </ng-template>
              </mat-form-field>
            </div>
            <div class="row">
              <h4 class="title col l4">{{'REGISTRATION.firstStep.profession' | translate }}</h4>
              <div class="field field_medium col l4">
                <mvms-autocomplete [data]="professionList" [inputControl]="personalForm.controls.professionId"
                  [placeholder]="'REGISTRATION.firstStep.SelectProfession' | translate"></mvms-autocomplete>
              </div>
            </div>
            <div class="row">
              <h4 class="title col l4">{{'REGISTRATION.firstStep.Either' | translate }}</h4>
              <mat-radio-group class="field field_radio col l8" formControlName="accountType">
                <mat-radio-button [value]="AccountType.AramcoEmployee" color="primary">
                  {{'REGISTRATION.firstStep.AramcoEmployee' | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="AccountType.AramcoDependent" color="primary">
                  {{'REGISTRATION.firstStep.AramcoDepended' | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="AccountType.None" color="primary">{{'REGISTRATION.firstStep.None' | translate
                  }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <!--div class="row" *ngIf="personalForm.controls.accountType.value != AccountType.None">
              <h4 class="title col l4">{{'REGISTRATION.firstStep.BadgeNumber' | translate }}</h4>
              <!mat-form-field class="field  field_medium col l4">
                <input matInput formControlName="badgeNo" type='text'
                       [placeholder]="'REGISTRATION.firstStep.BadgeNumber' | translate">
                <mat-error *ngIf="personalForm.controls.badgeNo.hasError('required')">
                  {{'SHARED.Errors.emptyField' | translate }}
                </mat-error>
                <mat-error *ngIf="personalForm.controls.badgeNo.hasError('pattern'); else error5">
                  {{'SHARED.Errors.badgeInvalid' | translate }}
                </mat-error>
                <ng-template #error5>
                  <mat-error *ngIf="personalForm.controls.badgeNo.hasError('maxlength')">
                    {{'SHARED.Errors.badgeMaxLength' | translate }}
                  </mat-error>
                </ng-template>
              </mat-form-field>
            </div-->
            <div class="row">
              <h4 class="title col l4">{{'REGISTRATION.firstStep.MaritalStatus' | translate }}</h4>
              <div class="field field_medium col l4">
                <mvms-dropdown-select [data]="maritalStatusType" [allowCustomValues]="false"
                  [inputControl]="personalForm.controls.maritalStatus"
                  [placeholder]="'REGISTRATION.firstStep.MaritalStatus' | translate">
                </mvms-dropdown-select>
              </div>
            </div>
          </div>
          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.firstStep.Email' | translate }}</h4>
            <mat-form-field class="field  field_medium col l6">
              <input matInput formControlName="email" type='email'
                [placeholder]="'REGISTRATION.firstStep.Email' | translate" (blur)="checkRegistrationEmail($event)">
              <mat-error *ngIf="personalForm.controls.email.hasError('notUnique')">
                {{'SHARED.Errors.EmailExist' | translate }}
              </mat-error>
              <mat-error *ngIf="personalForm.controls.email.hasError('pattern')">
                {{'SHARED.Errors.emailInvalid' | translate }}
              </mat-error>
              <mat-error *ngIf="personalForm.controls.email.hasError('required')">
                {{'SHARED.Errors.emptyField' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <ng-container formGroupName="passwordGroup">
            <div class="row">
              <h4 class="title col l4">{{'REGISTRATION.firstStep.Password' | translate }}</h4>
              <mat-form-field class="big-error-input field field_big col l4">
                <input matInput formControlName="password" [type]="showPassword6.checked ? 'text' : 'password'"
                  [placeholder]="'REGISTRATION.firstStep.Password' | translate" mvmsBlockPaste>
                <mat-button-toggle #showPassword6 matSuffix>
                  <mat-icon *ngIf="showPassword6.checked">visibility</mat-icon>
                  <mat-icon *ngIf="showPassword6.checked===false">visibility_off</mat-icon>
                </mat-button-toggle>

                <mat-error
                  *ngIf="personalForm.get('passwordGroup')['controls']['password'].hasError('pattern') || personalForm.controls.passwordGroup['controls']['password'].hasError('minlength') ">
                  {{'SHARED.Errors.passwordMust' | translate }}
                </mat-error>
                <mat-error *ngIf="personalForm.controls.passwordGroup['controls']['password'].hasError('required')">
                  {{'SHARED.Errors.emptyField' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <h4 class="title col l4">{{'REGISTRATION.firstStep.PasswordConfirmation' | translate }}</h4>
              <mat-form-field class="field  field_big col l6"
                [class.mat-form-field-invalid]="personalForm['controls'].passwordGroup.hasError('isMatch')  && personalForm['controls']['passwordGroup']['controls']['password'].touched && personalForm['controls']['passwordGroup']['controls']['confirmPassword'].touched">
                <input matInput formControlName="confirmPassword" [type]="showPassword7.checked ? 'text' : 'password'"
                  [placeholder]="'REGISTRATION.firstStep.PasswordConfirmation' | translate" mvmsBlockPaste>
                <mat-button-toggle #showPassword7 matSuffix>
                  <mat-icon *ngIf="showPassword7.checked">visibility</mat-icon>
                  <mat-icon *ngIf="showPassword7.checked===false">visibility_off</mat-icon>
                </mat-button-toggle>

                <mat-hint
                  *ngIf="personalForm['controls'].passwordGroup['controls']['confirmPassword'].hasError('isMatch')  && personalForm['controls']['passwordGroup']['controls']['password'].touched && personalForm['controls']['passwordGroup']['controls']['confirmPassword'].touched">
                  {{'SHARED.Errors.PasswordNotMatch' | translate }}
                </mat-hint>
                <mat-error
                  *ngIf="personalForm.get('passwordGroup')['controls']['confirmPassword'].hasError('required')">
                  {{'SHARED.Errors.emptyField' | translate }}
                </mat-error>
                <!--<mat-error-->
                <!--*ngIf="personalForm.get('passwordGroup')['controls']['confirmPassword'].hasError('pattern') || personalForm.controls.passwordGroup['controls']['confirmPassword'].hasError('minlength') ">-->
                <!--{{'SHARED.Errors.passwordMust' | translate }}-->
                <!--</mat-error>-->
              </mat-form-field>
            </div>

          </ng-container>
          <div class="row">
            <h4 class="title col l4"></h4>
            <div class="btn-group col l4">
              <button mat-raised-button color="accent" [disabled]="!personalForm.valid && personalFormChecked"
                (click)="checkStepPersonal()">
                {{'SHARED.Button.Next' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </mat-step>
    <mat-step [stepControl]="contactForm">
      <div class="section">
        <h2 class="header">{{'REGISTRATION.secondStep.Title' | translate }}</h2>
        <form class='form tenant-form' [formGroup]="contactForm">
          <ng-template matStepLabel>{{'REGISTRATION.secondStep.Title' | translate }}</ng-template>
          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.secondStep.City' | translate }}</h4>
            <div class="field field_medium col l4">
              <mvms-autocomplete [data]="cityList" [inputControl]="contactForm.controls.cityId"
                [placeholder]="'REGISTRATION.secondStep.City' | translate"></mvms-autocomplete>
            </div>
          </div>
          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.secondStep.Mobile' | translate }}</h4>
            <mat-form-field class="phone field  field_medium col l4">
              <input matInput formControlName="phoneNo" class="phone ltr" type='text' [placeholder]="'+9xx123456789'"
                (blur)="checkPhone($event)">
              <mat-error *ngIf="contactForm.controls.phoneNo.hasError('notUnique')">
                {{'SHARED.Errors.PhoneExist' | translate }}
              </mat-error>
              <mat-error
                *ngIf="contactForm.controls.phoneNo.hasError('notValid') || contactForm.controls.phoneNo.hasError('pattern')">
                {{'SHARED.Errors.PhoneNotValid' | translate }}
              </mat-error>
              <mat-error *ngIf="contactForm.controls.phoneNo.hasError('equal')">
                {{'SHARED.Errors.equalNumber' | translate }}
              </mat-error>
              <mat-error *ngIf="contactForm.controls.phoneNo.hasError('required')">
                {{'SHARED.Errors.emptyField' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.secondStep.Address' | translate }}</h4>
            <mat-form-field class="field  field_medium col l4">
              <input matInput formControlName="address" type='text'
                [placeholder]="'REGISTRATION.secondStep.Address' | translate">
              <mat-error *ngIf="contactForm.controls.address.hasError('required')">
                {{'SHARED.Errors.emptyField' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.secondStep.Emergency' | translate }}</h4>
            <mat-form-field class="phone field  field_medium col l4">
              <input matInput formControlName="emergencyContact" class="phone ltr" type='text'
                [placeholder]="'+9xx123456789'" (blur)="checkEmergencyPhone($event)">
              <mat-error
                *ngIf="contactForm.controls.emergencyContact.hasError('notValid') || contactForm.controls.emergencyContact.hasError('pattern')">
                {{'SHARED.Errors.EmergencyPhoneNotValid' | translate }}
              </mat-error>
              <mat-error *ngIf="contactForm.controls.emergencyContact.hasError('equal')">
                {{'SHARED.Errors.equalNumber' | translate }}
              </mat-error>
              <mat-error *ngIf="contactForm.controls.emergencyContact.hasError('required')">
                {{'SHARED.Errors.emptyField' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <h4 class="title col l4"></h4>
            <div class="btn-group col l4">
              <button mat-raised-button color="accent" matStepperPrevious>{{'SHARED.Button.Back' | translate }}</button>
              <button mat-raised-button color="accent" [disabled]="!contactForm.valid && contactFormChecked"
                (click)="checkStepContact()">
                {{'SHARED.Button.Next' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </mat-step>
    <mat-step [stepControl]="skillsForm">
      <div class="section">
        <h2 class="header">{{'REGISTRATION.thirdStep.Title' | translate }}</h2>
        <form class='form tenant-form' [formGroup]="skillsForm" #skills>
          <ng-template matStepLabel>{{'REGISTRATION.thirdStep.Title' | translate }}</ng-template>
          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.thirdStep.haveVolunteered' | translate }}</h4>
            <mat-radio-group class="field field_radio col l4" formControlName="hasExperience">
              <mat-radio-button [value]="true" color="primary">{{'SHARED.Button.Yes' | translate }}</mat-radio-button>
              <mat-radio-button [value]="false" color="primary">{{'SHARED.Button.No' | translate }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="row" *ngIf="skillsForm.controls.hasExperience.value">
            <h4 class="title col l4">{{'REGISTRATION.thirdStep.Where' | translate }}</h4>
            <mat-form-field class="field  field_medium col l4">
              <input matInput formControlName="experienceLocation" type='text'
                [placeholder]="'REGISTRATION.thirdStep.Location' | translate">
            </mat-form-field>
          </div>
          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.thirdStep.Experiences' | translate }}</h4>
            <mat-form-field class="field  field_medium col l4">
              <input matInput formControlName="experienceType" type='text'
                [placeholder]="'REGISTRATION.thirdStep.Description' | translate">
            </mat-form-field>
          </div>
          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.thirdStep.Skills' | translate }}</h4>
            <mat-form-field class="field field_medium col l4">
              <mat-select [placeholder]="'REGISTRATION.thirdStep.Skills' | translate" formControlName="skillIds"
                panelClass="mvms-public" multiple>
                <mat-option *ngFor="let skills of skillsList" [value]="skills.id">
                  {{skills.value}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="!skillsForm.controls.skillIds.valid">
                {{'SHARED.Errors.skillsInvalid' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row ">
            <h4 class="title col l4">{{'REGISTRATION.thirdStep.ShareLink' | translate }}</h4>
            <mat-form-field class="ltr field  field_medium col l4">
              <input matInput formControlName="validationLink" type='text'
                [placeholder]="'DASHBOARD.event.profile.ExperiencesTab.linkPlaceholder' | translate">
              <mat-hint class="hint" align="start"> {{'DASHBOARD.event.profile.ExperiencesTab.ShareLinkHint' |
                translate}}
              </mat-hint>
              <mat-error
                *ngIf="skillsForm.controls.validationLink.hasError('link') || skillsForm.controls.validationLink.hasError('https')">
                {{ ( getErrorMessage() === 'link' ? 'SHARED.Errors.linkInvalid' : 'SHARED.Errors.httpsInvalid') |
                translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <h4 class="title col l4"></h4>
            <div class="btn-group col l4">
              <button mat-raised-button color="accent" matStepperPrevious>{{'SHARED.Button.Back' | translate }}</button>
              <button mat-raised-button color="accent" [disabled]="!skillsForm.valid && skillsFormChecked"
                (click)="checkStepSkills()">
                {{'SHARED.Button.Next' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </mat-step>
    <mat-step [stepControl]="detailsForm">
      <div class="section">
        <h2 class="header">{{'REGISTRATION.lastStep.Title' | translate }}</h2>
        <form class='form tenant-form' [formGroup]="detailsForm">
          <ng-template matStepLabel>{{'REGISTRATION.lastStep.Title' | translate }}</ng-template>
          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.lastStep.ReasonsJoin' | translate }}</h4>
            <div class="field field_big col l6">
              <mvms-autocomplete [data]="reasonsList" [inputControl]="detailsForm.controls.joiningReasonId"
                [placeholder]="'REGISTRATION.lastStep.selectReason' | translate"></mvms-autocomplete>
            </div>
          </div>
          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.lastStep.PossibleDay' | translate }}</h4>
            <mat-form-field class="field field_big col l6">
              <mat-select [placeholder]="'REGISTRATION.lastStep.PossibleDay' | translate"
                formControlName="participationDays" panelClass="mvms-public" multiple>
                <mat-option *ngFor="let day of participationDayType" [value]="day.id">
                  {{day.value}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="detailsForm.controls.participationDays.hasError('required')">
                {{'SHARED.Errors.emptyField' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.lastStep.PossibleTime' | translate }}</h4>
            <div class="field field_big col l4">
              <mvms-dropdown-select [data]="participationTimeType" [allowCustomValues]="false"
                [inputControl]="detailsForm.controls.participationTime"
                [placeholder]="'REGISTRATION.lastStep.PossibleTime' | translate"></mvms-dropdown-select>
            </div>
          </div>

          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.lastStep.PossibleHours' | translate }}</h4>
            <div class="field field_big col l4">
              <mvms-dropdown-select [data]="participationHoursType" [allowCustomValues]="false"
                [inputControl]="detailsForm.controls.maxHoursPerDay"
                [placeholder]="'REGISTRATION.lastStep.PossibleHours' | translate">
              </mvms-dropdown-select>
            </div>
          </div>

          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.lastStep.SelectOne' | translate }}</h4>
            <mat-radio-group class="field field_radio col l6" formControlName="hasDiseases">
              <mat-radio-button [value]="false" color="primary">{{'REGISTRATION.lastStep.NoDiseases' | translate }}
              </mat-radio-button>
              <mat-radio-button [value]="true" color="primary">{{'REGISTRATION.lastStep.SomeDiseases' | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="row">
            <h4 class="title col l4">{{'REGISTRATION.lastStep.YourConsent' | translate }}</h4>
            <mat-checkbox color="primary" formControlName="codeOfConductAccepted">
              {{'REGISTRATION.lastStep.IAcceptThe' | translate }} <a href="/code-of-conduct"
                target="_blank">{{'REGISTRATION.lastStep.CodeOfConduct' | translate }}</a></mat-checkbox>
          </div>
          <div class="row" >
            <h4 class="title col l4"></h4>
            <mat-checkbox class="field field_radio col l6" color="primary"
              formControlName="codeOfConductMessageAccepted">
              {{'REGISTRATION.lastStep.AcceptMessage' | translate }}
            </mat-checkbox>
          </div>
          <div class="row" 
            [hidden]="!(detailsForm.controls.codeOfConductAccepted.value && detailsForm.controls.codeOfConductMessageAccepted.value)">
            <h4 class="title col l4"></h4>
            <div class="btn-group col l4">
              <re-captcha class="captcha" [siteKey]="captchaKey" formControlName="recaptchaResponse"></re-captcha>
            </div>
          </div>

          <!--div class="row" *ngIf="detailsForm.controls.hasDiseases.value">
            <h4 class="title col l4">{{'REGISTRATION.lastStep.WhichDisease' | translate }}</h4>
            <mat-form-field class="field  field_big col l4">
              <input matInput formControlName="diseases" type='text'
                     [placeholder]="'REGISTRATION.lastStep.Description' | translate" placeholder="Description">
              <mat-error *ngIf="detailsForm.controls.diseases.hasError('required')">
                {{'SHARED.Errors.emptyField' | translate }}
              </mat-error> 
              <mat-error *ngIf="detailsForm.controls.diseases.hasError('pattern')">
                {{'SHARED.Errors.alphabetsOnly' | translate }}
              </mat-error>
            </mat-form-field>
          </div-->
          <div class="row" >
            <h4 class="title col l4"></h4>
            <div class="btn-group col l4">
              <button mat-raised-button color="accent" matStepperPrevious>{{'SHARED.Button.Back' | translate }}</button>
            </div>
          </div>
          <div class="row">
            <h4 class="title col l4"></h4>
            <div class="btn-group field field_big col l6">
              <button class="full-width" mat-raised-button
                [disabled]="(!detailsForm.valid && detailsFormChecked ) || !(detailsForm.controls.codeOfConductAccepted.value && detailsForm.controls.codeOfConductMessageAccepted.value)" color="accent"
                (click)="checkdetailsStep()">{{'SHARED.Button.Register' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>