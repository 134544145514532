import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DATE_TIME_FMT } from '../constants/constant';
@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date, args?: any): any {

    const dateValue = new Date(value);
    const now = new Date();
    var seconds = Math.floor((now.getTime() - dateValue.getTime()) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (seconds > 172800) {
      return dateValue.toDateString().substring(4);
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 0) {
      return interval + "d";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 0) {
      return interval + "h";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 0) {
      return interval + "m";
    }
    return Math.floor(seconds) + "s";
  }
}
