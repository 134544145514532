import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {CroppieComponent} from './croppie/croppie.component';
import {CroppieOptions} from 'croppie';

@Component({
  selector: 'mvms-croppie-btn',
  templateUrl: './mvms-croppie-btn.component.html',
  styleUrls: ['./mvms-croppie-btn.component.scss']
})
export class MvmsCroppieBtnComponent implements OnInit {
  @Input()  public options: CroppieOptions;
  @Input()  public title = 'Upload';
  @Input()  public required = false;
  @Output() public handleUploadFile: EventEmitter<Object> = new EventEmitter();
  public file: any;
  public validStatus: any;
  constructor(  public dialog: MatDialog, ) { }
  ngOnInit() {}

  uploadCroppImg() {
    const confirmDialogRef: MatDialogRef<CroppieComponent> = this.dialog.open(CroppieComponent, {
      disableClose: false
    });
    confirmDialogRef.componentInstance.options = this.options;
    const sub = confirmDialogRef.componentInstance.handleUploadFile.subscribe((file) => {
      this.file = file;
      this.handleUploadFile.emit(file);
      // confirmDialogRef.close();
    });
    confirmDialogRef.afterClosed().subscribe( () => {
      sub.unsubscribe();
    });
  }

  checkValidate(status){
    this.validStatus = status;
  }

}
