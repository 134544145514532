import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { BASE_URL } from '../../../constants/constant';


@Injectable()
export class HeaderDataService {

  private urlNotification = `${BASE_URL}api/notification`;

  constructor(private http: HttpClient) {
  }

  getNotificationsList(filterParam:any): Observable<any> {
    return this.http.post<any>(`${this.urlNotification}/list`, filterParam);
  }
   
  markAknowledged(model:any) {
    return this.http.put(`${this.urlNotification}`, model);
  }
}
