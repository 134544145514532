<div class="create-form-dialog"  [class.rtl]="isCurrentLanguageArabic" [attr.dir]="isCurrentLanguageArabic  ? 'rtl' : ''">
  <div class="header-panel">
    <h2 *ngIf="isTypeEvaluate(); else viewType" class="header">{{ 'DASHBOARD.event.evaluation.SubmitEvalTitle' | translate }}</h2>
    <ng-template #viewType>
      <h2 class="header">{{ 'DASHBOARD.event.evaluation.ViewEvalTitle' | translate }}</h2>
    </ng-template>
    <div class="btn-close">
      <button mat-icon-button (click)="closeDialog()" tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="main">
    <div class="row">
      <h4 class="title col m4">{{'DASHBOARD.event.evaluation.title' | translate }}</h4>
      <div class="header-title col m8">{{event.title}}</div>
    </div>
    <div class="row">
      <h4 class="title col m4">{{'DASHBOARD.event.evaluation.date' | translate }}</h4>
      <div class="col m8">{{event.startDateTime | dateTimeFormat}}</div>
    </div>
    <div class="row" *ngIf="model && model.employeeName">
      <h4 class="title col m4">{{'DASHBOARD.event.evaluation.user' | translate }}</h4>
      <div class="col m8">{{model.employeeName}}</div>
    </div>
    <mat-divider class="mat-divider" role="separator" aria-orientation="horizontal"></mat-divider>
    <div class="criteria-box row">
      <form class="form " [formGroup]="criteriFormList" (submit)="evaluate()">
        <div formArrayName="items" class="criteria-list col m12">
          <div class="criteria" *ngFor="let item of criteriFormList.get('items')['controls']; let i=index">
            <div [formGroupName]="i">
              <h3 class="title">{{item.controls.englishName.value}} </h3>
              <div class="bar-rating">
                <bar-rating formControlName="value" [theme]="'fontawesome'" [max]="5"></bar-rating>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <mat-form-field class="col m12 ">
            <textarea matInput formControlName="comments" type='text' matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="5"
              [placeholder]="'DASHBOARD.event.evaluation.comments' | translate"></textarea>
            <mat-hint align="end">{{criteriFormList.controls['comments'].value.length}} / 250</mat-hint>
          </mat-form-field>
          <div class="col m12 ">
            <mat-error *ngIf="criteriFormList.controls['comments'].hasError('maxlength')">
              {{'SHARED.Errors.maxLength' | translate }}
            </mat-error>
          </div>
        </div>
        <div class="btn-group">
          <button type="button" mat-raised-button color="accent" (click)="closeDialog()">{{'SHARED.Button.Cancel' | translate }}
          </button>
          <button *ngIf="type === 'evaluate'" type="submit" mat-raised-button class='capitalize' color='accent'>
            {{'SHARED.Button.Save' | translate }}
          </button>
          <button *ngIf="type === 'receivedEvaluation'" type="button" mat-raised-button class='capitalize' color='accent' (click)="escalate()">{{'SHARED.Button.Escalate' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
