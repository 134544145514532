import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {BASE_URL} from '../../constants/constant';
import {Toast, ToasterMessagesTypes} from '../../decorators/toast.decorator';
import {ProgressBar} from '../../decorators/progress-bar.decorator';

@Injectable()
export class AccountService {
  private  url  = `${BASE_URL}api/account`;
  private headers = new HttpHeaders().set('hideToaster', 'true');
  constructor(private http: HttpClient) {
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullySent)
  recoverPasswordByEmail(model: any) {
    const url = `${this.url}/recoverPassword/emailWithCaptcha`;
    return this.http.post(url, model, {headers: this.headers});
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullySent)
  recoverPasswordBySms(model: any) {
    const url = `${this.url}/recoverPassword/smsWithCaptcha`;
    return this.http.post(url, model, {headers: this.headers});
  }

  getTokenByCode(model: any) {
    const url = `${this.url}/getTokenByCode`;
    return this.http.post(url, model, {headers: this.headers});
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyPasswordChanged)
  setNewPassword(model: any) {
    const url = `${this.url}/setNewPassword`;
    return this.http.post(url, model);
  }
}
