import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { DashboardDataService } from '../dashboard-data.service';
import { Subject } from 'rxjs/Subject';
import { MyTasksAndEvents, MyEvent, MyTask} from './my-events-tasks.model';
import { LanguageService } from 'src/app/services/language.service';
import { appInjector } from 'src/app/app-injector';
import { ToasterService } from 'angular5-toaster/dist';
import { CurrentUserService } from 'src/app/services/current-user.service';

@Component({
  selector: 'mvms-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.scss']
})
export class MyDashboardComponent implements OnInit, OnDestroy {
  protected componentDestroyed: Subject<any> = new Subject();
  public myTasksList: MyTask[] ;
  public myEventsList: MyEvent[] ;
  eventsCount = 6;
  public breakpoint;
  user: any;

  constructor(private router: Router,
    protected dashboardService: DashboardService,
    protected dashboardDataService: DashboardDataService,
    private currentUserService: CurrentUserService,
    private languageService: LanguageService) { }

  ngOnInit() {
    this.getEventTaskLists();
    this.breakpoint = (window.innerWidth <= 847) ? 1 : 2;
    this.currentUserService.getCurrentUser().subscribe((user) => {
      this.user = user;
    });
  }
  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 847) ? 1 : 2;
  }

  getEventTaskLists() {
    this.dashboardDataService.getEventsTasks({ eventsCount : this.eventsCount })
      .takeUntil(this.componentDestroyed)
      .subscribe((resp: MyTasksAndEvents) => {
        this.myEventsList = resp.events;
        this.myTasksList = resp.tasks;
        setTimeout(() => {
        }, 100);
      });
  }

  printExperienceCertificate() {
     this.dashboardDataService.printExperienceCertificate({
       VolunteerId: this.user.volunteerId,
       Lang : this.languageService.isCurrentLanguageArabic ? 'ar' : 'en',
     }).subscribe((res : Blob )=> {
       var url = window.URL.createObjectURL(res);
       window.open(url);
     }, (error) =>{
       const toasterService = appInjector().get(ToasterService);
         if (!this.languageService.isCurrentLanguageArabic)
           toasterService.pop('error', 'Error', 'No attendance found for the selected range');
         else
           toasterService.pop('error', 'خطأ', 'لم يتم العثور على حضور');
     }
     
    );
 
   }

}
