import { Component, Input, OnInit } from '@angular/core';
import { EnrollmentStatus, EventListModelExtended } from '../../events.model';
import { BASE_URL } from '../../../../../constants/constant';
import { Router } from '@angular/router';
import { DashboardService } from '../../../dashboard.service';
import { DashboardDataService } from '../../../dashboard-data.service';
import { LanguageService } from '../../../../../services/language.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WithdrawReasonComponent } from '../widthdraw-reason/widthdraw-reason.component';
import { ToasterService } from 'angular5-toaster/dist/angular5-toaster';
import { appInjector } from '../../../../../app-injector';
import { CurrentUserService } from '../../../../../services/current-user.service';

@Component({
  selector: 'mvms-events-list-item',
  templateUrl: './events-list-item.component.html',
  styleUrls: ['./events-list-item.component.scss']
})
export class EventsListItemComponent implements OnInit {
  @Input() event: EventListModelExtended;

  disjoinMessage: string;

  BASE_URL = BASE_URL;

  EnrollmentStatus = EnrollmentStatus;
  user: any;

  constructor(
    private dialog: MatDialog,
    protected router: Router,
    protected dashboardService: DashboardService,
    public currentUserService: CurrentUserService,
    private dashboardDataService: DashboardDataService,
    private languageService: LanguageService) { }

  ngOnInit() {
    this.disjoinMessage = this.languageService.isCurrentLanguageArabic ?
      'هل ترغب في الانسحاب من هذه الفعالية' :
      'Are you sure you want to withdraw this event?';

    this.currentUserService.getCurrentUser().subscribe((user) => {
      this.user = user;
    });
  }

  isCurrentEvent(date:any) {
    var now = new Date();
    return new Date(date).getTime() >= now.getTime();
  }

  goToEvent(id: string) {
    this.dashboardService.currentEventId.next(id);
    this.router.navigate([`/dashboard/myEvents/${id}`]);
  }

  printCertificate(eventId) {
    let lan = this.languageService.isCurrentLanguageArabic ? 'ar' : 'en';
    this.dashboardDataService.printEventCertificate(eventId, this.user.volunteerId, lan);
  }

  withdrawal(event, eventDetail) {
    // Issue fixed: https://takamul.atlassian.net/browse/MVMS-1169 by Saif ur Rehman, Dated: Jun 02, 2019.
    // var FORTY_EIGHT_HOURS = 48 * 60 * 60 * 1000; /* ms */
    // var currentData = new Date().getTime();
    // if ((Date.parse(eventDetail.startDateTime) - currentData) < FORTY_EIGHT_HOURS) {
    //   const toasterService = appInjector().get(ToasterService);
    //   if (this.languageService.isCurrentLanguageArabic)
    //     toasterService.pop('error', 'خطأ', 'لا يمكنك الإنسحاب من هذه الفعالية الآن. الرجاء إرسال استفسار لطلب الإنسحاب');
    //   else
    //     toasterService.pop('error', 'Error', 'You can no longer withdraw yourself from this event. Please send an enquiry to withdraw.');
    //   return;
    // }

    let withdrawalDialogRef: MatDialogRef<WithdrawReasonComponent> =
      this.dialog.open(WithdrawReasonComponent, { disableClose: false });

    withdrawalDialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        event.stopPropagation();
        this.dashboardDataService.withdrawEvent({
          eventId: eventDetail.id,
          reason: result.reason
        }).subscribe(() => {
          this.dashboardService.refreshEventList();
        });
      }
      withdrawalDialogRef = null;
    });
  }

}
