import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DashboardDataService } from './dashboard-data.service';
import { DashboardCountModel } from './dashboard.model';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'mvms-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
  public counter: DashboardCountModel;
  public isCurrentLanguageArabic: boolean;


  constructor(protected dashboardDataService: DashboardDataService, private languageService: LanguageService) { }

  ngOnInit(): void {
    this.dashboardDataService.getDashboardCounter()
      .subscribe(data => this.counter = data);
      this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
  }
}
