import { Component } from '@angular/core';
import { Toast } from 'angular5-toaster/dist/angular5-toaster';

@Component({
  selector: 'mvms-error-toast',
  template: `
    <div *ngIf="toast.data">
      <div *ngFor='let item of toast.data.details'>
        {{item.field}}: <br/>
        <span *ngFor='let error of item.errors'>{{error}}</span><br/>
      </div>
    </div>
  `
})
export class ErrorToasterComponent {
  public toast: Toast = { type: 'success' };

  constructor() {

  }
}
