import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

function ResetFormFields(form:any) {
  for (const name in form.controls) {
    if (form.controls.hasOwnProperty(name)) {
      form.controls[name].setValue('');
      form.controls[name].setErrors(null);
    }
  }
}

function RegexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }
    const valid = regex.test(control.value);
    return valid ? null : error;
  };
}

function OrderedValidator(validators: ValidatorFn[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }
    for (let i = 0; i < validators.length; i++) {
      const error = validators[i](control);
      if (error) {
        return error;
      }
    }
    return null;
  };
}

export {ResetFormFields, RegexValidator, OrderedValidator};
