<div class="section" #sectionContainer>
  <h2 class="header">{{'OPPORTUNITIES.Title' | translate }}</h2>
  <div class="gallery">
    <div *ngIf="events && events.length; else noRecords" fxLayout="row wrap" fxLayout.xs="column" fxLayout.sm="row wrap"
    fxLayoutAlign="start stretch">
        <mvms-global-events-list-item data-aos="zoom-in-up" data-aos-easing="ease-in-sine" data-aos-duration="500" *ngFor="let event of events" class="event-item" [event]="event"></mvms-global-events-list-item>
    </div>
    </div>
</div>
<ng-template #noRecords>
    <div class="noEvents" *ngIf="!isLoading">
        <span>{{ 'DASHBOARD.noEventsToDisplay' | translate }}</span>
    </div>
    <div class="noEvents" *ngIf="isLoading">
        <span><h4>{{ 'DASHBOARD.loading' | translate }}</h4></span>
    </div>
</ng-template>