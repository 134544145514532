import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CreateEnquiryComponent} from './create-enquiry/create-enquiry.component';

@Component({
  selector: 'mvms-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {
  constructor(private matDialog: MatDialog) { }

  ngOnInit() {
  }

  openCreateDialog() {
   this.matDialog.open(CreateEnquiryComponent, {panelClass: 'create'});
  }

}
