import { Component, OnInit, OnDestroy } from '@angular/core';
import { MvmsAnimations } from '../../../../animations/animations';
import { Subscription } from '../../../../../../node_modules/rxjs';
import {AttendanceDataService} from '../attendance-data.service';
import {AttendanceModel} from '../attendance.model';
import { CurrentUserService } from '../../../../services/current-user.service';

@Component({
  selector: 'mvms-list-attendance',
  templateUrl: './list-attendance.component.html',
  styleUrls: ['./list-attendance.component.scss'],
  animations: MvmsAnimations
})
export class ListAttendanceComponent implements OnInit, OnDestroy {
  public attendanceList: AttendanceModel[];
  public hasAccess: boolean;
  isLoading: boolean;
  subscribtions: Subscription[] = [];

  constructor(private attendanceDataService: AttendanceDataService,
    private currentUserService: CurrentUserService) {
    this.subscribtions.push(
      this.attendanceDataService.emitRefreshList
        .subscribe(
          () => this.refreshList()
        )
    );
  }

  ngOnInit() {
    this.refreshList();
    this.currentUserService.getCurrentUser().subscribe(res => {
      this.hasAccess = res.isAccepted;
    });
  }

  ngOnDestroy() {
    this.subscribtions.forEach(s => s.unsubscribe());
  }

  refreshList() {
    this.isLoading = true;

    this.subscribtions.push(
      this.attendanceDataService.getList({
        pageNo: 1,
        pageSize: 100,
        searchText: '',
        sortOrder: 'Desc'
      })
        .subscribe(data => {
          this.attendanceList = data.items;
          this.isLoading = false;
        },
          () => this.isLoading = false
        )
    );
  }

}
