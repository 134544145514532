import { Component, OnInit } from '@angular/core';

import { TrainingModelExtended, TrainingStatus } from '../training.model';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewResultTypes } from '../../../../../constants/constant';
import { MedicalModel } from '../../medical/medical.model';
import { LanguageService } from '../../../../../services/language.service';

@Component({
  selector: 'mvms-view-result',
  templateUrl: './view-result.component.html',
  styleUrls: ['./view-result.component.scss']
})
export class ViewResultComponent implements OnInit {
  data: TrainingModelExtended | MedicalModel;

  type: ViewResultTypes;

  isCurrentLanguageArabic: boolean;

  viewResultTypes = ViewResultTypes;
  public TrainingStatus = TrainingStatus;
  constructor(
    private dialogRef: MatDialogRef<ViewResultComponent>,
    private languageService: LanguageService) { }

  ngOnInit() {
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
