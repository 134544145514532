import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CreateUserService } from '../../services/create-user.service';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/takeUntil';
import { Router } from '@angular/router';
import {
  AccountType,
  ParticipationHours,
  RegexpPattern,
  NATIONALITY_SAUDI,
} from '../../constants/constant';
import { MvmsAnimations } from '../../animations/animations';
import { CommonValidators } from '../../common-validators';
import { MatStepper } from '@angular/material/stepper';
import { OrderedValidator, RegexValidator } from '../shared/utils';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'mvms-join-forms',
  templateUrl: './join-forms.component.html',
  styleUrls: ['./join-forms.component.scss'],
  animations: MvmsAnimations
})
export class JoinFormsComponent implements OnInit, OnDestroy {
  public AccountType = AccountType;
  public SaudiNational = NATIONALITY_SAUDI;
  public participationHoursType = ParticipationHours;
  public maxBirthDate = new Date(new Date().getFullYear() - 5, 0, 1);
  public personalForm = this.fb.group({
    firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern(new RegExp(RegexpPattern.englishLetters))]],
    middleName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern(new RegExp(RegexpPattern.englishLetters))]],
    lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern(new RegExp(RegexpPattern.englishLetters))]],
    arabicFirstName: ['', []],
    arabicMiddleName: ['', []],
    arabicLastName: ['', []],
    gender: ['', [Validators.required]],
    birthDate: ['', [Validators.required]],
    nationalityId: ['', [Validators.required]],
    nationalId: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(new RegExp(RegexpPattern.digits))]],
    passportNo: ['', [Validators.minLength(6), Validators.maxLength(9), Validators.pattern(new RegExp(RegexpPattern.lettersDigits))]],
    professionId: ['', [Validators.required]],
    accountType: [AccountType.None, [Validators.required]],
    //badgeNo: ['', [Validators.maxLength(8), Validators.pattern(new RegExp(RegexpPattern.digits))]],
    maritalStatus: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.pattern(new RegExp(RegexpPattern.email))]],
    passwordGroup: this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50), Validators.pattern(new RegExp(RegexpPattern.password))]],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(50)])]
    }, { validator: CommonValidators.isMatch('password', 'confirmPassword') })
  });

  public contactForm = this.fb.group({
    cityId: ['', [Validators.required]],
    phoneNo: [null, [Validators.required, Validators.minLength(7), Validators.pattern(new RegExp(RegexpPattern.phone))]],
    address: [''],
    emergencyContact: [null, [Validators.required, Validators.minLength(7), Validators.pattern(new RegExp(RegexpPattern.phone))]]
  });
  public skillsForm = this.fb.group({
    hasExperience: [false],
    experienceLocation: ['', []],
    experienceType: ['', []],
    skillIds: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
    validationLink: [null, [OrderedValidator.call(this, [RegexValidator.call(this, new RegExp(RegexpPattern.https), { 'https': true }), RegexValidator.call(this, new RegExp(RegexpPattern.link), { 'link': true })])]],
  });
  public detailsForm = this.fb.group({
    joiningReasonId: ['', [Validators.required]],
    participationDays: ['', [Validators.required]],
    participationTime: ['', [Validators.required]],
    maxHoursPerDay: ['', [Validators.required]],
    hasDiseases: [false],
    codeOfConductAccepted: [false, [Validators.requiredTrue]],
    codeOfConductMessageAccepted: [false, [Validators.requiredTrue]],
    recaptchaResponse: [null, [Validators.required]],

    // diseases: ['', [Validators.pattern(new RegExp(RegexpPattern.enumeration))]],
  });

  public reasonsList;
  public isCreatedUser = false;
  public skillsList;
  public professionList;
  public cityList;
  public genderType;
  public nationalityList;
  public maritalStatusType;
  public participationDayType;
  public participationTimeType;

  private componentDestroyed: Subject<any> = new Subject();
  @ViewChild('stepper') stepper: MatStepper;
  private phoneChecked = false;
  private nationalIDChecked: boolean;
  private passportIDChecked: boolean;
  private mailChecked: boolean;
  private emergencyContactChecked = false;

  private mainSectionOffsetTop = 300;
  public contactFormChecked = false;
  public personalFormChecked = false;
  public skillsFormChecked = false;
  public detailsFormChecked = false;

  isLoading = false;
  isCurrentLanguageArabic: boolean;
  captchaKey: string;

  constructor(
    private fb: FormBuilder,
    private createUserService: CreateUserService,
    private router: Router,
    private languageService: LanguageService) {
  }

  ngOnInit() {
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;
    this.initFormsValidators();
    this.initData();
    this.captchaKey = window.location.host.startsWith('localhost')
      ? '6LeEUzQaAAAAAJC2noxFx1L6aL2yPx16EacWU4o1' : '6LeEUzQaAAAAAJC2noxFx1L6aL2yPx16EacWU4o1';

  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  public initFormsValidators() {
    if (this.isCurrentLanguageArabic) {
      this.personalForm.get('arabicFirstName')
        .setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern(new RegExp(RegexpPattern.arabicLetters))]);
      this.personalForm.get('arabicMiddleName')
        .setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern(new RegExp(RegexpPattern.arabicLetters))]);
      this.personalForm.get('arabicLastName')
        .setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern(new RegExp(RegexpPattern.arabicLetters))]);
    } else {
      this.personalForm.get('arabicFirstName')
        .setValidators([Validators.minLength(2), Validators.maxLength(20), Validators.pattern(new RegExp(RegexpPattern.arabicLetters))]);
      this.personalForm.get('arabicMiddleName')
        .setValidators([Validators.minLength(2), Validators.maxLength(20), Validators.pattern(new RegExp(RegexpPattern.arabicLetters))]);
      this.personalForm.get('arabicLastName')
        .setValidators([Validators.minLength(2), Validators.maxLength(20), Validators.pattern(new RegExp(RegexpPattern.arabicLetters))]);
    }

    this.personalForm.get('arabicFirstName').updateValueAndValidity();
    this.personalForm.get('arabicMiddleName').updateValueAndValidity();
    this.personalForm.get('arabicLastName').updateValueAndValidity();


    this.personalForm.get('nationalityId').valueChanges.takeUntil(this.componentDestroyed).subscribe((nationality: string) => {
      // if (nationality === Nationality.Saudi) {
      if (nationality["id"] == this.SaudiNational) {
        this.personalForm.get('nationalId').setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(new RegExp(RegexpPattern.digits))]);
        this.personalForm.get('nationalId').updateValueAndValidity();
        this.clearValidators('personalForm', ['passportNo']);
      } else {
        this.personalForm.get('passportNo').setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(9), Validators.pattern(new RegExp(RegexpPattern.lettersDigits))]);
        this.personalForm.get('passportNo').updateValueAndValidity();
        this.clearValidators('personalForm', ['nationalId']);
      }
    });
    /*this.personalForm.get('accountType').valueChanges.takeUntil(this.componentDestroyed).subscribe((status: number) => {
      if (status !== AccountType.None) {
        this.personalForm.get('badgeNo').setValidators([Validators.required, Validators.maxLength(8), Validators.pattern(new RegExp(RegexpPattern.digits))]);
        this.personalForm.get('badgeNo').updateValueAndValidity();
      } else {
        this.clearValidators('personalForm', ['badgeNo']);
      }
    });*/
    /*this.detailsForm.get('hasDiseases').valueChanges.takeUntil(this.componentDestroyed).subscribe((status: number) => {
      if (status) {
        this.detailsForm.get('diseases').setValidators([Validators.required, Validators.pattern(new RegExp(RegexpPattern.enumeration))]);
        this.detailsForm.get('diseases').updateValueAndValidity();
      } else {
        this.clearValidators('detailsForm', ['diseases']);
      }
    });*/

    this.personalForm['controls'].passwordGroup.valueChanges.takeUntil(this.componentDestroyed).subscribe((changes) => {
      if (changes.confirmPassword && changes.password) {
        if (this.personalForm['controls'].passwordGroup.hasError('isMatch')) {
          this.personalForm['controls'].passwordGroup['controls'].confirmPassword.setErrors({ isMatch: true });
        } else {
          this.personalForm['controls'].passwordGroup['controls'].confirmPassword.setErrors(null);
          if ((this.personalForm['controls'].passwordGroup['controls'].confirmPassword.value === null || this.personalForm['controls'].passwordGroup['controls'].confirmPassword.value === '') && this.personalForm['controls'].passwordGroup['controls'].password.dirty && this.personalForm['controls'].passwordGroup['controls'].password.value !== '') {
            this.personalForm['controls'].passwordGroup['controls'].confirmPassword.setErrors({ required: true });
          }
        }
      }
    });

    this.contactForm.get('phoneNo').valueChanges.takeUntil(this.componentDestroyed).subscribe(() => {
      this.phoneChecked = false;
    });
    this.contactForm.get('emergencyContact').valueChanges.takeUntil(this.componentDestroyed).subscribe(() => {
      this.emergencyContactChecked = false;
    });
    this.personalForm.get('nationalId').valueChanges.takeUntil(this.componentDestroyed).subscribe(() => {
      this.nationalIDChecked = false;
    });
    this.personalForm.get('passportNo').valueChanges.takeUntil(this.componentDestroyed).subscribe(() => {
      this.passportIDChecked = false;
    });
    this.personalForm.get('email').valueChanges.takeUntil(this.componentDestroyed).subscribe(() => {
      this.mailChecked = false;
    });
  }

  public initData() {
    this.createUserService.getCites().takeUntil(this.componentDestroyed).subscribe(cities => this.cityList = cities);
    this.createUserService.getJoiningReason().takeUntil(this.componentDestroyed).subscribe(reasons => this.reasonsList = reasons);
    this.createUserService.getProfessions().takeUntil(this.componentDestroyed).subscribe(professions => this.professionList = professions);
    this.createUserService.getSkills().takeUntil(this.componentDestroyed).subscribe(skills => this.skillsList = skills);
    this.createUserService.getGender().takeUntil(this.componentDestroyed).subscribe(gender => this.genderType = gender);
    this.createUserService.getNationality().takeUntil(this.componentDestroyed).subscribe(nationalities => {
      this.nationalityList = nationalities.filter(obj => obj.value !== 'Other')
      this.personalForm.controls.nationalityId.setValue(nationalities.filter(obj => obj.id == this.SaudiNational)[0]);
    });

    this.createUserService.getMaritalStatus().takeUntil(this.componentDestroyed).subscribe(maritalStatus => this.maritalStatusType = maritalStatus);
    this.createUserService.getDayOfWeek().takeUntil(this.componentDestroyed).subscribe(dayOfWeek => this.participationDayType = dayOfWeek);
    this.createUserService.getParticipationTime().takeUntil(this.componentDestroyed).subscribe(participationTime => this.participationTimeType = participationTime);
  }

  clearValidators(form, fieldsArr) {
    for (let i = 0; i < fieldsArr.length; i++) {
      this[form].get(fieldsArr[i]).clearValidators();
      this[form].get(fieldsArr[i]).updateValueAndValidity();
    }
  }

  getErrorMessage() {
    return this.skillsForm.controls.validationLink.hasError('link') ? 'link' : 'https';
  }


  checkBirthDate() {
    const birthDateStr = this.personalForm.value.birthDate;
    this.personalForm.controls.birthDate.setValue(birthDateStr);
    const currentDate = Date.now();
    const birthDate = Date.parse(birthDateStr);
    if (isNaN(birthDate)) {
      this.personalForm.updateValueAndValidity();
    } else {
      if ((currentDate - birthDate) < 157784630000) {
        this.personalForm.controls.birthDate.setErrors({ 'youngerFiveYears': true });
      } else {
        this.personalForm.controls.birthDate.setErrors(null);
      }
      this.personalForm.updateValueAndValidity();
    }
  }

  submitForm() {
    this.isLoading = true;
    const joinRequestData = {
      password: this.personalForm.value.passwordGroup.password,
      passwordConfirmation: this.personalForm.value.passwordGroup.confirmPassword,
      email: this.personalForm.value.email,
      phoneNo: this.contactForm.value.phoneNo,
      personal: this.personalForm.value,
      contact: this.contactForm.value,
      skills: this.skillsForm.value,
      misc: this.detailsForm.value,
    };
    joinRequestData.personal.nationalityId = this.personalForm.controls.nationalityId.value.id;
    joinRequestData.personal.professionId = this.personalForm.controls.professionId.value.id;
    joinRequestData.contact.cityId = this.contactForm.controls.cityId.value.id;
    joinRequestData.skills.validationLink = this.skillsForm.value.validationLink === '' ? null : this.skillsForm.value.validationLink;
    joinRequestData.misc.joiningReasonId = this.detailsForm.controls.joiningReasonId.value.id;
    joinRequestData.misc.diseases = this.detailsForm.controls.hasDiseases.value ? 'Yes' : null;
    this.createUserService.createUser(joinRequestData).takeUntil(this.componentDestroyed).subscribe(res => {
      this.isCreatedUser = true;
      console.log('User create with ID ' + res);
      this.isLoading = false;
      if (res) {
        this.router.navigate(['/']);
      } else {
        alert(res);
      }
    }, error => {
      this.isLoading = false;
    });
  }

  checkRegistrationEmail(event) {
    if (this.personalForm.controls.email.dirty && this.personalForm.controls.email.valid) {
      this.createUserService.checkEmail({ email: this.personalForm.controls.email.value }).subscribe((res: any) => {
        if (!res.isUnique) {
          this.personalForm.controls.email.setErrors({ 'notUnique': true });
        } else {
          this.mailChecked = true;
          let target = event.relatedTarget;
          if (target === null) {
            target = document.activeElement;
          }
          if (target && target.nodeName === 'BUTTON') {
            this.checkStepPersonal();
          }
        }
      });
    }
  }

  checkStepContact() {
    if (this.contactForm.valid && this.phoneChecked && this.emergencyContactChecked) {
      this.stepper.next();
    }

    setTimeout(() => {
      this.contactFormChecked = true;
    }, 50);
  }

  checkStepPersonal() {
    if (this.personalForm.valid && this.mailChecked && (this.nationalIDChecked || this.passportIDChecked)) {
      this.stepper.next();
    }
    setTimeout(() => {
      this.personalFormChecked = true;
    }, 50);
  }

  checkStepSkills() {
    if (this.skillsForm.valid) {
      this.stepper.next();
    }
    setTimeout(() => {
      this.skillsFormChecked = true;
    }, 50);
  }

  checkdetailsStep() {
    if (this.detailsForm.valid) {
      this.submitForm();
    }
    setTimeout(() => {
      this.detailsFormChecked = true;
    }, 50);
  }

  checkPhone(event) {
    if (this.contactForm.controls.phoneNo.dirty && this.contactForm.controls.phoneNo.valid) {
      this.createUserService.checkPhone({ phoneNo: this.contactForm.controls.phoneNo.value }).subscribe((res: any) => {
        if (!res.isValid) {
          this.contactForm.controls.phoneNo.setErrors({ 'notValid': true });
        } else if (!res.isUnique) {
          this.contactForm.controls.phoneNo.setErrors({ 'notUnique': true });
        } else {
          // this.contactForm.patchValue({phoneNo: res.formattedPhoneNo});
          if (this.contactForm.value.emergencyContact) {
            if (this.contactForm.value.emergencyContact !== this.contactForm.value.phoneNo) {
              this.contactForm.controls.emergencyContact.updateValueAndValidity();
            } else {
              this.contactForm.controls.emergencyContact.setErrors({ 'equal': true });
            }
          }
          this.contactForm.updateValueAndValidity();
          this.phoneChecked = true;
          let target = event.relatedTarget;
          if (target === null) {
            target = document.activeElement;
          }
          if (target && target.nodeName === 'BUTTON') {
            this.checkStepContact();
          }
        }
      });
    }
  }

  checkEmergencyPhone(event) {
    if (this.contactForm.controls.emergencyContact.dirty && this.contactForm.controls.emergencyContact.valid) {
      this.createUserService.checkPhone({ phoneNo: this.contactForm.controls.emergencyContact.value }).subscribe((res: any) => {
        if (!res.isValid) {
          this.contactForm.controls.emergencyContact.setErrors({ 'notValid': true });
        } else {
          // this.contactForm.patchValue({emergencyContact: res.formattedPhoneNo});
          if (this.contactForm.value.emergencyContact === this.contactForm.value.phoneNo) {
            this.contactForm.controls.emergencyContact.setErrors({ 'equal': true });
          }
          this.contactForm.updateValueAndValidity();
          this.emergencyContactChecked = true;
          let target = event.relatedTarget;
          if (target === null) {
            target = document.activeElement;
          }
          if (target && target.nodeName === 'BUTTON') {
            this.checkStepContact();
          }
        }
      });
    }
  }

  checkNationalID(event) {
    if (this.personalForm.controls.nationalId.dirty && this.personalForm.controls.nationalId.valid) {
      this.createUserService.checkNationalID({ nationalId: this.personalForm.controls.nationalId.value }).subscribe((res: any) => {
        if (!res.isUnique) {
          this.personalForm.controls.nationalId.setErrors({ 'notUnique': true });
        } else {
          this.nationalIDChecked = true;
          let target = event.relatedTarget;
          if (target === null) {
            target = document.activeElement;
          }
          if (target && target.nodeName === 'BUTTON') {
            this.checkStepPersonal();
          }
        }
      });
    }

  }

  checkPassportID(event) {
    if (this.personalForm.controls.passportNo.dirty && this.personalForm.controls.passportNo.valid) {
      this.createUserService.checkPassportID({ passportNo: this.personalForm.controls.passportNo.value }).subscribe((res: any) => {
        if (!res.isUnique) {
          this.personalForm.controls.passportNo.setErrors({ 'notUnique': true });
        } else {
          this.passportIDChecked = true;
          let target = event.relatedTarget;
          if (target === null) {
            target = document.activeElement;
          }
          if (target && target.nodeName === 'BUTTON') {
            this.checkStepPersonal();
          }
          if (event.relatedTarget && event.relatedTarget.nodeName === 'BUTTON') {
            this.checkStepPersonal();
          }
        }
      });
    }
  }

  changeStep() {
    const element = document.getElementById('main');
    $('body,html').animate({
      scrollTop: element.offsetTop + 70
    }, 800);
    // const items = document.getElementsByClassName('form-part');
    // if (items.length > 0) {
    //   const item = <HTMLElement>items[event.selectedIndex];
    //   $('body,html').animate({
    //     scrollTop: item.offsetTop - $('element').outerHeight(true)
    //   }, 800);
    //   // window.scrollBy(0, item.offsetTop - window.pageYOffset + this.mainSectionOffsetTop);
    // }
  }
}
