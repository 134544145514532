import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mvms-my-events',
  templateUrl: './my-events.component.html',
  styleUrls: ['./my-events.component.scss']
})
export class MyEventsComponent implements OnInit {

  @Input() eventList: any[];

  constructor(private router: Router) { }

  ngOnInit() {
  }
  goToEvents() {
    this.router.navigate([`/dashboard/myEvents`]);
  }

}
