import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
declare var $: any;

@Component({
  selector: 'mvms-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageCropperComponent implements OnInit, AfterViewInit {
  @ViewChild('box') box: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>
  ) { }

  ngOnInit() {

  }
  ngAfterViewInit() {
    $.fn.demoUpload();
  }

}
