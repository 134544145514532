<div id="blog" *ngIf="masonryItems">
  <h2 class="header">{{'NEWS.Title' | translate }}</h2>
  <div class="gallery row">
    <!-- <div class="col l4 m4 m6 s12 gallery-item gallery-expand overhead"
         *ngFor="let item of masonryItems;let i = index" id="{{'masonry-item' + i}}">
      <div class="gallery-curve-wrapper">
        <a class="gallery-cover gray">
          <mvms-image [imageId]="item.imageId" [isAvatar]="false" *ngIf="!item.videoLink"></mvms-image>
          <div class="player-container" *ngIf="item.videoLink">
            <youtube-player
              width="100%"
              height="100%"
              [videoId]="item.videoId"
              class="player"
            ></youtube-player>
          </div>
        </a>
        <div class="gallery-header">
          <span>{{item.title}}</span>
        </div>
        <div class="gallery-body">
          <div class="title-wrapper">
            <h3>{{item.title}}</h3>
          </div>
          <p class="description" [innerHTML]="item.content | safeHtml"></p>
        </div>
      </div>
    </div> -->
    <div class="col l4 m4 m6 s12 post-module gallery-item gallery-expand overhead"
      *ngFor="let item of masonryItems;let i = index" id="{{'masonry-item' + i}}">
      <!-- Thumbnail-->
      <div class="gallery-curve-wrapper">
        <div class="thumbnail">
          <a class="gallery-cover gray">
            <mvms-image [imageId]="item.imageId" [isAvatar]="false" *ngIf="!item.videoLink"></mvms-image>
            <div class="player-container" *ngIf="item.videoLink">
              <youtube-player width="100%" height="100%" [videoId]="item.videoId" class="player"></youtube-player>
            </div>
          </a>


          <!-- Post Content-->
          <div class="post-content gallery-header">
            <h1 class="title">{{item.title}}</h1>
            <div class="description">
              <p [innerHTML]="item.content | safeHtml"></p>
              <div class="fade"><button class="button">{{'SHARED.Button.readMore' | translate }} </button></div>
            </div>
          </div>
        </div>
        <div class="gallery-body">
          <div class="title-wrapper">
            <h3>{{item.title}}</h3>
          </div>
          <p class="description" [innerHTML]="item.content | safeHtml"></p>
        </div>
      </div>

    </div>

  </div>
  <div class="center" *ngIf="blogItems && masonryItems.length < blogItems.length">
    <button (click)="showMore()">{{'SHARED.Button.More' | translate }}</button>
  </div>
</div>