import {NgModule, ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthConfig, IAuthConfig} from './auth.config';
import {TokenService} from './services/token.service';
import {JwtHttpInterceptor} from './services/jwt-http.interceptor';
import {AuthenticationService} from './services/authentication.service';
import {AuthGuard} from './guard/auth.guard';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  imports: [
    CommonModule, RouterModule
  ],
  declarations: [
    // LoginComponent
  ],
})
export class AuthModule {
  static forRoot(config?: IAuthConfig): ModuleWithProviders<any> {
    return {
      ngModule: AuthModule,
      providers: [
        {provide: AuthConfig, useValue: config},
        TokenService, AuthenticationService, AuthGuard,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtHttpInterceptor,
          multi: true,
        },
      ]
    };
  }
}
