<div class="photo circle"  *ngIf="isAvatar">
  <img class="responsive-img" [src]="baseUrl + 'api/File/'+ imageId + '/thumbnail'" alt="avatar" *ngIf="imageId">
  <img class="responsive-img" src="assets/icons/profile.jpg" alt="avatar" *ngIf="!imageId">
  <!--<div class="photo-img" [style.backgroundImage]="backURL"  mat-card-avatar></div>-->
</div>
<div class="image" *ngIf="!isAvatar"><!---->
  <img [src]="baseUrl + 'api/file/'+ imageId + '/thumbnail'" alt="image" *ngIf="imageId">
  <!--<div class="picture" [style.backgroundImage]="backURL"></div>-->
  <img [src]="transparentSource" class="empty-image" alt="image" *ngIf="!imageId">
</div>

<!--<div class="back-img" *ngIf="!isAvatar">-->
  <!--<img class="thumbnail" [src]="baseUrl + 'api/file/'+ imageId + '/thumbnail'" alt="image" *ngIf="imageId">-->
  <!--<img src="assets/images/backgrounds/empty.jpg" alt="image" *ngIf="!imageId">-->
<!--</div>-->

