<div class="create-form-dialog" [class.rtl]="isCurrentLanguageArabic"
  [attr.dir]="isCurrentLanguageArabic  ? 'rtl' : ''">
  <div class="header-panel">
    <h2 class="header">{{'DASHBOARD.event.WithdrawalReason' | translate }} </h2>
    <div class="btn-close">
      <button mat-icon-button (click)="closeDialog()" tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="main">
    <form class="form" [formGroup]="withdrawalReasonForm" (ngSubmit)="submit()" #form="ngForm">
      <div class="row">
        <h4 class="title col m2 s12">{{'DASHBOARD.event.WithdrawalReason' | translate }}</h4>
        <mat-form-field class="col m10 s12" floatLabel="never">
          <textarea matInput formControlName="reason" rows="3"
            [placeholder]="'DASHBOARD.event.WithdrawalReason' | translate" type='text'></textarea>
          <mat-hint align="end">{{withdrawalReasonForm.controls['reason'].value.length}} / 200</mat-hint>
          <mat-error
            *ngIf="(withdrawalReasonForm.controls['reason'].touched || validate) && withdrawalReasonForm.controls['reason'].hasError('required')">
            {{'SHARED.Errors.emptyField' | translate }}
          </mat-error>
          <mat-error *ngIf="withdrawalReasonForm.controls['reason'].hasError('maxlength')">
            {{'SHARED.Errors.maxLength' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="btn-group">
        <button type="submit" mvmsDebounceClick (debounceClick)="form.onSubmit($event)" [debounceTime]="1500"
          mat-raised-button color="accent">
          {{'DASHBOARD.event.Withdrawal' | translate }}
        </button>
        <button type="button" mvmsDebounceClick (click)="close(false)"
        mat-raised-button color="accent">
        {{'SHARED.Button.Cancel' | translate }}
      </button>
      </div>
    </form>
  </div>
</div>